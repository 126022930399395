import IconPriorityHighest from "@/components/icons/priorities/IconPriorityHighest.vue";
import IconPriorityHigh from "@/components/icons/priorities/IconPriorityHigh.vue";
import IconPriorityMedium from "@/components/icons/priorities/IconPriorityMedium.vue";
import IconPriorityLow from "@/components/icons/priorities/IconPriorityLow.vue";
import IconPriorityLowest from "@/components/icons/priorities/IconPriorityLowest.vue";
import {
  CommunicationLogType,
  LogEndpointType,
  TaskPriorityLevels,
  TaskStatuses
} from "@/enums/communicationLogs";

export const priorities = [
  {
    value: TaskPriorityLevels.highest,
    icon: IconPriorityHighest,
    viewBox: "-1 0 15 12",
    class: "text-red-500"
  },
  {
    value: TaskPriorityLevels.high,
    icon: IconPriorityHigh,
    viewBox: "-1 -3 15 12",
    class: "text-red-500"
  },
  {
    value: TaskPriorityLevels.medium,
    icon: IconPriorityMedium,
    viewBox: "-1 0 15 12",
    class: "text-yellow-500"
  },
  {
    value: TaskPriorityLevels.low,
    icon: IconPriorityLow,
    viewBox: "-1.5 0 15 12",
    class: "text-primary"
  },
  {
    value: TaskPriorityLevels.lowest,
    icon: IconPriorityLowest,
    viewBox: "-1.5 -3 15 15",
    class: "text-blue-500"
  }
];

export const prioritiesIconsMap = {
  [TaskPriorityLevels.highest]: {
    icon: IconPriorityHighest,
    class: "text-red-500"
  },
  [TaskPriorityLevels.high]: {
    icon: IconPriorityHigh,
    class: "text-red-500"
  },
  [TaskPriorityLevels.medium]: {
    icon: IconPriorityMedium,
    class: "text-yellow-500"
  },
  [TaskPriorityLevels.low]: {
    icon: IconPriorityLow,
    class: "text-primary"
  },
  [TaskPriorityLevels.lowest]: {
    icon: IconPriorityLowest,
    class: "text-blue-500"
  }
};

export const TASK_STATUSES_DISPLAY_OPTIONS = {
  [TaskStatuses.todo]: {
    class: "text-gray-400 bg-gray-200 bg-opacity-50",
    label: "ACTIVITY.TASK.TO_DO"
  },
  [TaskStatuses.in_progress]: {
    class: "text-blue-400 bg-blue-100 bg-opacity-50",
    label: "ACTIVITY.TASK.IN_PROGRESS"
  },
  [TaskStatuses.done]: {
    class: "text-green-400 bg-green-100 bg-opacity-50",
    label: "ACTIVITY.TASK.DONE"
  }
};

export const LOG_ENDPOINT_TYPE_TO_LOG_TYPE: Record<
  LogEndpointType,
  CommunicationLogType
> = {
  [LogEndpointType.CallSession]: CommunicationLogType.call,
  [LogEndpointType.Task]: CommunicationLogType.task,
  [LogEndpointType.Sms]: CommunicationLogType.sms
};
