export enum ValidOppositeConditions {
  PASS = "pass",
  FAIL = "fail"
}

export enum OrchestrationType {
  WORKFLOW_ORCHESTRATION = "workflowOrchestration",
  DATA_ORCHESTRATION = "dataOrchestration"
}

export enum OrchestrationTemplateStatus {
  Draft = 1,
  Published = 2
}
