import { canShowNewLogNotification } from "@/helpers/communication";
import type { CommunicationLogEventData } from "@/models/communicationLogs";
import { useCommunicationStore } from "@/stores/communication";
import { storeToRefs } from "pinia";

export const handleCommunicationLogsUpdate = (
  log: CommunicationLogEventData
) => {
  if (log.communication_log_id === null) {
    return;
  }

  const communicationStore = useCommunicationStore();
  const { hasNewLogs } = storeToRefs(communicationStore);

  if (log.event === "updated") {
    communicationStore.handleWebsocketLogUpdateEvent({
      id: log.communication_log_id,
      event: log.event,
      data: {
        ...log.data,
        type: log.type,
        application_id: String(log.linked_instance_id)
      }
    });
    return;
  }

  if (hasNewLogs.value || !canShowNewLogNotification(log.data)) {
    return;
  }

  communicationStore.toggleHasNewLogsStatus(true);
};
