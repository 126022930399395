import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import type {
  SendApplicationEmailPayload,
  SendApplicationEmailModalData,
  EmailRecipient
} from "@/models/emails";
import { formatString } from "@/helpers/formatting";
import type {
  CommunicationFilters,
  EmailLogData,
  EmailThread,
  EmailThreadItem
} from "@/models/communicationLogs";
import type { IPaginatedResponse } from "@/models/common";

class EmailService {
  async sendApplicationEmail(
    params: Partial<SendApplicationEmailPayload> = {}
  ) {
    const response = await axiosClient.post<{ data: { started: boolean } }>(
      endpoints.COMMUNICATION.EMAILS.SEND,
      {
        ...params
      }
    );
    return response.data;
  }

  async getApplicationEmailModalData(application_ids: string[]) {
    const response = await axiosClient.post<SendApplicationEmailModalData>(
      endpoints.COMMUNICATION.EMAILS.MODAL_DATA,
      {
        application_ids
      }
    );
    return response.data;
  }

  async assignEmailToApplication(application_id: string, email_id: number) {
    const response = await axiosClient.put<{ data: EmailLogData }>(
      formatString(
        endpoints.COMMUNICATION.EMAILS.ASSIGN_TO_APPLICATION,
        email_id
      ),
      {
        application_id
      }
    );
    return response.data;
  }

  async getEmailThreads(params: Partial<CommunicationFilters> = {}) {
    const response = await axiosClient.get<IPaginatedResponse<EmailThread>>(
      formatString(endpoints.COMMUNICATION.EMAILS.THREADS),
      { params }
    );
    return response.data;
  }

  async getEmailThread(
    thread_id: number,
    params: Partial<CommunicationFilters> = {}
  ) {
    const response = await axiosClient.get<IPaginatedResponse<EmailThreadItem>>(
      formatString(endpoints.COMMUNICATION.EMAILS.THREAD, thread_id),
      { params }
    );
    return response.data;
  }

  async replyToEmail(payload: { emailId: number; body: string }) {
    await axiosClient.post(
      formatString(endpoints.COMMUNICATION.EMAILS.REPLY, payload.emailId),
      { body: payload.body }
    );
  }

  async getEmailRecipients(params: Record<string, unknown>) {
    const response = await axiosClient.get<IPaginatedResponse<EmailRecipient>>(
      formatString(endpoints.COMMUNICATION.EMAILS.RECIPIENTS),
      { params }
    );
    return response.data;
  }
}

const emailService = new EmailService();
export default emailService;
