export const CONTEXT_APPLICATION = "application" as const;
export const CONTEXT_WORKFLOW = "workflow" as const;
export const CONTEXT_DATA_ORCHESTRATION = "dataOrchestration" as const;
export const CONTEXT_SCORECARD = "scorecard" as const;

export const MESSAGE_APP_ROLLBACK =
  "Rollback to :prevStage from :currentStage" as const;
export const MESSAGE_SC_BUILDER_PHASE =
  "Scorecard Builder Phase: :phase" as const;
export const MESSAGE_NAVIGATION = "Navigation to :to from :from" as const;
export const MESSAGE_START_SERVICE = "Start service: :service" as const;
export const MESSAGE_DEAL_MOVE_FORWARD =
  "Move to :nextStage from :currentStage" as const;
export const MESSAGE_RESTORE_APP = "Restore app: :app" as const;
export const MESSAGE_SUBMIT_APPLICATION = "Submit application: :app" as const;

export const CATEGORY_SCORECARDS = "scorecards" as const;
export const CATEGORY_NAVIGATION = "navigation" as const;
export const CATEGORY_SERVICE = "service" as const;
export const CATEGORY_DEAL_ROLLBACK = "dealRollback" as const;
export const CATEGORY_DEAL_MOVE_FORWARD = "dealMoveForward" as const;
export const CATEGORY_DEAL_RESTORE = "dealRestore" as const;
export const CATEGORY_DEAL_SUBMIT_APPLICATION =
  "dealSubmitApplication" as const;
