import apiService from "@/services/modules/orchestration";
import type { GetTemplateServicesParams } from "@/services/modules/orchestration";
import applicationsApiService from "@/services/modules/applications";
import type { IRootState } from "@/models/state";
import type { ConditionType, IResponseMeta } from "@/models/common";
import type {
  ActiveConfigPanel,
  DataOrchestrationOutcome,
  IActiveConfigPanelConditional,
  IAttribute,
  IConditionalArgument,
  IConditionalSetValueModel,
  IContextSetValueModel,
  ICustomAttributeContext,
  IDataOrchestrationFlowCheckCondition,
  IDataOrchestrationFlowOutcome,
  IDataService,
  IDataServices,
  IOrchestrationTemplate,
  IOrchestrationTemplatesState,
  IStep,
  IStepAttribute,
  SaveMode
} from "@/models/orchestration";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import { produce } from "immer";
import {
  getDoStepBlueprint,
  validateDataOrchestrationPayload
} from "@/helpers/orchestration";
import {
  DO_CFA_BOOLEAN_ATTRIBUTES,
  DO_SAVE_TEMPLATE_MODES,
  DO_SCHEMA_VERSION,
  SERVICE_APPLICATION_DATA,
  SERVICE_CUSTOM,
  SERVICE_CUSTOM_ATTRIBUTES,
  SERVICE_TRIGGER,
  TYPE_GREATER_THAN_OR_EQUAL,
  TYPE_LESS_THAN_OR_EQUAL,
  TYPE_RANGE
} from "@/helpers/constants";
import i18n from "@/i18n";
import { makeRandomString } from "@/helpers/formatting";
import {
  collectionHasTransunion,
  updateTransUnionAttributes
} from "@/helpers/scorecards";

const state: IOrchestrationTemplatesState = {
  meta: {} as IResponseMeta,
  all: [],
  activeTemplate: {} as IOrchestrationTemplate,
  activeTemplateSteps: [],
  loading: false,
  activeServices: {} as IDataServices,
  activeStep: {} as IStep,
  activeConfigPanel: "",
  selectedSteps: [],
  copiedSteps: [],
  activeConfigPanelConditional: null,
  replaceStepId: "",
  replacementStep: null,
  showReplaceSericeDropdown: false,
  nonPersistedTemplatesForWfo: []
};

const BOOLEAN_VALUES: Record<string, boolean> = {
  true: true,
  false: false
};

const mutations: MutationTree<IOrchestrationTemplatesState> = {
  setAllTemplates(state, data: IOrchestrationTemplate[]) {
    state.all = data;
  },
  setMetadata(state, meta: IResponseMeta) {
    state.meta = meta;
  },
  setLoading(state, val: boolean) {
    state.loading = val;
  },
  setUpdatedTemplate(state, updatedTemplate: IOrchestrationTemplate) {
    state.all = produce(state.all, (draft) => {
      const index = draft.findIndex(
        (template) => template.id === updatedTemplate.id
      );
      if (index !== -1) {
        draft[index] = { ...draft[index], ...updatedTemplate };
      }
    });
  },
  setActiveTemplate(state, template: IOrchestrationTemplate) {
    state.activeTemplate = template;
  },
  setActiveTemplateSteps(state, templateFlow: IStep[]) {
    state.activeTemplateSteps = templateFlow;
  },
  setActiveServices(state, services: IDataServices) {
    state.activeServices = produce(services, (draftState) => {
      const customAttributes = draftState.other?.find(
        (otherService) => otherService.title === SERVICE_CUSTOM_ATTRIBUTES
      );
      customAttributes?.attributes?.forEach((attr) => {
        const { context } = attr;
        if (context?.id) {
          attr.context = {
            value: { id: context.id },
            type: context.type
          };
        }
      });
    });
  },
  setActiveConfigPanel(state, type: ActiveConfigPanel) {
    state.activeConfigPanel = type;
  },
  setActiveStep(state, step: IStep) {
    state.activeStep = step;
  },
  setReplaceStepId(state, id: string) {
    state.replaceStepId = id;
  },
  setReplacementStep(state, replacementStep: IDataService) {
    state.replacementStep = replacementStep;
  },
  unsetAll(state) {
    state.all = [];
    state.meta = {} as IResponseMeta;
  },
  unsetDoTemplate(state) {
    state.activeTemplate = {} as IOrchestrationTemplate;
    state.activeStep = {} as IStep;
    state.activeTemplateSteps = [] as IStep[];
    state.selectedSteps.splice(0);
    state.copiedSteps.splice(0);
  },
  appendNextService(
    state,
    payload: {
      stepId?: string | null;
      outcome?: DataOrchestrationOutcome | null;
      data: IDataService;
    }
  ) {
    const newStep = getDoStepBlueprint(payload.data);
    // when there's no steps
    if (
      !state.activeTemplateSteps.length &&
      !payload.stepId &&
      !payload.outcome
    ) {
      state.activeTemplateSteps = [...state.activeTemplateSteps, newStep];
      return;
    }

    // when there are steps already
    const indexOfStepToUpdate = state.activeTemplateSteps.findIndex(
      (step) => step.id === payload.stepId
    );
    if (indexOfStepToUpdate === -1) {
      return;
    }
    const indexOfStepOutcomeToUpdate = state.activeTemplateSteps[
      indexOfStepToUpdate
    ].outcomes.findIndex((outcome) => outcome.outcome === payload.outcome);
    if (indexOfStepOutcomeToUpdate === -1) {
      return;
    }

    state.activeTemplateSteps = [...state.activeTemplateSteps, newStep];

    state.activeTemplateSteps = produce(state.activeTemplateSteps, (draft) => {
      draft[indexOfStepToUpdate].outcomes[
        indexOfStepOutcomeToUpdate
      ].next_step = newStep.id;
    });
  },
  appendNextConditional(
    state,
    payload: { stepId: string; data: IConditionalArgument }
  ) {
    state.activeTemplateSteps = produce(state.activeTemplateSteps, (draft) => {
      const index = draft.findIndex((step) => step.id === payload.stepId);
      if (index === -1) {
        return;
      }

      draft[index] = {
        ...draft[index],
        outcomes: [
          ...(draft[index].outcomes || []),
          { outcome: payload.data.id, checks: payload.data.checks || [] }
        ]
      };
    });
  },
  appendNextCheckCondition(
    state,
    payload: {
      stepId: string;
      checkId: number | null;
      outcome: DataOrchestrationOutcome;
      attributes: IStepAttribute[];
    }
  ) {
    const stepIndex = state.activeTemplateSteps.findIndex(
      (s) => s.id === payload.stepId
    );
    if (stepIndex === -1) {
      return;
    }
    const stepOutcomeIndex = state.activeTemplateSteps[
      stepIndex
    ].outcomes.findIndex((o) => o.outcome === payload.outcome);
    if (stepOutcomeIndex === -1) {
      return;
    }

    const outcomeToUpdate =
      state.activeTemplateSteps[stepIndex].outcomes[stepOutcomeIndex];

    // if the checks array is empty, we need to add the first check
    if (payload.checkId === null) {
      outcomeToUpdate.checks = [
        {
          comparator: "and",
          conditions: payload.attributes.map((attr) => ({
            attribute: attr.id,
            conditions: [],
            ...(attr.context && { context: attr.context })
          }))
        }
      ];
      return;
    }

    const checksToUpdate = outcomeToUpdate.checks;

    if (!checksToUpdate) {
      return;
    }

    const checkToUpdate = checksToUpdate[payload.checkId];

    // filter attributes by existing ones in the check
    const filteredAttributes = payload.attributes.filter((attr) => {
      return !checkToUpdate.conditions.some(
        (condition: IDataOrchestrationFlowCheckCondition) =>
          attr.id === SERVICE_CUSTOM
            ? (attr.context?.value as IContextSetValueModel).id ===
              (condition.context?.value as IContextSetValueModel).id
            : attr.id === condition.attribute
      );
    });

    // add new check conditions
    const conditionsToAdd = filteredAttributes.map((attr) => ({
      attribute: attr.id,
      conditions: [],
      ...(attr.context && { context: attr.context })
    }));

    if (!checkToUpdate.comparator) {
      checkToUpdate.comparator = "and";
    }

    checkToUpdate.conditions = [
      // filter to see if some existing conditions are unchecked
      ...checkToUpdate.conditions.filter((condition) =>
        payload.attributes.some((attr) =>
          attr.id === SERVICE_CUSTOM
            ? (attr.context?.value as IContextSetValueModel).id ===
              (condition.context?.value as IContextSetValueModel).id
            : attr.id === condition.attribute
        )
      ),
      ...conditionsToAdd
    ];

    // remove the check completely if its conditions are empty
    if (!checkToUpdate.conditions.length) {
      checksToUpdate.splice(payload.checkId, 1);
    }
  },
  linkOutcomeToOtherOutcomeNextStep(
    state,
    payload: {
      stepId: string;
      outcome: IDataOrchestrationFlowOutcome;
      nextStepId: string;
      remove: boolean;
    }
  ) {
    const indexOfStepToUpdate = state.activeTemplateSteps.findIndex(
      (step) => step.id === payload.stepId
    );
    if (indexOfStepToUpdate === -1) {
      return;
    }
    const indexOfStepOutcomeToUpdate = state.activeTemplateSteps[
      indexOfStepToUpdate
    ].outcomes.findIndex(
      (outcome) => outcome.outcome === payload.outcome.outcome
    );
    if (indexOfStepOutcomeToUpdate === -1) {
      return;
    }
    state.activeTemplateSteps = produce(state.activeTemplateSteps, (draft) => {
      const updated = {
        next_step: payload.remove ? undefined : payload.nextStepId,
        follows_other_outcome_path: !payload.remove
      };
      draft[indexOfStepToUpdate].outcomes[indexOfStepOutcomeToUpdate] = {
        ...draft[indexOfStepToUpdate].outcomes[indexOfStepOutcomeToUpdate],
        ...updated
      };
    });
  },
  addConditionsGroup(
    state,
    payload: { stepId: string; outcome: DataOrchestrationOutcome }
  ) {
    const stepIndex = state.activeTemplateSteps.findIndex(
      (s) => s.id === payload.stepId
    );
    if (stepIndex === -1) {
      return;
    }
    const stepOutcomeIndex = state.activeTemplateSteps[
      stepIndex
    ].outcomes.findIndex((o) => o.outcome === payload.outcome);

    if (stepOutcomeIndex === -1) {
      return;
    }

    state.activeTemplateSteps[stepIndex].outcomes[stepOutcomeIndex].checks = [
      ...(state.activeTemplateSteps[stepIndex].outcomes[stepOutcomeIndex]
        .checks || []),
      {
        conditions: []
      }
    ];
  },
  updateConditionalValue(
    state,
    payload: {
      stepId: string;
      outcome: DataOrchestrationOutcome;
      type: ConditionType;
      value: string | string[];
      secondValue: string | string[];
      argument: IDataOrchestrationFlowCheckCondition;
      checkIndex: number;
      context: IContextSetValueModel | null;
      from?: string | number;
      through?: string | number;
      digits?: string[] | number[];
    }
  ) {
    const stepIndex = state.activeTemplateSteps.findIndex(
      (s) => s.id === payload.stepId
    );
    if (stepIndex === -1) {
      return;
    }
    const stepOutcomeIndex = state.activeTemplateSteps[
      stepIndex
    ].outcomes.findIndex((o) => o.outcome === payload.outcome);

    if (stepOutcomeIndex === -1) {
      return;
    }

    const outcomeToUpdate =
      state.activeTemplateSteps[stepIndex].outcomes[stepOutcomeIndex];

    const checksToUpdate = outcomeToUpdate.checks || [];

    const argConditionIndex = checksToUpdate[
      payload.checkIndex
    ]?.conditions?.findIndex((cond) =>
      payload.argument.attribute === SERVICE_CUSTOM
        ? (cond.context?.value as IContextSetValueModel)?.id ===
          (payload.argument.context?.value as IContextSetValueModel).id
        : cond.attribute === payload.argument.attribute
    );

    if (typeof argConditionIndex !== "number" || argConditionIndex === -1) {
      return;
    }

    const value =
      !Array.isArray(payload.value) && payload.type === "boolean"
        ? BOOLEAN_VALUES[payload.value]
        : payload.value;

    const typeIsRange = payload.type === TYPE_RANGE;

    let conditionsPayload: IConditionalSetValueModel[] = [
      {
        type: typeIsRange ? TYPE_GREATER_THAN_OR_EQUAL : payload.type,
        value
      }
    ];

    if (typeIsRange && payload.secondValue) {
      conditionsPayload = [
        ...conditionsPayload,
        {
          type: TYPE_LESS_THAN_OR_EQUAL,
          value: payload.secondValue
        }
      ];
    }

    const typeIsNaicsRange =
      payload.type === "naics_code_in_range" ||
      payload.type === "naics_code_not_in_range";

    if (typeIsNaicsRange && payload.from && payload.through && payload.digits) {
      conditionsPayload = [
        {
          type: payload.type,
          value: {
            from: payload.from,
            through: payload.through,
            digits: payload.digits.map(Number)
          }
        }
      ];
    }

    checksToUpdate[payload.checkIndex].conditions[
      argConditionIndex
    ].conditions = conditionsPayload;

    if (payload.context) {
      checksToUpdate[payload.checkIndex].conditions[argConditionIndex].context =
        payload.context;
    }
  },
  updateConditionalContext(
    state,
    payload: {
      stepId: string;
      outcome: DataOrchestrationOutcome;
      checkIndex: number;
      context: IContextSetValueModel;
    }
  ) {
    const stepIndex = state.activeTemplateSteps.findIndex(
      (s) => s.id === payload.stepId
    );
    if (stepIndex === -1) {
      return;
    }
    const stepOutcomeIndex = state.activeTemplateSteps[
      stepIndex
    ].outcomes.findIndex((o) => o.outcome === payload.outcome);

    if (stepOutcomeIndex === -1) {
      return;
    }

    const checksToUpdate =
      state.activeTemplateSteps[stepIndex].outcomes[stepOutcomeIndex].checks ||
      [];

    checksToUpdate[payload.checkIndex].conditions = checksToUpdate[
      payload.checkIndex
    ].conditions.map((check) => {
      if (DO_CFA_BOOLEAN_ATTRIBUTES.includes(check.attribute)) {
        return check;
      }
      return {
        ...check,
        context: payload.context
      };
    });
  },
  setActiveConfigPanelConditional(
    state,
    payload: IActiveConfigPanelConditional | null
  ) {
    state.activeConfigPanelConditional = payload;
  },
  setStepAttributes(
    state,
    payload: { stepId: string; attributes: IStepAttribute[] }
  ) {
    state.activeTemplateSteps = produce(state.activeTemplateSteps, (draft) => {
      const index = draft.findIndex((step) => step.id === payload.stepId);
      if (index === -1) {
        return;
      }
      draft[index].attributes = payload.attributes;
    });

    if (state.activeStep?.id === payload.stepId) {
      state.activeStep.attributes = payload.attributes;
    }
  },
  removeAttributesFromOutcomes(
    state,
    payload: { stepId: string; removedAttributes: IStepAttribute[] }
  ) {
    // if attributes have been removed also remove them from outcome checks
    const modifiedStep = state.activeTemplateSteps.find(
      (step) => step.id === payload.stepId
    );
    payload.removedAttributes?.forEach((attribute) => {
      modifiedStep?.outcomes.forEach((outcome) => {
        outcome.checks?.forEach(
          (check) =>
            (check.conditions = check.conditions.filter((checkCondition) =>
              attribute.id === SERVICE_CUSTOM
                ? (checkCondition.context?.value as ICustomAttributeContext)
                    .id !==
                  (attribute.context?.value as ICustomAttributeContext).id
                : checkCondition.attribute !== attribute.id
            ))
        );
      });
    });
  },
  deleteStep(state, payload: { stepId: string }) {
    const remove = (id: string) => {
      const stepIndex = state.activeTemplateSteps.findIndex(
        (step) => step.id === id
      );
      if (stepIndex === -1) {
        return;
      }

      if (stepIndex >= 1) {
        state.activeTemplateSteps[stepIndex - 1]?.outcomes?.forEach(
          (outcome) => {
            if (outcome.follows_other_outcome_path) {
              outcome.follows_other_outcome_path = false;
              outcome.next_step = undefined;
            }
          }
        );
      }

      state.activeTemplateSteps[stepIndex]?.outcomes?.forEach((outcome) => {
        if (outcome.next_step) {
          remove(outcome.next_step);
        }
      });

      state.activeTemplateSteps = produce(
        state.activeTemplateSteps,
        (draft) => {
          const stepAndOutcomeIndexPair = draft.reduce(
            (soAcc, soCurr, soIndex) => {
              const outcomeIndexes = soCurr.outcomes.reduce(
                (oAcc, oCurr, oIndex) => {
                  if (oCurr.next_step === id) {
                    return [...oAcc, oIndex];
                  }
                  return oAcc;
                },
                [] as number[]
              );

              if (!outcomeIndexes.length) {
                return soAcc;
              }

              const outcomesMappedWithStepIndex: [number, number][] =
                outcomeIndexes.map((oIndex) => [soIndex, oIndex]);

              return [...soAcc, ...outcomesMappedWithStepIndex];
            },
            [] as [number, number][]
          );

          stepAndOutcomeIndexPair.forEach(([stepIndex, outcomeIndex]) => {
            delete draft[stepIndex].outcomes[outcomeIndex].next_step;
          });

          draft.splice(stepIndex, 1);
        }
      );
    };
    remove(payload.stepId);
  },
  deleteConditional(
    state,
    payload: { stepId: string; outcome: DataOrchestrationOutcome }
  ) {
    const stepIndex = state.activeTemplateSteps.findIndex(
      (s) => s.id === payload.stepId
    );

    if (stepIndex === -1) {
      return;
    }
    const outcomeToDelete = state.activeTemplateSteps[stepIndex].outcomes.find(
      ({ outcome }) => outcome === payload.outcome
    );

    if (!outcomeToDelete) {
      return;
    }

    state.activeTemplateSteps[stepIndex].outcomes = state.activeTemplateSteps[
      stepIndex
    ].outcomes.reduce<IDataOrchestrationFlowOutcome[]>((acc, curr) => {
      if (curr.outcome === payload.outcome) {
        return acc;
      }
      if (
        curr.follows_other_outcome_path &&
        curr.next_step === outcomeToDelete.next_step
      ) {
        curr.follows_other_outcome_path = false;
        curr.next_step = undefined;
      }
      acc.push(curr);
      return acc;
    }, []);
  },
  clearSelectedSteps(state) {
    state.selectedSteps.splice(0);
  },
  changeSelectedSteps(state, stepId) {
    if (!stepId) {
      return;
    }
    const stepIndex = state.selectedSteps.indexOf(stepId);
    if (stepIndex < 0) {
      state.selectedSteps.push(stepId);
      return;
    }
    state.selectedSteps.splice(stepIndex, 1);
  },
  selectAllSteps(state) {
    const stepsIds = state.activeTemplateSteps.map(({ id }) => id);
    if (!stepsIds.length) {
      return;
    }
    state.selectedSteps = Array.from(
      new Set([...state.selectedSteps, ...stepsIds])
    );
  },
  clearCopiedSteps(state) {
    state.copiedSteps.splice(0);
  },
  setCopiedSteps(state, ids: string[]) {
    const steps = ids
      .map(
        (id) => state.activeTemplateSteps.find((step) => step.id === id) || null
      )
      .filter(Boolean) as IStep[];

    steps.sort(
      (stepA, stepB) =>
        state.activeTemplateSteps.indexOf(stepA) -
        state.activeTemplateSteps.indexOf(stepB)
    );

    const stepsWithNewId = steps.map((step) => ({
      ...step,
      newId: makeRandomString(15)
    }));

    const stepsWithUpdatedOutcomes = stepsWithNewId.map<IStep>((step) => ({
      ...step,

      outcomes: step.outcomes
        .map((outcome) =>
          outcome.next_step && ids.includes(outcome.next_step)
            ? outcome
            : { ...outcome, next_step: undefined }
        )
        .map((outcome) => {
          const step = stepsWithNewId.find(
            (step) => step.id === outcome.next_step
          );

          return step?.newId ? { ...outcome, next_step: step.newId } : outcome;
        })
    }));

    const updatedSteps = stepsWithUpdatedOutcomes
      .map((step) => ({
        ...step,
        id: step.newId
      }))
      .map((step) => {
        delete step.newId;
        return step;
      }) as IStep[];

    state.copiedSteps.splice(0);

    state.copiedSteps.push(...updatedSteps);
  },
  pasteStep(
    state,
    payload: {
      stepId: string;
      outcome: DataOrchestrationOutcome;
      newStep: IStep;
    }
  ) {
    const indexOfStepToUpdate = state.activeTemplateSteps.findIndex(
      (step) => step.id === payload.stepId
    );
    if (indexOfStepToUpdate === -1) {
      return;
    }

    const indexOfStepOutcomeToUpdate = state.activeTemplateSteps[
      indexOfStepToUpdate
    ].outcomes.findIndex((outcome) => outcome.outcome === payload.outcome);
    if (indexOfStepOutcomeToUpdate === -1) {
      return;
    }

    state.activeTemplateSteps = [...state.activeTemplateSteps, payload.newStep];

    state.activeTemplateSteps = produce(state.activeTemplateSteps, (draft) => {
      draft[indexOfStepToUpdate].outcomes[
        indexOfStepOutcomeToUpdate
      ].next_step = payload.newStep.id;
    });
  },
  setNonPersistedTemplatesForWfo(
    state,
    payload: Array<IOrchestrationTemplate>
  ) {
    state.nonPersistedTemplatesForWfo = payload;
  }
};

const actions: ActionTree<IOrchestrationTemplatesState, IRootState> = {
  async fetchAllTemplates(
    { commit },
    payload: { params: Record<string, unknown>; applicationId: string } = {
      params: {},
      applicationId: ""
    }
  ) {
    commit("setLoading", true);
    const response = payload.applicationId
      ? await applicationsApiService.getOrchestrationTemplates(
          payload.applicationId,
          payload.params
        )
      : await apiService.getOrchestrationTemplates(payload.params);
    commit("setLoading", false);
    return response.data;
  },
  async getAllTemplates(
    { commit },
    payload: { params: Record<string, unknown>; applicationId: string } = {
      params: {},
      applicationId: ""
    }
  ) {
    commit("setLoading", true);
    const data = payload.applicationId
      ? await applicationsApiService.getOrchestrationTemplates(
          payload.applicationId,
          payload.params
        )
      : await apiService.getOrchestrationTemplates(payload.params);
    commit("setAllTemplates", data.data);
    commit("setMetadata", data.meta);
    commit("setLoading", false);
  },
  async createTemplate(
    { dispatch },
    { data }: { data: Partial<IOrchestrationTemplate> }
  ) {
    const newTemplate = await apiService.createOrchestrationTemplate(data);
    void dispatch("getAllTemplates");
    return newTemplate;
  },
  async updateTemplate(
    { commit },
    { templateId, data }: { templateId: string; data: IOrchestrationTemplate }
  ) {
    const updatedTemplate = await apiService.updateOrchestrationTemplate(
      templateId,
      data
    );
    commit("setUpdatedTemplate", updatedTemplate);
    if (state.activeTemplate.id === updatedTemplate.id) {
      commit("setActiveTemplate", updatedTemplate);
    }
    return updatedTemplate;
  },
  async saveTemplate(
    { dispatch, state, commit },
    { mode, workflow }: { mode: SaveMode; workflow: string[] }
  ) {
    if (!state.activeTemplate.id) {
      return;
    }
    const steps = state.activeTemplateSteps;
    const [error] = validateDataOrchestrationPayload(steps);
    if (mode === DO_SAVE_TEMPLATE_MODES.publish && error) {
      commit(
        "setGlobalMessage",
        { title: error, type: "error" },
        { root: true }
      );
      return;
    }

    const payload = {
      templateId: state.activeTemplate.id,
      data: {
        content: {
          steps,
          version: DO_SCHEMA_VERSION
        },
        status: mode,
        workflow
      }
    };

    const hasTransUnionAttributes = payload.data.content.steps.some(
      ({ attributes }) => collectionHasTransunion(attributes)
    );

    if (hasTransUnionAttributes && mode === DO_SAVE_TEMPLATE_MODES.publish) {
      payload.data.content.steps = updateTransUnionAttributes(
        state.activeTemplateSteps
      );
    }

    return await dispatch("updateTemplate", payload);
  },
  async deleteTemplate({ commit }, templateId: string) {
    const { status } = await apiService.deleteOrchestrationTemplate(templateId);

    if (status === 204) {
      const allTemplates = state.all.filter(
        (template) => template.id !== templateId
      );
      commit("setAllTemplates", allTemplates);
    }
  },
  async duplicateTemplate({ dispatch }, templateId: string) {
    const data = await apiService.getOrchestrationTemplate(
      templateId,
      DO_SCHEMA_VERSION
    );
    return dispatch("createTemplate", {
      data: {
        ...data,
        status: DO_SAVE_TEMPLATE_MODES.draft,
        name: `${data.name} ${i18n.global.t("COMMON.APPEND_COPY_TEXT")}`
      }
    });
  },
  async getActiveTemplate({ commit }, templateId: string) {
    const data = await apiService.getOrchestrationTemplate(
      templateId,
      DO_SCHEMA_VERSION
    );

    commit("setActiveTemplate", data);
    commit("setActiveTemplateSteps", data.content?.steps || []);
    return data;
  },
  async getDataServices({ commit }, params: GetTemplateServicesParams = {}) {
    commit("setLoading", true);
    const data = await apiService.getOrchestrationTemplateServices(params);
    commit("setActiveServices", data);
    commit("setLoading", false);
  },
  async updateTrigger({ commit, state }, triggerType: string) {
    const data = await apiService.updateOrchestrationTemplate(
      state.activeTemplate.id,
      {
        trigger: triggerType
      }
    );
    commit("setActiveTemplate", data);
  },
  async continuePass({ rootState }, stepId: string) {
    await apiService.continuePass(rootState.applications.active.id, stepId);
  },
  async continueFail({ rootState }, stepId: string) {
    await apiService.continueFail(rootState.applications.active.id, stepId);
  },
  async retryStep({ rootState }, stepId: string) {
    await apiService.retryStep(rootState.applications.active.id, stepId);
  },
  cancelReplaceService({ commit }) {
    commit("setReplaceStepId", "");
    commit("setReplacementStep", null);
  },
  replaceStep({ dispatch, commit, state }) {
    const stepIndex = state.activeTemplateSteps.findIndex(
      (step) => step.id === state.replaceStepId
    );
    const stepToReplace = state.activeTemplateSteps[stepIndex];
    commit("removeAttributesFromOutcomes", {
      stepId: stepToReplace.id,
      removedAttributes: stepToReplace.attributes
    });
    state.activeTemplateSteps[stepIndex] = {
      ...stepToReplace,
      provider: state.replacementStep?.provider,
      title: state.replacementStep?.title,
      attributes: []
    };
    dispatch("cancelReplaceService");
  }
};

const getters: GetterTree<IOrchestrationTemplatesState, IRootState> = {
  allTemplates(state) {
    return state.all;
  },
  loading(state) {
    return state.loading;
  },
  activeTemplate(state) {
    return state.activeTemplate;
  },
  activeTemplateSteps(state): IStep[] {
    return state.activeTemplateSteps;
  },
  stepById:
    (state) =>
    (id: string): IStep | undefined => {
      return state.activeTemplateSteps.find((step) => step.id === id);
    },
  activeServices(state) {
    return state.activeServices;
  },
  selectedSteps: (state) => state.selectedSteps,
  copiedSteps: (state) => state.copiedSteps,
  meta(state): IResponseMeta {
    return state.meta;
  },
  triggers(state) {
    return state.activeServices?.other?.find(
      (element) => element.title === SERVICE_TRIGGER
    );
  },
  serviceInfoByTitle:
    (state) =>
    (title: string): IDataService | undefined => {
      return state.activeServices?.all_data_services?.find(
        (service) => service.title === title
      );
    },
  appDataAttributes(state) {
    return (
      state.activeServices.other?.find(
        (service) => service.title === SERVICE_APPLICATION_DATA
      )?.attributes || []
    );
  },
  customAttributesAttributes(state) {
    return (
      state.activeServices.other?.find(
        (service) => service.title === SERVICE_CUSTOM_ATTRIBUTES
      )?.attributes || []
    );
  },
  activeServiceAttributes(state) {
    return (state.activeServices?.all_data_services || []).flatMap(
      (dataService: IDataService) =>
        Object.values(dataService?.data_service_details || {}).flatMap(
          (serviceDetails: Record<string, IAttribute[]>) =>
            Object.values(serviceDetails).flat()
        )
    );
  },
  activeStep(state): IStep | null {
    return state.activeStep;
  },
  activeConfigPanel(state) {
    return state.activeConfigPanel;
  },
  activeConfigPanelConditional(state) {
    return state.activeConfigPanelConditional;
  },
  replaceStepId(state) {
    return state.replaceStepId;
  },
  replacementStep(state) {
    return state.replacementStep;
  },
  nonPersistedTemplatesForWfo(state) {
    return state.nonPersistedTemplatesForWfo;
  }
};

export const orchestration = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
