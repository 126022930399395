export enum DynamicDate {
  TODAY = "today",
  YESTERDAY = "yesterday",
  THIS_WEEK = "this_week",
  LAST_WEEK = "last_week",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  LAST_THIRTY_DAYS = "last_thirty_days",
  THIS_QUARTER = "this_quarter",
  LAST_SIX_MONTHS = "last_six_months",
  THIS_YEAR = "this_calendar_year",
  LAST_YEAR = "last_calendar_year",
  LAST_365_DAYS = "last_365_days"
}

export enum Unit {
  Dollar = "$",
  Hours = "hours",
  Minutes = "minutes",
  Months = "months",
  Points = "points"
}
