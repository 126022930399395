import store from "@/store";

import type {
  ApplicationCreatedEvent,
  DataOrchestrationLogEvent,
  ExternalServiceLogEvent,
  ApplicationScoreCardGroupResultsFinishedEvent,
  ApplicationScoreCardResultsFinishedEvent,
  DocuSignEvent,
  OfferAcceptedEvent,
  PlaidConnectionEvent
} from "@/helpers/websockets/applications/events";

import useApplicationsStore from "@/stores/applications";
import type { IDataOrchestrationLog } from "@/models/applications";
import type { MoneyThumbAnalyticsPayload } from "@/models/commercialData/moneythumb/MoneyThumbCfa";
import i18n from "@/i18n";
import { WORKFLOW_BLOCKS_IDS } from "@/helpers/constants/workflow";
import { getValidBlockIds } from "@/helpers/workflow";
import applicationsApiService from "@/services/modules/applications";
import { PHASE_PLACEMENT } from "@/helpers/constants";

export const handleApplicationOffers = (data: OfferAcceptedEvent): void => {
  if (!data.application_id) {
    return;
  }

  store.dispatch("applications/getOffers");
};

export const handleEnrichmentResponseUpdatedEvent = (data: {
  application_id: string;
}): void => {
  if (!data.application_id) {
    return;
  }

  const { application_id: id } = data;

  store.dispatch("applications/getApplication", id);
  store.dispatch("applications/getCommercialData", { id });
  store.dispatch("underwriting/getUnderwritingLogs", id);
};

export const handleExternalServiceLogEvent = (
  data: ExternalServiceLogEvent
): void => {
  if (!data.applicationId) {
    return;
  }

  const { applicationId: id, serviceId } = data;

  const moneythumbCfaAccountNumber =
    store.state?.applications?.moneythumbCfaAccountNumber;
  const bookId = store.state.applications.active.moneythumb_book?.id;

  store.dispatch("applications/getApplication", id);
  store.dispatch("applications/getCommercialData", { id });
  store.dispatch("underwriting/getUnderwritingLogs", id);

  if (serviceId === "moneythumb_cfa") {
    if (!bookId) {
      store.commit("applications/setMoneythumbAnalytics", null);
      return;
    }
    const payload: MoneyThumbAnalyticsPayload = {
      applicationId: id,
      bookId
    };
    if (
      moneythumbCfaAccountNumber !==
      i18n.global.t("DEALS.DEAL_PROGRESS.BANK_CONNECTION.SUMMARY")
    ) {
      payload["bank_accounts"] = [moneythumbCfaAccountNumber];
    }
    store.dispatch("applications/getMoneythumbAnalytics", payload);
  }

  if (serviceId.includes("plaid")) {
    store.dispatch("applications/getBankData", id);
    store.dispatch("applications/getPlaidData", id);
    store.commit("applications/setPlaidDataLoading", false);
  }
};

export const handleDataOrchestrationLogEvent = (
  data: DataOrchestrationLogEvent
): void => {
  if (!data.applicationId || !data.logId) {
    return;
  }

  const { applicationId: appId } = data;
  const applicationsStore = useApplicationsStore();

  applicationsStore.getLatestDataOrchestrationLog({ appId });

  store.dispatch("underwriting/getUnderwritingLogs", appId);
};

export const handleDocuSignEvent = async (data: DocuSignEvent) => {
  if (!data.id) {
    return;
  }
  store.commit("applications/setLoading", true);
  await store.dispatch("applications/getApplication", data.id);
  store.commit("applications/setLoading", false);
};

export const handleApplicationCreatedEvent = (
  data: ApplicationCreatedEvent
): void => {
  if (!data?.id) {
    return;
  }
  store.dispatch("applications/getAllWithSameParams");
};

export const handleApplicationScoreCardGroupResultsFinishedEvent = ({
  application_id
}: ApplicationScoreCardGroupResultsFinishedEvent) => {
  store.dispatch("scorecards/respondToResultFinishedEvent", { application_id });
};

export const handleApplicationScoreCardResultsFinishedEvent = ({
  application_id
}: ApplicationScoreCardResultsFinishedEvent) => {
  const workflow_stage_id = store.state.workflows.activeStage.id;
  const isWorkflowStageScoreCards =
    store.state.workflows.activeStage?.type === "scorecards";
  if (!isWorkflowStageScoreCards) {
    return;
  }
  store.dispatch("scorecards/respondToResultFinishedEvent", {
    application_id,
    workflow_stage_id
  });
};

export const handlePlacementStage = () => {
  const isWorkflowPlacementStage =
    store.state.workflows.activeStage?.type ===
    PHASE_PLACEMENT.toLocaleLowerCase();
  if (!isWorkflowPlacementStage) {
    return;
  }
  store.dispatch("applications/getPlacements");
};

export const handleExecuteDataOrchestrationBatchFinishedEvent =
  async (applicationsResponse: {
    application_id: string;
    data_orchestration_log_id: IDataOrchestrationLog["id"];
  }) => {
    if (!applicationsResponse) {
      return;
    }

    const applicationsStore = useApplicationsStore();

    await applicationsStore.getDataOrchestrationLog({
      appId: applicationsResponse.application_id,
      logId: applicationsResponse.data_orchestration_log_id
    });

    const dataOrchestrationLog = applicationsStore.activeDataOrchestrationLog;

    store.commit("applications/updateApplicationDataInList", {
      application_id: applicationsResponse.application_id,
      path: "data_orchestration_log",
      value: dataOrchestrationLog
    });
  };

export const handleApplicationUpdatedEvent = async () => {
  const blockIdsToFetch = [
    WORKFLOW_BLOCKS_IDS.business_info,
    WORKFLOW_BLOCKS_IDS.personal_info
  ];

  const payload = getValidBlockIds(
    blockIdsToFetch,
    "application",
    store.state.workflows.activeTemplate
  );
  const activeDealId = store.state.applications.active.id;
  if (!payload.length || !activeDealId) {
    return;
  }
  store.dispatch("workflows/getBlocksData", payload);
  const data = await applicationsApiService.getApplication(activeDealId);
  store.commit("applications/setActive", data);
};

export const handlePlaidEvent = ({ application_id }: PlaidConnectionEvent) => {
  if (!application_id) {
    return;
  }
  store.dispatch("applications/getPlaidData", application_id);
};
