import { formatString } from "@/helpers/formatting";
import type { IndividualOrBusiness } from "@/models/IndividualProfile";
import type { CreateEntityProfilePayload } from "@/models/entityProfiles";
import type { IApplication } from "@/models/applications";
import type { IProfile } from "@/models/businesses";
import type { IPaginatedResponse, ParamRecord } from "@/models/common";
import type { Entity } from "@/enums/entityProfiles";

import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import type { AxiosResponse } from "axios";

class ProfilesApiService {
  async getProfilesByType(type: Entity, params: Record<string, unknown>) {
    const url = formatString(endpoints.PROFILES.ALL_BY_TYPE, type);
    const { data } = await axiosClient.get<IPaginatedResponse<IProfile>>(url, {
      params
    });
    return data;
  }

  async getSingleProfileByType<ET extends Entity>(
    entityType: ET,
    profileId: IProfile["id"]
  ): Promise<IndividualOrBusiness<ET>> {
    const url = formatString(
      endpoints.PROFILES.SINGLE_BY_TYPE,
      entityType,
      profileId
    );
    const response =
      await axiosClient.get<AxiosResponse<IndividualOrBusiness<ET>>>(url);

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data.data;
  }

  async updateProfileByType<ET extends Entity>(
    type: ET,
    profileId: string,
    data: Record<string, unknown>
  ): Promise<IndividualOrBusiness<ET>> {
    const url = formatString(
      endpoints.PROFILES.SINGLE_BY_TYPE,
      type,
      profileId
    );
    const response = await axiosClient.put<{ data: IndividualOrBusiness<ET> }>(
      url,
      data
    );

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.data.data;
  }

  async getProfileDeals(
    type: Entity,
    profileId: string,
    params?: {
      search?: string;
      per_page?: number;
      page?: number;
    } & ParamRecord
  ) {
    const url = formatString(endpoints.PROFILES.DEALS, type, profileId);
    const { data } = await axiosClient.get<IPaginatedResponse<IApplication>>(
      url,
      { params }
    );
    return data;
  }

  async updateBusinessEntityProfile(
    profileId: string,
    params: Record<string, unknown>
  ) {
    const url = formatString(
      endpoints.PROFILES.SINGLE_BY_TYPE,
      "business",
      profileId
    );
    const { data } = await axiosClient.put(url, params);
    return data;
  }
  async createEntityProfile(payload: CreateEntityProfilePayload) {
    const { data } = await axiosClient.post(
      endpoints.PROFILES.CREATE_PROFILE,
      payload
    );
    return data;
  }
}

const profilesApiService = new ProfilesApiService();
export default profilesApiService;
