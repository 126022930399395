import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import { formatString } from "@/helpers/formatting";
import type {
  NoteFilterParams,
  NotesResource,
  Note,
  NotePayload
} from "@/models/notes";

class NotesService {
  async getNotes(params: NoteFilterParams) {
    const response = await axiosClient.get<NotesResource>(endpoints.NOTES.ALL, {
      params
    });
    return response;
  }

  async createNote(payload: NotePayload) {
    const response = await axiosClient.post(endpoints.NOTES.ALL, payload);
    return response.data;
  }

  async updateNote(payload: NotePayload) {
    if (!payload.id) {
      return;
    }
    const response = await axiosClient.put(
      formatString(endpoints.NOTES.SINGLE, payload.id),
      payload
    );
    return response.data;
  }

  async deleteNote(id: Note["id"]) {
    if (!id) {
      return;
    }
    await axiosClient.delete(formatString(endpoints.NOTES.SINGLE, id));
  }

  async markNotesAsRead(notes: (number | string)[]) {
    await axiosClient.post(endpoints.NOTES.MARK_AS_READ, { notes });
  }
}

const notesService = new NotesService();
export default notesService;
