import config from "@/config";
import { formatString } from "@/helpers/formatting";
import type { IRequiredStipForOffer } from "@/models/applications";
import type {
  IFile,
  ISmartyStreetsResponse,
  ISmartyStreetsSuggestion
} from "@/models/common";
import type { AxiosResponse } from "axios";
import Axios from "axios";

const ENDPOINTS = {
  REQ_STIPS_FOR_OFFER: "api/ext/offers/%s/required-stips",
  STIP_FILE: "api/ext/offers/%s/stip-file",
  DELETE_STIP_FILE: "api/ext/offers/%s/stip-file/%s",
  SMARTY_STREETS: "https://us-autocomplete-pro.api.smartystreets.com/lookup"
};

const axiosClient = Axios.create({
  baseURL: config.api.baseUrl
});

class ExternalApiService {
  async getRequiredStipsForOffer(offerId: string) {
    const response = await axiosClient.get<{ data: IRequiredStipForOffer[] }>(
      formatString(ENDPOINTS.REQ_STIPS_FOR_OFFER, offerId)
    );
    return response.data.data;
  }

  async uploadStipFile(
    offerId: string,
    offerStipId: number,
    file: File,
    additionalConfig: Record<string, unknown> = {}
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("offer_stip_id", `${offerStipId}`);

    const response = await axiosClient.post<{ data: IFile }>(
      formatString(ENDPOINTS.STIP_FILE, offerId),
      formData,
      {
        headers: {
          "content-type": "multipart/form-data"
        },
        ...additionalConfig
      }
    );
    return response.data.data;
  }

  async removeUploadedFile(payload: {
    index: number;
    section: number;
    fileId: number;
    offerId: string;
  }) {
    await axiosClient.delete(
      formatString(ENDPOINTS.DELETE_STIP_FILE, payload.offerId, payload.fileId)
    );
  }

  /**
   * Get a list of address suggestions for a given search query
   * https://www.smarty.com/docs/cloud/us-autocomplete-pro-api
   */
  async smartyStreetsRequest(
    search: string,
    params?: Record<string, unknown>
  ): Promise<AxiosResponse<ISmartyStreetsResponse>> {
    const key = config.smartyStreetsKey || "";
    return await axiosClient.get(ENDPOINTS.SMARTY_STREETS, {
      params: {
        ...params,
        search,
        key
      }
    });
  }

  /**
   * Given a verified address suggestion with multple entries (i.e. apartment numbers),
   * returns the list of suggestions to all possible address matches.
   * https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
   */
  async expandSmartyStreetsSuggestion(
    suggestion: ISmartyStreetsSuggestion,
    params?: Record<string, unknown>
  ) {
    const { street_line, secondary, entries, city, state, zipcode } =
      suggestion;
    const cityStateZip = `${city}, ${state} ${zipcode}`;
    let search = `${street_line}${secondary && ` ${secondary}`}`;
    let selected;

    if (entries > 1) {
      selected = `${search} (${entries}) ${cityStateZip}`;
    } else {
      search += ` ${cityStateZip}`;
    }

    return await this.smartyStreetsRequest(search, { ...params, selected });
  }
}

const externalApiService = new ExternalApiService();
export default externalApiService;
