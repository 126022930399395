import authApiService from "@/services/modules/authentication";
import clientApiService from "@/services/modules/clients";
import userApiService from "@/services/modules/users";
import chunk from "lodash/chunk";
import {
  leaveChannel,
  subscribeToChannelEvents
} from "@/helpers/websockets/channels";
import {
  AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS,
  AUTH_SOCKETS_CHANNEL_FOR_CLIENT
} from "@/helpers/websockets/common";
import router from "@/router";
import {
  ADMIN,
  CLIENT_ADMIN,
  FUNDING_ADVISOR,
  SUPER_ADMIN,
  UNDERWRITER,
  USER,
  FUNDER_ADMIN,
  FUNDER_USER,
  FUNDER_ROLE_GROUP,
  CLIENT_UNDERWRITER,
  CLIENT_ROLE_GROUP,
  ANALYST,
  CLIENT_ANALYST,
  CLIENT_FUNDING_ADVISOR,
  LENDFLOW_ROLE_GROUP
} from "@/helpers/constants";
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";
import type {
  IAuthState,
  CreateOrResetPayload,
  ILoginPayload,
  ILoginResponse,
  ProductSettingPayload,
  IAbility,
  IAuthUser,
  IIntegration
} from "@/models/authentications";
import { isClient, isFunder } from "@/helpers/auth";
import * as amplitude from "@amplitude/analytics-browser";

import type { IUser } from "@/models/users";
import type { IRootState } from "@/models/state";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IErrorResponse } from "@/models/common";
import type { Role } from "@/models/options";
import type { IClient } from "@/models/clients";

import { getAbilityChecksum } from "@/helpers/auth";
import { prepareUrl } from "@/helpers/formatting";

import { useCommunicationStore } from "@/stores/communication";

const VALID_ROLES_FOR_USER: Record<string, Role[]> = {
  [SUPER_ADMIN]: [SUPER_ADMIN, ADMIN, UNDERWRITER, FUNDING_ADVISOR, ANALYST],
  [ADMIN]: [ADMIN, UNDERWRITER, FUNDING_ADVISOR, ANALYST],
  [UNDERWRITER]: [],
  [FUNDING_ADVISOR]: [],
  [CLIENT_ADMIN]: [USER, CLIENT_ANALYST, CLIENT_FUNDING_ADVISOR],
  [USER]: [],
  [FUNDER_ADMIN]: [FUNDER_USER],
  [FUNDER_USER]: [],
  [CLIENT_UNDERWRITER]: [],
  [CLIENT_FUNDING_ADVISOR]: []
};

const VALID_ROLES_FOR_FUNDER_USER: Record<string, Role[]> = {
  [SUPER_ADMIN]: [FUNDER_USER],
  [ADMIN]: [FUNDER_USER],
  [UNDERWRITER]: [],
  [FUNDING_ADVISOR]: [],
  [CLIENT_ADMIN]: [],
  [USER]: [],
  [FUNDER_ADMIN]: [FUNDER_USER],
  [FUNDER_USER]: [],
  [CLIENT_UNDERWRITER]: [],
  [CLIENT_FUNDING_ADVISOR]: []
};

const state: IAuthState = {
  user: null,
  accessToken: localStorage.getItem("accessToken") || "",
  authClientSettings: {} as IClient,
  authClientIntegration: {} as IIntegration,
  impersonating: localStorage.getItem("impersonatorToken")
    ? (
        JSON.parse(localStorage.getItem("impersonatorToken") || "") as {
          impersonating: string;
        }
      ).impersonating
    : "",
  userEmail: null,
  preferences: { dealColumns: {} },
  currentPermissions: {},
  permissionsToRequest: {},
  loadingPermissions: false
};

const mutations: MutationTree<IAuthState> = {
  setAccessToken(state: IAuthState, accessToken: string) {
    localStorage.setItem("accessToken", accessToken);
    state.accessToken = accessToken;
  },
  setUser(state, user: IAuthUser) {
    state.user = user;
  },
  setUpdatedAuthUser(state, updatedUser: IAuthUser) {
    state.user = { ...state.user, ...updatedUser };
  },
  setAuthClientSettings(state, authClientSettings: IClient) {
    if (authClientSettings.id) {
      state.authClientSettings = {
        ...state.authClientSettings,
        ...authClientSettings
      };
    } else {
      // unset client settings state if data sent is empty
      // can occur if lendflow user impersonates client user then stops impersonating
      // otherwise if test mode is enabled it would remain enabled when impersonation stops
      state.authClientSettings = {} as IClient;
    }
  },
  setAuthClientIntegration(state, integration: IIntegration) {
    state.authClientIntegration = {
      ...state.authClientIntegration,
      ...integration
    };
  },
  setImpersonating(state, impersonatedUser: string) {
    state.impersonating = impersonatedUser;
  },
  setEmail(state, email: string) {
    state.userEmail = email;
  },
  setColumnPreferences(state, columnPreferences: Record<string, boolean>) {
    state.preferences.dealColumns = columnPreferences;
  },
  setProductSettings(state, payload: ProductSettingPayload) {
    const productSetting = state.authClientIntegration.product_settings.find(
      (setting) => setting.product_type === payload.product_type
    );
    if (productSetting) {
      productSetting.custom_required_fields = payload.fields;
      productSetting.is_using_default = payload.is_using_default;
    } else {
      state.authClientIntegration.product_settings.push({
        product_type: payload.product_type,
        custom_required_fields: payload.fields,
        is_using_default: payload.is_using_default
      });
    }
  },
  setPermissionsLoading(state, loading: boolean) {
    state.loadingPermissions = loading;
  },
  setPermissionsToRequest(state, permissions: Record<string, IAbility>) {
    state.permissionsToRequest = permissions;
  },
  addPermission(state, abilities: IAbility[]) {
    abilities.forEach((ability) => {
      state.currentPermissions[getAbilityChecksum(ability)] = ability;
    });
  },
  purgePermissionsToRequest(state) {
    const filteredPermissionsToRequest = pickBy(
      state.permissionsToRequest,
      (_, key) => !state.currentPermissions[key]
    );
    state.permissionsToRequest = filteredPermissionsToRequest;
  },
  clearCurrentPermissions(state) {
    state.currentPermissions = {};
  },
  addPermissionToRequest(state, abilities: IAbility[]) {
    abilities.forEach((ability) => {
      const permissionChecksum = getAbilityChecksum(ability);
      if (
        !state.permissionsToRequest[permissionChecksum] &&
        !state.currentPermissions[permissionChecksum]
      ) {
        state.permissionsToRequest[permissionChecksum] = ability;
      }
    });
  },
  clearPermission(
    state,
    { subject, ability }: Pick<IAbility, "ability" | "subject">
  ) {
    state.currentPermissions = pickBy(state.currentPermissions, (value) => {
      return value.ability !== ability && value.subject !== subject;
    });
  }
};

const actions: ActionTree<IAuthState, IRootState> = {
  async register(_, user: Partial<IClient>) {
    const payload = {
      ...user,
      company_website: prepareUrl(user.company_website || "")
    };
    const data = await authApiService.register(payload);
    return data;
  },
  async login({ dispatch, commit }, user: ILoginPayload) {
    try {
      commit("resetHistoryStack", null, { root: true });
      const data = await authApiService.login(user);
      if (user.redirectTo) {
        data.redirectTo = user.redirectTo;
      }
      await dispatch("postLogin", data);
    } catch (error) {
      if ((error as IErrorResponse)?.response?.status === 511) {
        commit(
          "setGlobalMessage",
          {
            title:
              "You are unable to access this site because you are currently not connected to VPN. Please connect to VPN to access this site.",
            type: "error",
            duration: 8000
          },
          { root: true }
        );

        return;
      }

      //redirect user to renew password page if their passowrd is expired
      if (
        (error as IErrorResponse)?.response?.data?.meta?.error_code ===
        "password_expired"
      ) {
        commit("setEmail", user.email);
        void router.push({ name: "RenewPassword" });
      }
    }
  },
  async enrichUserData({ dispatch, state }) {
    await dispatch("getAuthDetails");
    await Promise.allSettled([
      dispatch("getAuthClientSettings"),
      dispatch("options/getAll", null, { root: true }),
      dispatch("options/resetOptionsByName", "application", { root: true }),
      dispatch("options/resetOptionsByName", "workflow_templates", {
        root: true
      })
    ]);

    const user = state.user;
    subscribeToChannelEvents(
      AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS,
      String(user?.id)
    );
    if (isClient(user)) {
      const communicationStore = useCommunicationStore();
      await communicationStore.getActivityHubInfo();
      subscribeToChannelEvents(
        AUTH_SOCKETS_CHANNEL_FOR_CLIENT,
        String(user?.client_id)
      );
    }
  },
  async postLogin({ commit, dispatch, state }, data: Partial<ILoginResponse>) {
    commit("setAccessToken", data.access_token);
    await dispatch("enrichUserData");
    const activeUser = state.user;
    await dispatch("flushAuth");

    if (data.redirectTo === undefined) {
      data.redirectTo = {
        name:
          isFunder(activeUser) && !isClient(activeUser)
            ? "Funder-Details"
            : "Home"
      };
    }

    const isLendflowUser = !!activeUser?.roles.some((role) =>
      LENDFLOW_ROLE_GROUP.includes(role)
    );
    const ampIdentifyProperties = [
      ["Client ID", activeUser?.client_id ?? ""],
      ["Lendflow Employee", isLendflowUser],
      ["User ID", activeUser?.id ?? ""]
    ] as const;

    const identify = new amplitude.Identify();
    ampIdentifyProperties.forEach(([key, value]) => {
      identify.set(key, value);
    });
    amplitude.identify(identify);

    return data.redirectTo !== null ? router.push(data.redirectTo) : null;
  },
  async refreshToken({ commit }) {
    const data = await authApiService.refreshToken();
    commit("setAccessToken", data.access_token);
  },
  async logout({ commit, dispatch, state }) {
    const activeUser = state.user;
    leaveChannel(AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS, String(activeUser?.id));
    if (isClient(state.user)) {
      leaveChannel(AUTH_SOCKETS_CHANNEL_FOR_CLIENT, String(activeUser?.id));
    }
    commit("setAccessToken", "");
    commit("setUser", null);
    commit("options/resetState", null, { root: true });
    dispatch("resetAppState", null, { root: true });
    localStorage.removeItem("accessToken");
    await dispatch("stopImpersonating", { getInitial: false });
  },
  async impersonateUser(
    { dispatch, commit, state, getters },
    { userId, canRedirect = true }: { userId: number; canRedirect: boolean }
  ) {
    const data = await authApiService.getImpersonatedToken(userId);
    const oldUser = state.user;
    const impersonatorAccessToken = new String(state.accessToken);
    if (!canRedirect) {
      data.redirectTo = null;
    }
    leaveChannel(AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS, String(oldUser?.id));
    await dispatch("resetAppState", null, { root: true });
    await dispatch("postLogin", data);
    const newUser = getters["user"];
    const impersonatedUserName = `${newUser?.first_name} ${newUser?.last_name}`;
    localStorage.setItem(
      "impersonatorToken",
      JSON.stringify({
        access_token: impersonatorAccessToken,
        impersonating: impersonatedUserName
      })
    );
    commit("setImpersonating", impersonatedUserName);
  },
  async stopImpersonating(
    { dispatch, commit, state },
    { getInitial = true }: { getInitial: boolean }
  ) {
    const activeUser = state.user;
    leaveChannel(AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS, String(activeUser?.id));
    if (isClient(activeUser)) {
      leaveChannel(AUTH_SOCKETS_CHANNEL_FOR_CLIENT, String(activeUser?.id));
    }
    commit("setImpersonating", null);
    if (getInitial) {
      const data: ILoginResponse = JSON.parse(
        localStorage.getItem("impersonatorToken") || ""
      );

      if (data.access_token) {
        const postLoginPayload: Partial<ILoginResponse> = {
          access_token: data.access_token,
          redirectTo: null
        };
        await dispatch("postLogin", postLoginPayload);
        dispatch("resetAppState", null, { root: true });
      }
    }
    localStorage.removeItem("impersonatorToken");
  },
  async sendResetLink(_, data: { email: string }) {
    return await authApiService.sendResetLink(data);
  },
  async createOrResetPassword(
    _,
    data: { password: string; password_confirmation: string }
  ) {
    return await authApiService.createOrResetPassword(data);
  },
  async renewPassword({ commit }, data: CreateOrResetPayload) {
    const response = await authApiService.renewPassword(data);
    commit("setEmail", null);
    return response;
  },
  async updateAuthUser({ commit, state }, userData: IUser) {
    if (!state.user?.id) {
      return;
    }
    const updatedUser = await userApiService.updateUser(
      userData,
      state.user.id
    );
    commit("setUpdatedAuthUser", updatedUser);
  },
  async deleteAuthUser({ dispatch }) {
    if (!state.user?.id) {
      return;
    }
    await userApiService.deleteUser(state.user.id);
    void dispatch("logout");
    void router.push("/login");
  },
  async getAuthClientSettings({ commit, rootState }) {
    const clientId = state.user?.client_id || rootState.clients.active?.id;
    const hasFunderRole = !!state.user?.roles?.some((role) =>
      FUNDER_ROLE_GROUP.includes(role)
    );
    const hasClientRole = !!state.user?.roles?.some((role) =>
      CLIENT_ROLE_GROUP.includes(role)
    );
    if (!clientId || (hasFunderRole && !hasClientRole)) {
      commit("setAuthClientSettings", {});
      return;
    }
    const authClientSettings = await clientApiService.getClient(clientId);
    commit("setAuthClientSettings", authClientSettings);
  },
  async getAuthDetails({ commit }) {
    const userData = await authApiService.getAuthDetails();
    const columnPreferences = userData.preferences.find(
      (preference) => preference.key === "hidden-report-columns"
    );
    commit("setColumnPreferences", columnPreferences?.value || {});
    commit("setUpdatedAuthUser", userData);
    return userData;
  },
  async getAuthClientIntegration(
    { commit, state },
    clientId = state.authClientSettings.id
  ) {
    if (!clientId) {
      return;
    }

    const clientIntegration =
      await clientApiService.getClientIntegration(clientId);
    commit("setAuthClientIntegration", clientIntegration);
  },
  async updateAuthClientSettings(
    { state, commit, dispatch },
    data: Partial<IClient>
  ) {
    const updatedAuthClient = await clientApiService.updateClient(
      data,
      state.authClientSettings.id
    );
    commit("setAuthClientSettings", updatedAuthClient);
    if (data.ip_addresses) {
      await dispatch("getAuthClientIntegration");
    }
    return updatedAuthClient;
  },
  async saveColumnPreferences({ commit }, data: Record<string, boolean>) {
    await clientApiService.saveColumnPreferences(data);
    commit("setColumnPreferences", data);
  },
  async saveProductSettings({ commit }, payload: ProductSettingPayload) {
    await clientApiService.updateCustomRequiredFields(
      state.authClientSettings.id,
      { custom_required_fields: [payload] }
    );
    commit("setProductSettings", payload);
  },
  async getPermissionAbility({ commit, state }, ability: IAbility[]) {
    const permissionsToRequest: IAbility[] = [];
    const existingPermissions: IAbility[] = [];
    //we only want to request permissions if they don't already exist
    ability.forEach((ability) => {
      const lookedUpPermission =
        state.currentPermissions[getAbilityChecksum(ability)];
      if (!lookedUpPermission) {
        permissionsToRequest.push({
          subject: ability.subject,
          ability: ability.ability,
          arguments: ability.arguments || {}
        });
        return;
      }
      existingPermissions.push(lookedUpPermission);
    });
    if (!permissionsToRequest.length) {
      return existingPermissions;
    }
    try {
      const retreivedPermission =
        await authApiService.getAbilityPermission(permissionsToRequest);

      commit("addPermission", retreivedPermission);
    } finally {
      commit("setPermissionsLoading", false);
    }
  },
  async flushAuth({ commit, dispatch, state }) {
    const permissionsToRenew = Object.values(state.currentPermissions);
    commit("clearCurrentPermissions");
    commit("addPermissionToRequest", permissionsToRenew);
    dispatch("checkAuthBatched");
  },
  checkAuthBatched: throttle(
    async ({ commit, dispatch, state }) => {
      const permissionValues = Object.values(state.permissionsToRequest);
      const permissionBatches = chunk(permissionValues, 20);
      await Promise.all(
        permissionBatches.map((batch) =>
          dispatch("getPermissionAbility", batch)
        )
      );
      commit("purgePermissionsToRequest");
    },
    500,
    { leading: false, trailing: true }
  )
};

const getters: GetterTree<IAuthState, IRootState> = {
  isAuthenticated(state) {
    return !!state.accessToken;
  },
  accessToken(state) {
    return state.accessToken;
  },
  user(state) {
    return state.user;
  },
  manageUserRoles: (state, _, rootState) => (isForEmployees: boolean) => {
    if (!isForEmployees) {
      return VALID_ROLES_FOR_USER[CLIENT_ADMIN];
    }
    const allRoles = rootState.options.all.roles;
    const foundRoles =
      state.user?.roles?.reduce(
        (acc: Role[], role: Role) => [...acc, ...VALID_ROLES_FOR_USER[role]],
        []
      ) || [];
    return Object.values(
      pickBy(allRoles, (value: Role) => foundRoles.includes(value))
    );
  },
  funderUserRoles(state) {
    return state.user?.roles?.reduce(
      (acc: Role[], role: Role) => [
        ...acc,
        ...VALID_ROLES_FOR_FUNDER_USER[role]
      ],
      []
    );
  },
  authClientSettings(state) {
    return state.authClientSettings;
  },
  customSubDomain(state) {
    return state.authClientSettings?.custom_subdomain ?? null;
  },
  authClientIntegration(state) {
    return state.authClientIntegration;
  },
  impersonating(state) {
    return state.impersonating;
  },
  getEmail(state) {
    return state.userEmail;
  },
  columnsPreferences(state) {
    return state.preferences;
  },
  permissionsList(state) {
    return state.currentPermissions;
  },
  permissionsToRequest(state) {
    return state.permissionsToRequest;
  },
  permissionsLoading(state) {
    return state.loadingPermissions;
  }
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
