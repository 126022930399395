import { formatString } from "@/helpers/formatting";
import type { IApplication } from "@/models/applications";
import type { IPaginatedResponse } from "@/models/common";
import type { IBusinessEntityProfile } from "@/models/businesses";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

class BusinessesApiService {
  async getBusinesses(params: Record<string, unknown>) {
    const response = await axiosClient.get<
      IPaginatedResponse<IBusinessEntityProfile>
    >(endpoints.BUSINESSES.ALL, {
      params
    });
    return response.data;
  }

  async getSingleBusiness(id: number) {
    const response = await axiosClient.get<{ data: IBusinessEntityProfile }>(
      formatString(endpoints.BUSINESSES.SINGLE, id)
    );
    return response.data.data;
  }

  async getBusinessApplications(id: number, params: Record<string, unknown>) {
    const response = await axiosClient.get<
      IPaginatedResponse<IBusinessEntityProfile>
    >(formatString(endpoints.BUSINESSES.APPLICATIONS, id), { params });
    return response.data;
  }

  async getLastBusinessApplication(id: number) {
    const response = await axiosClient.get<{ data: IApplication }>(
      formatString(endpoints.BUSINESSES.LAST_APPLICATION, id),
      { params: { with_underwriting: true } }
    );
    return response.data.data;
  }

  async createNewApplication(
    businessId: string | number,
    data: Partial<IApplication>
  ) {
    const response = await axiosClient.post<{ data: IApplication }>(
      formatString(endpoints.BUSINESSES.APPLICATIONS, businessId),
      data
    );

    return response.data.data;
  }
}

const businessesApiService = new BusinessesApiService();
export default businessesApiService;
