import { defineStore } from "pinia";
import vuexStore from "@/store";
import { useRoute } from "vue-router";
import uniq from "lodash/uniq";
import type { IApplication } from "@/models/applications";
import type {
  BacktestReportList,
  IScorecardContentCategory,
  IScorecardResult,
  IScorecardGroupResult,
  IScorecardsViewParams
} from "@/models/scorecards";
import { TYPE_INDIVIDUAL, TYPE_GROUP } from "@/helpers/constants/scorecards";
import type { IPaginatedResponse } from "@/models/common";
import { expandContentCategory } from "@/helpers/scorecards";
import scorecardsApiService from "@/services/modules/scorecards";
import { ROUTE_SCORECARDS_BACKTEST_REPORT_VIEW } from "@/router/routes";

interface UseScorecardsState {
  activeBacktestPhase: number;
  backtestReportApplicationIds: Array<IApplication["id"]>;
  backtestReportScorecardId: string | null;
  backtestReportData:
    | IPaginatedResponse<IScorecardResult | IScorecardGroupResult>
    | undefined;
  backtestReports: IPaginatedResponse<BacktestReportList> | null;
  backtestResultIds: number[];
  runScorecardBacktestIds: string[];
  runScorecardGroupBacktestIds: string[];
  loadingBacktestReport: boolean;
  loadingScorecard: boolean;
  loadingScorecardGroup: boolean;
  viewParams: IScorecardsViewParams;
}

const getDefaultState = (): UseScorecardsState => ({
  activeBacktestPhase:
    useRoute()?.name === ROUTE_SCORECARDS_BACKTEST_REPORT_VIEW ? 2 : 0,
  backtestReportApplicationIds: [],
  backtestReportScorecardId: null,
  backtestReportData: undefined,
  backtestReports: null,
  backtestResultIds: [],
  runScorecardBacktestIds: [],
  runScorecardGroupBacktestIds: [],
  loadingBacktestReport: false,
  loadingScorecard: vuexStore.state.scorecards.loading,
  loadingScorecardGroup: vuexStore.state.scorecards.groupLoading,
  viewParams: {
    page: 1,
    per_page: 100,
    search: "",
    include_content: true,
    application_id: ""
  }
});

const useScorecardsStore = defineStore("scorecards", {
  state: getDefaultState,
  actions: {
    clearAllBacktestReportData() {
      this.backtestReportApplicationIds = [];
      this.backtestReportScorecardId = null;
      this.backtestResultIds = [];
      this.backtestReportData = undefined;
    },
    setBacktestReportData(
      data: IPaginatedResponse<IScorecardResult> | undefined
    ) {
      if (!data || !data.data.length) {
        this.backtestReportData = undefined;
        return;
      }
      data.data.map((report) => {
        report.categories.map((category, index) => {
          return expandContentCategory(
            category as IScorecardContentCategory,
            index,
            vuexStore.state.scorecards.activeServices
          );
        });
        return report;
      });
      this.backtestReportData = data;
    },
    setGroupBacktestReportData(
      data: IPaginatedResponse<IScorecardGroupResult> | undefined
    ) {
      this.backtestReportData = data ?? undefined;
    },
    addRunScorecardBacktestId(id: string) {
      this.runScorecardBacktestIds = uniq([
        ...this.runScorecardBacktestIds,
        id
      ]);
    },
    removeRunScorecardBacktestId(id: string) {
      this.runScorecardBacktestIds = this.runScorecardBacktestIds.filter(
        (runId) => runId !== id
      );
    },
    addRunScorecardGroupBacktestId(id: string) {
      this.runScorecardGroupBacktestIds = uniq([
        ...this.runScorecardGroupBacktestIds,
        id
      ]);
    },
    removeRunScorecardGroupBacktestId(id: string) {
      this.runScorecardGroupBacktestIds =
        this.runScorecardGroupBacktestIds.filter((runId) => runId !== id);
    },
    async getBacktestReports({
      params = {}
    }: {
      params?: Record<string, string | number>;
    }) {
      const data =
        await scorecardsApiService.getScorecardBacktestReports(params);
      this.backtestReports = data;
      return data;
    },
    async queueScorecardBacktestReport(payload: {
      id: string;
      applicationIds: string[];
      type: typeof TYPE_INDIVIDUAL | typeof TYPE_GROUP;
    }) {
      const fetchMethod =
        payload.type === TYPE_INDIVIDUAL
          ? scorecardsApiService.queueScorecardBacktestReport
          : scorecardsApiService.queueScorecardGroupBacktestReport;
      const setType =
        payload.type === TYPE_INDIVIDUAL
          ? "addRunScorecardBacktestId"
          : "addRunScorecardGroupBacktestId";
      const data = await fetchMethod(payload.id, payload.applicationIds);
      this[setType](payload.id);
      return data;
    },
    async runScorecardBacktestReport(payload: {
      id: string;
      type: typeof TYPE_INDIVIDUAL | typeof TYPE_GROUP;
      resultIds: number[];
      page?: number;
      per_page?: number;
    }) {
      const isGroupData = (
        data:
          | IPaginatedResponse<IScorecardGroupResult>
          | IPaginatedResponse<IScorecardResult>
      ): data is IPaginatedResponse<IScorecardGroupResult> =>
        payload.type === TYPE_GROUP;

      this.loadingBacktestReport = true;
      const data:
        | IPaginatedResponse<IScorecardGroupResult>
        | IPaginatedResponse<IScorecardResult> =
        await scorecardsApiService.getScorecardBacktestContextReport({
          id: payload.id,
          type: payload.type,
          payload: {
            page: payload.page ?? 1,
            per_page: payload.per_page ?? 100,
            result_ids: payload.resultIds
          }
        });

      if (isGroupData(data)) {
        this.setGroupBacktestReportData(data);
      } else {
        this.setBacktestReportData(data);
      }

      this.loadingBacktestReport = false;
      return data;
    },
    async respondToBacktestResultFinishedEvent(
      score_card_id: string,
      resultIds: number[]
    ) {
      const runIds = this.runScorecardBacktestIds;
      if (!runIds.length || !runIds.find((id) => id === score_card_id)) {
        return;
      }
      this.backtestResultIds = resultIds;
      this.runScorecardBacktestReport({
        id: this.runScorecardBacktestIds[0],
        type: TYPE_INDIVIDUAL,
        resultIds
      });
      this.removeRunScorecardBacktestId(score_card_id);
    },
    async respondToGroupBacktestResultFinishedEvent(
      score_card_group_id: string,
      resultIds: number[]
    ) {
      this.backtestResultIds = resultIds;
      const runIds = this.runScorecardGroupBacktestIds;
      if (!runIds.length || !runIds.find((id) => id === score_card_group_id)) {
        return;
      }
      this.runScorecardBacktestReport({
        id: this.runScorecardGroupBacktestIds[0],
        type: TYPE_GROUP,
        resultIds
      });
      this.removeRunScorecardGroupBacktestId(score_card_group_id);
    },
    clearViewParams() {
      this.viewParams = getDefaultState().viewParams;
    }
  }
});

export default useScorecardsStore;
