import type { ActionTree, GetterTree, MutationTree } from "vuex";

import profilesApiService from "@/services/modules/profiles/index";
import { Entity } from "@/enums/entityProfiles";
import type { IRootState } from "@/models/state";
import type { IProfile } from "@/models/businesses";
import type { IResponseMeta, ParamRecord } from "@/models/common";

interface ProfilesState {
  individuals: IProfile[];
  meta: IResponseMeta;
}

const getDefaultState = (): ProfilesState => ({
  individuals: [] as IProfile[],
  meta: {} as IResponseMeta
});

const state = getDefaultState();

const mutations: MutationTree<ProfilesState> = {
  setIndividuals(state, data: IProfile[]): void {
    state.individuals = data;
  },
  setMetadata(state, meta: IResponseMeta): void {
    state.meta = meta;
  },
  addIndividualProfile(state, data: IProfile): void {
    state.individuals.unshift(data);
  }
};

const actions: ActionTree<ProfilesState, IRootState> = {
  async getAllIndividuals({ commit }, params: ParamRecord) {
    const response = await profilesApiService.getProfilesByType(
      Entity.individual,
      params
    );

    commit("setIndividuals", response.data);
    commit("setMetadata", response.meta);
  }
};

const getters: GetterTree<ProfilesState, IRootState> = {
  individuals(state): IProfile[] {
    return state.individuals;
  },
  meta(state): IResponseMeta {
    return state.meta;
  }
};

export const profiles = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
