import type {
  Identifiable,
  MaybeArray,
  Nullish,
  StipsCollection
} from "@/models/common";

import type {
  IAttribute,
  IContextSetValueModel,
  ICustomAttributeContext,
  IDataService
} from "@/models/orchestration";

import type { IUser } from "@/models/users";
import type { IScorecard, IScorecardGroup } from "@/models/scorecards";
import i18n from "@/i18n";
import { DEFAULT_PAGE_TITLE, SERVICE_CUSTOM } from "@/helpers/constants";
import { UTIL_COLORS } from "@/helpers/constants";
import type { WorkflowSnapshotStatus } from "@/models/applications";

export const handleSortByChange = (
  value: string,
  filters: Record<string, unknown>
) => (filters.sort_by = filters.sort_by !== value ? value : `-${value}`);

export const formatUsersForFilterDropdown = (
  data: IUser[],
  includeNoneOption = true
) => {
  return data.reduce<Record<string, string>>(
    (acc, curr) => {
      let name =
        curr?.full_name ||
        [curr?.first_name, curr?.last_name].filter(Boolean).join(" ") ||
        "";

      if (curr.deleted_at) {
        name += ` (${i18n.global.t("COMMON.DELETED")})`;
      }

      return { ...acc, [curr.id]: name };
    },
    // add option for no persons
    includeNoneOption ? { ["null"]: i18n.global.t("COMMON.NONE") } : {}
  );
};

// Reduces (IScorecard | IScorecardGroup)[] to Object { [id]: name }
export const formatScorecardsForFilterDropdown = (
  data: (IScorecard | IScorecardGroup)[],
  includeNoneOption = false
) => {
  return data.reduce<Record<string, string>>(
    (acc, curr) => {
      acc[curr.id] = curr.name;
      return acc;
    },
    includeNoneOption ? { ["null"]: i18n.global.t("COMMON.NONE") } : {}
  );
};

export const getWorkflowStatusFormatter = (
  property: "status" | "sub_status"
) => {
  return (data: WorkflowSnapshotStatus[]) => {
    return data.reduce<Record<string, string>>(
      (acc, curr) => {
        if (!curr[property]) {
          return acc;
        }

        acc[curr[property]] = curr[property];
        return acc;
      },
      { ["null"]: i18n.global.t("COMMON.NONE") }
    );
  };
};

export const getStipNameByType = (
  type: string,
  collections: StipsCollection[]
) => {
  const category = collections
    .flatMap((c) => c.categories)
    .find((c) => c.type === type);
  return category ? category.name : "";
};

export const getDropdownOptions = (data: Identifiable[]) =>
  Object.values(data).reduce<Record<string, string>>((acc, curr) => {
    if (curr.id) {
      acc[curr.id] = curr.name;
    }
    return acc;
  }, {});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getDropdownOptionsByKey = <
  TData extends Record<string, any>,
  TKey extends keyof TData
>(
  data: TData[],
  key: TKey
): Record<string, TData[TKey]> => {
  return data.reduce<Record<string, TData[TKey]>>((acc, curr) => {
    const keyValue = curr[key];
    if (keyValue !== undefined && keyValue !== null) {
      acc[String(keyValue)] = keyValue;
    }
    return acc;
  }, {});
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const updatePageTitle = (title: string | Nullish) => {
  if (title) {
    document.title = `${title} | ${DEFAULT_PAGE_TITLE}`;
  }
};

export const getObjectKeys = <O extends object>(object: O) => {
  return Object.keys(object) as Array<keyof O>;
};

/**
 * Checking is it number.
 *
 * @param number | string
 * @returns {boolean}
 */
export const looksLikeNumber = (
  number: string | string[] | boolean | number | null | undefined
): boolean => {
  return !isNaN(parseInt(String(number), 10));
};

/**
 * Parses the boolean value to binary
 *
 * @param value The value to be parsed
 * @returns 1 | 0
 */
export function booleanToBinary(value: boolean | undefined): 1 | 0 {
  return value ? 1 : 0;
}

/**
 * Checking if it's a string.
 *
 * @param string | Nullish
 * @returns {string}
 */
export const validateString = (
  param: string | Nullish,
  emptyPlaceholder = "-"
) => {
  return typeof param === "string" ? param : emptyPlaceholder;
};

/**
 * Returns color based on the input.
 *
 * @param string
 * @returns {string}
 */
export const getColumnValueColor = (num: string, positive?: string) => {
  const numRegex = /[^0-9.-]+/g;
  return Number(num.replace(numRegex, "")) < 0
    ? UTIL_COLORS.ERROR
    : positive
      ? positive
      : "";
};

export const convertToArray = <T>(value: MaybeArray<T> | Nullish): T[] => {
  if (Array.isArray(value)) {
    return value;
  }
  return isNullish(value) ? [] : [value];
};

export const isNullish = <T>(value: T | Nullish): value is null | undefined =>
  value === null || value === undefined;

export const stripHTMLTagsFromString = (str: string, noBreaks = true) => {
  if (!noBreaks) {
    // avoid issue where textContent removes spaces between tags
    str = str.replaceAll("</", " </");
  }
  const text = new DOMParser().parseFromString(str, "text/html");
  return validateString(text.body.textContent);
};

export const filterArrayFromNullish = <T>(valuesArray: (T | null)[]): T[] => {
  // due to runtime nature of filter, simple array.filter does not
  // infer the right type
  const result: T[] = [];

  valuesArray.forEach((value) => {
    if (!isNullish(value)) {
      result.push(value);
    }
  });

  return result;
};

export const getServiceAttributes = (acc: IAttribute[], curr: IDataService) => {
  if (curr.data_service_details) {
    const currentAttributes = Object.values(curr.data_service_details).reduce<
      IAttribute[]
    >((acc, curr) => acc.concat(Object.values(curr).flat()), []);

    acc.push(...currentAttributes);
    return acc;
  } else if (curr.attributes) {
    acc.push(...curr.attributes);
    return acc;
  }
  return acc;
};

export const getServiceProviderDetailsHelper = (
  serviceId: string,
  services: IDataService[]
) => {
  let serviceKey = -1;
  for (const service of services) {
    for (const [key, value] of Object.entries(
      service.data_service_details || []
    )) {
      for (const dataServices of Object.values(value)) {
        for (const srv of dataServices) {
          if (srv.id === serviceId) {
            serviceKey = Number(key);
            break;
          }
        }
      }
    }
  }

  let provider = null;
  for (const service of services) {
    for (const srv of service.services || []) {
      if (srv.id === serviceKey) {
        provider = srv;
        break;
      }
    }
  }
  return provider;
};

export const getServiceHelper = (
  serviceId: string,
  services: IAttribute[],
  context?: IContextSetValueModel
) => {
  return services.find((attr) => {
    if (
      serviceId === SERVICE_CUSTOM &&
      attr.context?.value &&
      !!context?.value
    ) {
      return (
        (attr.context.value as ICustomAttributeContext).id ===
        (context.value as ICustomAttributeContext).id
      );
    } else {
      return attr.id === serviceId;
    }
  });
};

export const getServiceByName = (
  name: IAttribute["name"],
  services: IAttribute[]
) => {
  return services.find((attr) => attr.name === name);
};

export const getIndividualName = <
  TIndividual extends { first_name?: string | null; last_name?: string | null }
>(
  individual: TIndividual,
  individualIndex: number
) => {
  if (individual.first_name || individual.last_name) {
    return [individual.first_name, individual.last_name]
      .filter(Boolean)
      .join(" ");
  }
  return `${i18n.global.t("COMMON.INDIVIDUAL")} ${individualIndex + 1}`;
};

/*
  Overcome lack of reverse mapping for string enum members
  https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
*/
export const getEnumKeyFromValue = <T extends { [key: string]: string }>(
  enumObject: T,
  value: string
): keyof T | null =>
  Object.keys(enumObject).find((enumKey) => enumObject[enumKey] === value) ||
  null;
