import {
  CalleeType,
  type CommunicationLogType
} from "@/enums/communicationLogs";
import type { IBusiness } from "@/models/applications";
import type {
  TaskLog,
  CallLog,
  CommunicationLogBase,
  ContactTelephones,
  CommunicationLogFilterableData,
  QueryFilter
} from "@/models/communicationLogs";
import type { IOwner } from "@/models/users";
import compact from "lodash/compact";
import router from "@/router";
import type { LocationQuery, LocationQueryValue } from "vue-router";
import omit from "lodash/omit";
import { convertToArray, isNullish } from "@/helpers/common";
/**
 * Checks whether the communication log item is of the type specified.
 * @param {CommunicationLogBase} communicationLog The communication item to check
 * @param {CommunicationLogType} typeToAssert The communication log type to assert
 */
export const logIsOfType = <T extends CommunicationLogType>(
  communicationLog: CommunicationLogBase | undefined,
  typeToAssert: T
): communicationLog is T extends CommunicationLogType.call
  ? CallLog
  : T extends CommunicationLogType.task
    ? TaskLog
    : never => {
  return !!communicationLog && communicationLog.type === typeToAssert;
};

interface GetContactsWithCalleeTypeOptions {
  business?: IBusiness;
  owners?: (IOwner | null)[];
}
export const getContactsWithCalleeType = ({
  business,
  owners
}: GetContactsWithCalleeTypeOptions) => {
  const businessData = business
    ? {
        ...business,
        callee_type: CalleeType.Business
      }
    : null;

  const ownersData = compact(
    owners?.map((owner) => ({
      ...owner,
      callee_type: CalleeType.PersonalInformation
    }))
  );

  return compact([businessData, ...ownersData]) as ContactTelephones[];
};

export const canShowNewLogNotification = (
  eventData: Partial<CommunicationLogFilterableData>,
  query: LocationQuery = router.currentRoute.value.query
) => {
  const queryFilters = Object.keys(query) as (keyof QueryFilter)[];

  if (!queryFilters.length) {
    return true;
  }

  const filterableQueryFilters = omit(query, ["page", "per_page", "sort"]);

  return Object.entries(filterableQueryFilters).every(([key, value]) => {
    const filter = filtersMethodsMap[key as keyof typeof filtersMethodsMap];
    if (!filter) {
      return true;
    }
    const queryFilterValue = convertToArray(value);
    return filter(queryFilterValue, eventData);
  });
};

const createFilterCheck =
  <K extends keyof CommunicationLogFilterableData>(
    key: K,
    filterCallback?: (
      queryValue: LocationQueryValue[],
      eventData: Partial<CommunicationLogFilterableData>
    ) => boolean
  ) =>
  (
    queryValue: LocationQueryValue[],
    eventData: Partial<CommunicationLogFilterableData>
  ) => {
    if (!isNullish(eventData[key])) {
      const value = String(eventData[key]);
      const filter = filterCallback || (() => queryValue.includes(value));
      return filter(queryValue, eventData);
    }
    return true;
  };

const assigneeIdFilter = createFilterCheck("assignee_id");
const applicationIdFilter = createFilterCheck("application_id");
const taskPriorityLevelFilter = createFilterCheck("priority_level");
const taskStatusFilter = createFilterCheck("status");
const taskTypeFilter = createFilterCheck("type");
const taskDueDateFilter = createFilterCheck(
  "due_at",
  (queryValue, eventData) =>
    !!eventData.due_at &&
    new Date(queryValue[0] as string).getTime() >=
      new Date(eventData.due_at).getTime()
);

const filtersMethodsMap = {
  assignee_ids: assigneeIdFilter,
  task_due_date: taskDueDateFilter,
  application_ids: applicationIdFilter,
  task_priority_level: taskPriorityLevelFilter,
  task_statuses: taskStatusFilter,
  type: taskTypeFilter
};
