import { defineStore } from "pinia";
import applicationsApiService from "@/services/modules/applications";
import type { IDataOrchestrationLog } from "@/models/applications";
import type { EXTRA_DEAL_VIEW_REQUESTS } from "@/helpers/constants/deals";

interface UseApplicationsState {
  dataOrchestrationLog: IDataOrchestrationLog | null;
  gettingOrchestrationLogs: boolean;
  canSeeNotes: boolean;
  shouldFetch: Record<(typeof EXTRA_DEAL_VIEW_REQUESTS)[number], boolean>;
}

const getDefaultState = (): UseApplicationsState => ({
  dataOrchestrationLog: null,
  gettingOrchestrationLogs: false,
  canSeeNotes: false,
  shouldFetch: {
    notes: true,
    businessNotes: true,
    offers: true
  }
});

const useApplicationsStore = defineStore("applications", {
  state: () => getDefaultState(),
  getters: {
    activeDataOrchestrationLog: ({ dataOrchestrationLog }) =>
      dataOrchestrationLog,
    shouldFetchNotes: ({ shouldFetch }) => shouldFetch.notes,
    shouldFetchBusinessNotes: ({ shouldFetch }) => shouldFetch.businessNotes,
    shouldFetchOffers: ({ shouldFetch }) => shouldFetch.offers
  },
  actions: {
    async getLatestDataOrchestrationLog({ appId }: { appId: string }) {
      try {
        this.dataOrchestrationLog = null;
        this.gettingOrchestrationLogs = true;
        const logsData: IDataOrchestrationLog[] =
          await applicationsApiService.getDataOrchestrationLogs(appId);

        if (!logsData.length) {
          return;
        }

        const activeLogId = logsData[0].id;

        if (!activeLogId) {
          return;
        }

        const logData = await applicationsApiService.getDataOrchestrationLog(
          appId,
          activeLogId
        );

        this.dataOrchestrationLog = logData;
      } finally {
        this.gettingOrchestrationLogs = false;
      }
    },
    async getDataOrchestrationLog({
      appId,
      logId
    }: {
      appId: string;
      logId: string;
    }) {
      this.dataOrchestrationLog =
        await applicationsApiService.getDataOrchestrationLog(appId, logId);
    },
    updateDataOrchestrationLog(log: IDataOrchestrationLog) {
      this.dataOrchestrationLog = log;
    },
    async updateDataOrchestrationMasterStatus(payload: {
      appId: string;
      logId: string;
      master_status: number;
    }) {
      await applicationsApiService.updateDataOrchestrationMasterStatus(
        payload.appId,
        payload.logId,
        payload.master_status
      );
    },
    updateFetchServiceStatus(
      services: (typeof EXTRA_DEAL_VIEW_REQUESTS)[number][],
      status: boolean
    ) {
      services.forEach((service) => (this.shouldFetch[service] = status));
    }
  }
});

export default useApplicationsStore;
