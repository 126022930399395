import clientsApiService from "@/services/modules/clients";
import type { IIntegration } from "@/models/authentications";
import type {
  IClient,
  IClientEmbedReportData,
  IDnsRecord,
  IDocusignTemplate,
  IEmailCustomization,
  IPasswordExpiryPayload,
  IUpdatePiisPayload,
  IWhitelabel,
  ExperianSubcodes,
  ActiveClientCredentialsValues,
  ClientCredentialsRules,
  IClientsState,
  IMaskedPii,
  CreateClientPayload,
  DomainStatus
} from "@/models/clients";
import type { IUserNotification, IResponseMeta } from "@/models/common";
import type { IRootState } from "@/models/state";
import { produce } from "immer";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IUser } from "@/models/users";
import type {
  IntegrationFunder,
  ServicePayload
} from "@/models/clientCredentials";
import lenderNetworkService from "@/services/modules/lenderNetwork";
import type { LenderNetwork } from "@/models/lenderNetwork";

const getDefaultState = (): IClientsState => {
  return {
    meta: {} as IResponseMeta,
    all: [],
    active: null,
    activeClientIntegration: {} as IIntegration,
    activeClientCredentialsRules: {} as ClientCredentialsRules,
    activeClientCredentialsValues: {} as ActiveClientCredentialsValues,
    activeClientFunderIntegrations: [] as IntegrationFunder[],
    plaidLinkToken: null,
    plaidPublicToken: null,
    emailTemplates: [],
    emailTemplatesForTestMail: [],
    clientsLoading: false,
    dnsRecords: [],
    domainStatus: null,
    embedReportData: {} as IClientEmbedReportData
  };
};

const state = getDefaultState();

const mutations: MutationTree<IClientsState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAll(state, clients: IClient[]) {
    state.all = clients;
  },
  setMetadata(state, meta: IResponseMeta) {
    state.meta = meta;
  },
  setActive(state, client: IClient | null) {
    state.active = client;
  },
  unsetActive(state) {
    state.active = null;
  },
  unsetAllClients(state) {
    state.meta = {} as IResponseMeta;
    state.all = [];
  },
  setUpdatedClient(state, updatedClient: IClient) {
    state.all = produce(state.all, (draft) => {
      const index = draft.findIndex((client) => client.id === updatedClient.id);
      if (index !== -1) {
        draft[index] = { ...draft[index], ...updatedClient };
      }
    });
    if (state.active?.id && state.active.id === updatedClient.id) {
      state.active = { ...state.active, ...updatedClient };
    }
  },
  setActiveClientIntegration(state, integrationData: IIntegration) {
    state.activeClientIntegration = integrationData;
  },
  setClientCredentialsRules(state, clientCredentialsRules) {
    state.activeClientCredentialsRules = clientCredentialsRules;
  },
  setClientCredentialsValues(state, clientCredentialsValues) {
    state.activeClientCredentialsValues = clientCredentialsValues;
  },
  setClientFunderIntegrations(state, clientFunderIntegrations) {
    state.activeClientFunderIntegrations = clientFunderIntegrations;
  },
  setPlaidLinkToken(state, plaidLinkToken: string) {
    state.plaidLinkToken = plaidLinkToken;
  },
  setPlaidPublicToken(state, plaidPublicToken: string) {
    state.plaidPublicToken = plaidPublicToken;
  },
  unsetDeletedClient(state, deletedClientId: string) {
    state.all = state.all.filter((client) => client.id !== deletedClientId);
  },
  setUpdatedOrganizationClient(state, updatedUser: IClient) {
    state.all = produce(state.all, (draft) => {
      const index = draft.findIndex((client) => client.id === updatedUser.id);
      draft[index] = { ...draft[index], ...updatedUser };
    });
  },
  setClientNotifications(
    state,
    {
      clientId,
      notifications
    }: { clientId: string; notifications: IUserNotification[] }
  ) {
    if (state.active?.id === clientId) {
      state.active = {
        ...state.active,
        notifications
      };
    }
    state.all = produce(state.all, (draft) => {
      const index = draft.findIndex((client) => client.id === clientId);
      if (index !== -1) {
        draft[index].notifications = notifications;
      }
    });
  },
  setAvailableEmailTemplates(state, templates: IEmailCustomization[]) {
    state.emailTemplates = templates;
  },
  setAvailableEmailTemplatesForTestEmail(state, templateId) {
    const emailTemplates = state.emailTemplates.map((template) => ({
      ...template,
      active: template.id === templateId
    }));
    state.emailTemplatesForTestMail = emailTemplates;
  },
  deleteEmailTemplate(state, templateId: number) {
    state.emailTemplates = state.emailTemplates.filter(
      (template) => template.id !== templateId
    );
  },
  setClientsLoading(state, val: boolean) {
    state.clientsLoading = val;
  },
  setUpdatedDocusignTemplate(state, val: IDocusignTemplate) {
    if (state.active?.id) {
      state.active.docusign_template = val;
    }
  },
  addTemplate(state, template: IEmailCustomization) {
    const templates = state.emailTemplates.filter(
      (oldTemplate) => oldTemplate?.id !== template?.id
    );
    state.emailTemplates = [...templates, template];
  },
  setDnsRecords(state, val: IDnsRecord[]) {
    state.dnsRecords = val;
  },
  unsetDnsRecords(state) {
    state.dnsRecords = [];
  },
  setDomainStatus(state, val: DomainStatus) {
    state.domainStatus = val;
  },
  setUpdatedClientOwner(state, owner: IUser) {
    state.all = produce(state.all, (draft) => {
      const index = draft.findIndex((client) => client.owner?.id === owner.id);
      if (index === -1) {
        return;
      }
      draft[index].owner = {
        ...draft[index].owner,
        ...owner
      };
    });
  },
  setEmbedReportData(state, value: IClientEmbedReportData) {
    state.embedReportData = value;
  },
  setActiveClientRoutingNumber(state, routingNumber: string) {
    const activeClientRoutingNumber = state.active?.piis?.find(
      (pii) => pii.key === "RN"
    );
    if (activeClientRoutingNumber) {
      activeClientRoutingNumber.value = routingNumber;
    }
  },
  setActiveClientBankAccountNumber(state, bankAccountNumber: string) {
    const activeClientBankAccountNumber = state.active?.piis?.find(
      (pii) => pii.key === "AN"
    );
    if (activeClientBankAccountNumber) {
      activeClientBankAccountNumber.value = bankAccountNumber;
    }
  },
  setMaskedPiis(state, piis: IMaskedPii[]) {
    state.active = { ...state.active, ...{ maskedPiis: piis } } as IClient;
  }
};

const actions: ActionTree<IClientsState, IRootState> = {
  async getAll({ commit }, payload: Record<string, unknown>) {
    const data = await clientsApiService.getClients(payload);
    commit("setMetadata", data.meta);
    return commit("setAll", data.data);
  },
  async create({ commit }, payload: CreateClientPayload) {
    const client = await clientsApiService.createClient(payload);
    commit("setActive", client);
    return client;
  },
  async find({ commit }, clientId: string) {
    commit("setClientsLoading", true);
    const data = await clientsApiService.getClient(clientId);
    commit("setClientsLoading", false);
    return commit("setActive", data);
  },
  async refreshActiveClient({ dispatch, state }) {
    if (state.active?.id) {
      await dispatch("find", state.active.id);
    }
  },
  async updateClient(
    { commit },
    payload: { data: Partial<IClient>; id: string; auth?: boolean }
  ) {
    const previousClientData = state.active;
    const id = state.active?.id || payload.id;
    if (!id.length) {
      return;
    }
    try {
      const updatedClient = await clientsApiService.updateClient(
        payload.data,
        id
      );
      commit("setUpdatedClient", updatedClient);
      if (payload.auth) {
        commit("auth/setAuthClientSettings", updatedClient, { root: true });
      }
      return updatedClient;
    } catch (e) {
      commit("setUpdatedClient", previousClientData);
    }
  },
  async updatePiis({ commit, state }, data: IUpdatePiisPayload) {
    if (!state.active?.id) {
      return;
    }
    const updatedClient = await clientsApiService.updateClientPiis(
      state.active.id,
      data
    );
    commit("setUpdatedClient", updatedClient);
  },
  async getPlaidLinkToken({ commit, state }) {
    if (!state.active?.id) {
      return;
    }
    const data = await clientsApiService.getPlaidLinkToken(state.active.id);
    return commit("setPlaidLinkToken", data.plaid_link_token);
  },
  async storePlaidPublicToken({ state }) {
    if (!state.active?.id || !state.plaidPublicToken) {
      return;
    }
    await clientsApiService.storePlaidPublicToken(
      state.active.id,
      state.plaidPublicToken
    );
  },
  async uploadFile(
    { state },
    payload: { data: File; type: string; metadata?: Record<string, string> }
  ) {
    if (!state.active?.id) {
      return;
    }
    await clientsApiService.uploadClientFile(state.active.id, payload);
  },
  async deleteClient({ commit }, { clientId }: { clientId: string }) {
    const { status } = await clientsApiService.deleteClient(clientId);
    if (status === 204) {
      commit("unsetDeletedClient", clientId);
    }
    return status === 204;
  },
  async updateOrganizationClient(
    { commit },
    payload: { data: IClient; id: string }
  ) {
    const updatedClient = await clientsApiService.updateClient(
      payload.data,
      payload.id
    );
    commit("setUpdatedOrganizationClient", updatedClient);
  },
  async updateClientNotifications(
    { commit, rootState },
    {
      clientId,
      data,
      auth = false
    }: {
      clientId: string;
      data: Record<string, number[]>;
      auth: boolean;
    }
  ) {
    const notifications = await clientsApiService.updateClientNotifications(
      clientId,
      data
    );

    if (auth) {
      const currentClient = rootState.auth.authClientSettings || {};
      commit(
        "auth/setAuthClientSettings",
        { ...currentClient, notifications },
        { root: true }
      );
      return;
    }
    commit("setClientNotifications", {
      clientId,
      notifications
    });
  },
  async updateCloseApiKey({ commit, state }, data: { api_key: string }) {
    if (!state.active?.id) {
      return;
    }
    const updatedClient = await clientsApiService.updateCloseApiKey(
      state.active.id,
      data
    );
    commit("setUpdatedClient", updatedClient);
  },
  async setPasswordExpiry({ commit, state }, data: IPasswordExpiryPayload) {
    if (!state.active?.id) {
      return;
    }
    const updatedClient = await clientsApiService.setPasswordExpiry(
      state.active.id,
      data
    );
    commit("setUpdatedClient", updatedClient);
  },
  async listEmailCustomisationTemplates({ commit }, clientId: string) {
    const templates =
      await clientsApiService.listEmailCustomisationTemplates(clientId);
    commit("setAvailableEmailTemplates", templates);
    return templates;
  },
  async deleteEmailTemplate(
    { commit },
    { clientId, templateId }: { clientId: string; templateId: number }
  ) {
    const response = await clientsApiService.deleteEmailCustomisationTemplate(
      clientId,
      templateId
    );
    if (response.status === 204) {
      commit("deleteEmailTemplate", templateId);
    }
  },
  async getTemplateDetails(
    { commit },
    { clientId, templateId }: { clientId: string; templateId: number }
  ) {
    const result = await clientsApiService.getEmailCustomisationDetails(
      clientId,
      templateId
    );
    commit("addTemplate", result);
  },
  async setActiveEmailTemplate(
    _,
    { clientId, templateId }: { clientId: string; templateId: number }
  ) {
    await clientsApiService.setActiveEmailTemplate(clientId, templateId);
  },
  async addNewEmailTemplate(
    _,
    {
      clientId,
      formData,
      files,
      shouldUploadFiles
    }: {
      clientId: string;
      formData: FormData;
      files: FormData;
      shouldUploadFiles: boolean;
    }
  ) {
    const data = await clientsApiService.createEmailCustomisationTemplate(
      clientId,
      formData
    );
    if (shouldUploadFiles && data.id) {
      await clientsApiService.uploadEmailTemplateLogo(clientId, data.id, files);
    }
  },
  async updateEmailTemplate(
    _,
    {
      clientId,
      templateId,
      formData,
      files,
      shouldUploadFiles
    }: {
      clientId: string;
      templateId: number;
      formData: FormData;
      files: FormData;
      shouldUploadFiles: boolean;
    }
  ) {
    await Promise.all([
      clientsApiService.updateEmailCustomisationTemplate(
        clientId,
        templateId,
        formData
      ),
      ...(shouldUploadFiles
        ? [
            clientsApiService.uploadEmailTemplateLogo(
              clientId,
              templateId,
              files
            )
          ]
        : [])
    ]);
  },

  async removeImage(
    _,
    {
      clientId,
      templateId,
      type
    }: { clientId: string; templateId: number; type: string }
  ) {
    await clientsApiService.removeImage(clientId, templateId, type);
  },
  async addWhiteLabel({ state }, data: Partial<IWhitelabel>) {
    if (!state.active?.id) {
      return;
    }
    const createdWhitelabel = await clientsApiService.addWhiteLabel(
      state.active.id,
      data
    );
    state.active.whitelabels?.push(createdWhitelabel);
  },
  async updateWhiteLabel({ state }, data: Partial<IWhitelabel>) {
    if (!state.active?.id || !data.id) {
      return;
    }
    await clientsApiService.updateWhiteLabel(state.active.id, data);
  },
  async deleteDns({ commit, state }, id: string) {
    if (!state.active?.id) {
      return;
    }
    await clientsApiService.deleteDns(state.active.id, id);
    commit("unsetDnsRecords");
  },
  async getDnsRecords({ commit, state }, whitelabelId: string) {
    if (!state.active?.id) {
      return;
    }
    const dnsRecords = await clientsApiService.getDnsRecords(
      state.active.id,
      whitelabelId
    );
    commit("setDnsRecords", dnsRecords);
  },
  async getDomainStatus({ state }, whitelabelId: string) {
    if (!state.active?.id) {
      return;
    }
    const domainStatus = await clientsApiService.getDomainStatus(
      state.active.id,
      whitelabelId
    );
    const index = state.active?.whitelabels?.findIndex(
      (whitelabel) => String(whitelabel.id) === whitelabelId
    );
    if (index === -1 || index === undefined || !state.active?.whitelabels) {
      return;
    }
    state.active.whitelabels[index] = domainStatus;
  },
  async sendTestEmail(
    _,
    {
      clientId,
      emails,
      template_id
    }: { clientId: string; emails: string[]; template_id: number }
  ) {
    await clientsApiService.sendClientTestEmails(clientId, {
      emails,
      template_id
    });
  },
  async storeLinkedFunder({ state, dispatch }, funder_id: number) {
    if (!state.active?.id) {
      return;
    }
    await clientsApiService.storeLinkedFunder(state.active.id, funder_id);
    await dispatch("find", state.active.id);
  },
  async getActiveClientIntegration({ state, commit }, clientId: string) {
    const clientIntegration = await clientsApiService.getClientIntegration(
      clientId || state.active?.id || ""
    );
    commit("setActiveClientIntegration", clientIntegration);
  },
  async updateClientExperianSubcodes(
    _,
    payload: { clientId: string; experianCredentials: ExperianSubcodes }
  ) {
    await clientsApiService.updateClientExperianSubcodes(
      payload.clientId,
      payload.experianCredentials
    );
  },
  async getClientCredentialsRules({ commit }, clientId: string) {
    const clientCredentialsRules =
      await clientsApiService.getClientCredentialsRules(clientId);
    commit("setClientCredentialsRules", clientCredentialsRules);
  },
  async getClientCredentialsValues({ commit }, clientId: string) {
    const clientCredentialsValues =
      await clientsApiService.getClientCredentialsValues(clientId);
    commit("setClientCredentialsValues", clientCredentialsValues);
  },
  async updateClientCredentials(
    _,
    payload: { clientId: string; data: ServicePayload }
  ) {
    await clientsApiService.updateClientCredentials(
      payload.clientId,
      payload.data
    );
    await this.dispatch("clients/getClientCredentialsValues", payload.clientId);
  },
  async getClientFunderIntegrations({ commit }, clientId: string) {
    const clientFunderIntegrations =
      await clientsApiService.getClientFunderIntegrations(clientId);
    commit("setClientFunderIntegrations", clientFunderIntegrations);
  },
  async updateClientFunderIntegration(
    _,
    payload: { clientId: string; funderId: string; data: IntegrationFunder }
  ) {
    await clientsApiService.updateClientFunderIntegration(
      payload.clientId,
      payload.funderId,
      payload.data
    );
    await this.dispatch(
      "clients/getClientFunderIntegrations",
      payload.clientId
    );
  },
  async updateClientTestMode(
    { commit },
    payload: { id: string; test_mode: boolean }
  ) {
    const previousClientData = state.active;
    const id = state.active?.id || payload.id;
    if (!id) {
      return;
    }
    try {
      await clientsApiService.updateClientTestMode(id, payload.test_mode);
      const updatedClient = {
        ...previousClientData,
        in_test_mode: payload.test_mode
      };
      commit("setUpdatedClient", updatedClient);
      return updatedClient;
    } catch (e) {
      commit("setUpdatedClient", previousClientData);
    }
  },
  async fetchPii(
    _,
    payload: {
      clientId: string;
      piiId: number;
    }
  ) {
    return await clientsApiService.getPii(payload.clientId, payload.piiId);
  },
  async getRoutingNumber(
    { commit, dispatch },
    payload: { clientId: string; piiId: number }
  ) {
    const rn = await dispatch("fetchPii", payload);
    commit("setActiveClientRoutingNumber", rn.deobfuscated_value);
  },
  async getBankAccountNumber(
    { commit, dispatch },
    payload: {
      clientId: string;
      piiId: number;
    }
  ) {
    const bn = await dispatch("fetchPii", payload);
    commit("setActiveClientBankAccountNumber", bn.deobfuscated_value);
  },
  async promoteToOwner(
    { commit },
    payload: {
      clientId: string;
      userId: number;
    }
  ) {
    const data = {
      owner_id: payload.userId
    };
    const client = await clientsApiService.updateClient(data, payload.clientId);
    commit("setActive", client);
  },
  async getClientEmbedReportToken(
    { commit },
    { clientId, reportId }: { clientId: string; reportId?: string }
  ) {
    if (!clientId) {
      return;
    }

    const embedReportData = await clientsApiService.getClientEmbedReportToken(
      clientId,
      reportId
    );
    commit("setEmbedReportData", embedReportData);
  },
  async updatePiiMapping({ commit }, payload: { id: string; data: number[] }) {
    const response = await clientsApiService.updateClientPiiMask(
      payload.id,
      payload.data
    );
    commit("setMaskedPiis", response);
  },

  async attachLenders({ commit }, { lenders }: { lenders: IClient["id"][] }) {
    const clientId = state.active?.id;
    if (!clientId) {
      return;
    }

    let lenderNetwork: LenderNetwork;

    const lenderNetworkId = state.active?.lender_network?.id;
    if (lenderNetworkId) {
      lenderNetwork = await lenderNetworkService.update(lenderNetworkId, {
        lenders
      });
    } else {
      lenderNetwork = await lenderNetworkService.create({
        client_id: clientId,
        lenders
      });
    }

    commit("setActive", {
      ...state.active,
      lender_network_enabled: true,
      lender_network: lenderNetwork
    });
  }
};

const getters: GetterTree<IClientsState, IRootState> = {
  active(state) {
    return state.active;
  },
  all(state) {
    return state.all;
  },
  meta(state) {
    return state.meta;
  },
  plaidLinkToken(state) {
    return state.plaidLinkToken;
  },
  plaidPublicToken(state) {
    return state.plaidPublicToken;
  },
  accountNumber(state) {
    return (
      state.active?.piis?.find((element) => element.key == "AN")?.value || ""
    );
  },
  routingNumber(state) {
    return (
      state.active?.piis?.find((element) => element.key == "RN")?.value || ""
    );
  },
  emailTemplates(state) {
    return state.emailTemplates;
  },
  emailTemplatesForTestEmail(state): IEmailCustomization[] {
    return state.emailTemplatesForTestMail;
  },
  clientsLoading(state) {
    return state.clientsLoading;
  },
  whitelabels(state) {
    return state.active?.whitelabels || [];
  },
  domainStatus(state) {
    return state.domainStatus;
  },
  activeClientIntegration(state) {
    return state.activeClientIntegration;
  },
  activeClientCredentialsRules(state) {
    return state.activeClientCredentialsRules;
  },
  activeClientCredentialsValues(state) {
    return state.activeClientCredentialsValues;
  },
  activeClientFunderIntegrations(state) {
    return state.activeClientFunderIntegrations;
  },
  embedReportData(state) {
    return state.embedReportData;
  },
  maskedPiis(state) {
    return state.active?.maskedPiis;
  }
};

export const clients = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
