import dashboardApiService from "@/services/modules/dashboard";
import {
  DASHBOARD_STATISTICS,
  getTrendForTwoPeriods
} from "@/helpers/statistics";
import {
  formatMoney,
  formatNumberWithCommas,
  formatPercentage
} from "@/helpers/formatting";
import type {
  IDashboardState,
  IDashboardStatisticForState,
  IDashboardStatistic,
  IDashboardStatisticResponse
} from "@/models/dashboard";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IRootState } from "@/models/state";

const state: IDashboardState = {
  statistic: null,
  prevStatistic: null
};

const mutations: MutationTree<IDashboardState> = {
  setStatistic(state, statistic: IDashboardStatisticResponse) {
    const sortedStatistic: Record<
      string,
      | (Omit<IDashboardStatistic, "total"> & { total: number | string })
      | { total: number | string; data: null }
    > = Object.keys(DASHBOARD_STATISTICS).reduce((acc, item) => {
      if (typeof statistic[item] === "object") {
        return { ...acc, [item]: statistic[item] };
      } else {
        return { ...acc, [item]: { total: statistic[item], data: null } };
      }
    }, {});

    const getStatisticTotal = (objKey: keyof typeof DASHBOARD_STATISTICS) => {
      if (DASHBOARD_STATISTICS[objKey].money) {
        return formatMoney(sortedStatistic[objKey].total, 2);
      } else if (DASHBOARD_STATISTICS[objKey].percentage) {
        return (
          formatPercentage(parseFloat(`${sortedStatistic[objKey].total}`), 2) ||
          ""
        );
      }
      return formatNumberWithCommas(
        parseFloat(`${sortedStatistic[objKey].total}`)
      );
    };

    state.statistic = Object.keys(sortedStatistic).map((key) => {
      return {
        ...sortedStatistic[key],
        total: getStatisticTotal(key as keyof typeof DASHBOARD_STATISTICS),
        title:
          DASHBOARD_STATISTICS[key as keyof typeof DASHBOARD_STATISTICS].title,
        subtitle:
          DASHBOARD_STATISTICS[key as keyof typeof DASHBOARD_STATISTICS]
            .subtitle,
        trend: getTrendForTwoPeriods(
          statistic[key].total,
          state.prevStatistic?.[key]?.total
        ),
        money:
          DASHBOARD_STATISTICS[key as keyof typeof DASHBOARD_STATISTICS].money
      };
    }) as unknown as IDashboardStatisticForState;
  },
  setPreviousStatistic(state, statistic: IDashboardStatisticResponse) {
    state.prevStatistic = statistic;
  }
};

const actions: ActionTree<IDashboardState, IRootState> = {
  async getStatistic({ commit }, payload: Record<string, unknown>) {
    const data = await dashboardApiService.getStatistic(payload);
    commit("setStatistic", data);
  },
  async getPreviousStatistic({ commit }, payload: Record<string, unknown>) {
    const data = await dashboardApiService.getStatistic(payload);
    commit("setPreviousStatistic", data);
  }
};

const getters: GetterTree<IDashboardState, IRootState> = {
  statistic(state) {
    return state.statistic || [];
  }
};

export const dashboard = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
