import type { IRequiredStipForOffer } from "@/models/applications";
import type { IServicingState } from "@/models/servicing";
import type { IRootState } from "@/models/state";
import externalApiService from "@/services/external";
import type { ActionTree, MutationTree } from "vuex";

const state: IServicingState = {
  offerId: "",
  requiredStips: [],
  uploadedFiles: {}
};

const mutations: MutationTree<IServicingState> = {
  setOfferId(state, offerId: string) {
    state.offerId = offerId;
  },
  setRequiredStips(state, requiredStips: IRequiredStipForOffer[]) {
    state.requiredStips = requiredStips;
  },
  initializeUploadedFiles(state) {
    state.uploadedFiles = Object.values(state.requiredStips).reduce(
      (acc, curr) => ({ ...acc, [curr.id]: [] }),
      {}
    );
  },
  addUploadedFile(state, payload: { section: number; file: File }) {
    const tempFiles = { ...state.uploadedFiles };
    tempFiles[payload.section].push(payload.file);
    state.uploadedFiles = tempFiles;
  },
  removeUploadedFile(state, payload: { section: number; index: number }) {
    const tempFiles = { ...state.uploadedFiles };
    tempFiles[payload.section].splice(payload.index, 1);
    state.uploadedFiles = tempFiles;
  },
  resetUploadedFiles(state) {
    state.uploadedFiles = {};
  }
};

const actions: ActionTree<IServicingState, IRootState> = {
  async getRequiredStips({ state, commit }) {
    const data = await externalApiService.getRequiredStipsForOffer(
      state.offerId
    );
    commit("setRequiredStips", data);
    commit("initializeUploadedFiles");
  },
  async removeFile(
    { commit },
    payload: { index: number; section: number; fileId: number }
  ) {
    await externalApiService.removeUploadedFile({
      ...payload,
      offerId: state.offerId
    });
    commit("removeUploadedFile", payload);
  }
};

export const servicing = {
  namespaced: true,
  state,
  actions,
  mutations
};
