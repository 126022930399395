import useScorecardsStore from "@/stores/scorecards";
import type {
  ScoreCardBacktestResultsFinishedEvent,
  ScoreCardGroupBacktestResultsFinishedEvent
} from "@/helpers/websockets/scorecards/events";

export const handleScoreCardBacktestResultsFinishedEvent = ({
  scoreCardId,
  resultIds
}: ScoreCardBacktestResultsFinishedEvent) => {
  const store = useScorecardsStore();
  store.respondToBacktestResultFinishedEvent(scoreCardId, resultIds);
};
export const handleScoreCardGroupBacktestResultsFinishedEvent = ({
  scoreCardGroupId,
  resultIds
}: ScoreCardGroupBacktestResultsFinishedEvent) => {
  const store = useScorecardsStore();
  store.respondToGroupBacktestResultFinishedEvent(scoreCardGroupId, resultIds);
};
