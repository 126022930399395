import { formatString } from "@/helpers/formatting";
import type { IStip } from "@/models/applications";
import type { IPaginatedResponse } from "@/models/common";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

class StipsApiService {
  async getStips(params: Record<string, unknown> = {}) {
    const response = await axiosClient.get<IPaginatedResponse<IStip>>(
      endpoints.STIPS.ALL,
      {
        params
      }
    );
    return response.data;
  }

  async createStip(stip: Partial<IStip>) {
    const response = await axiosClient.post<{ data: IStip }>(
      endpoints.STIPS.ALL,
      stip
    );
    return response.data.data;
  }

  async updateStip(stipId: number, payload: Partial<IStip>) {
    const response = await axiosClient.patch<{ data: IStip }>(
      formatString(endpoints.STIPS.SINGLE, stipId),
      payload
    );
    return response.data.data;
  }

  async deleteStip(stipId: number) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.STIPS.SINGLE, stipId)
    );
    return response;
  }
}

const stipsApiService = new StipsApiService();
export default stipsApiService;
