export enum Entity {
  business = "business",
  individual = "individual"
}

export enum Relation {
  company = "company",
  creditor = "creditor",
  customer = "customer",
  customerOther = "customer_other",
  debtor = "debtor",
  manufacturer = "manufacturer",
  owner = "owner",
  parent = "parent",
  subsidiary = "subsidiary",
  supplier = "supplier",
  vendor = "vendor",
  vendorOther = "vendor_other"
}
