export enum FullStoryEvent {
  FS_LOAD_WORKFLOW_EVENT = "workflow_loaded",
  FS_VIEW_STAGE_EVENT = "view_stage",
  FS_TIME_AGO_TO_FULL_TIME_EVENT = "time_ago_to_full_time"
}

export enum AmplitudeEvent {
  DealStageViewed = "Deal Stage Viewed",
  DealStageChanged = "Deal Stage Changed",
  DataOrchestrationRun = "Data Orchestration Run",
  DealKilled = "Deal Killed"
}
