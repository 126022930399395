import {
  handleScoreCardBacktestResultsFinishedEvent,
  handleScoreCardGroupBacktestResultsFinishedEvent
} from "@/helpers/websockets/scorecards/handlers";
export interface ScoreCardGroupBacktestResultsFinishedEvent {
  scoreCardGroupId: string;
  resultIds: number[];
}

export interface ScoreCardBacktestResultsFinishedEvent {
  scoreCardId: string;
  resultIds: number[];
}

export const SCORECARD_GROUP_BACKTEST_RESULTS_FINISHED_EVENT =
  "ScoreCardGroupBacktestResultsFinishedEvent" as const;
export const SCORECARD_BACKTEST_RESULTS_FINISHED_EVENT =
  "ScoreCardBacktestResultsFinishedEvent" as const;

export const SCORECARDS_EVENT_HANDLERS = {
  [SCORECARD_BACKTEST_RESULTS_FINISHED_EVENT]:
    handleScoreCardBacktestResultsFinishedEvent,
  [SCORECARD_GROUP_BACKTEST_RESULTS_FINISHED_EVENT]:
    handleScoreCardGroupBacktestResultsFinishedEvent
};
