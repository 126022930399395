import type { IDashboardStatisticResponse } from "@/models/dashboard";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

class DashboardApiService {
  async getStatistic(params: Record<string, unknown>) {
    const response = await axiosClient.get<{
      data: IDashboardStatisticResponse;
    }>(endpoints.STATISTIC, {
      params
    });
    return response.data.data;
  }
}

const dashboardApiService = new DashboardApiService();
export default dashboardApiService;
