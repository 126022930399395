import { formatString } from "@/helpers/formatting";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

import type {
  IUserNotification,
  IPaginatedResponse,
  IFile
} from "@/models/common";
import type { Role } from "@/models/options";
import type { IUser, UserEmailAddresses } from "@/models/users";
import type { IClient } from "@/models/clients";
import type { EmailRecipient } from "@/models/emails";

class UsersApiService {
  async createUser(data: Partial<IUser>) {
    const response = await axiosClient.post<{ data: IUser }>(
      endpoints.USERS.ALL,
      data
    );
    return response.data.data;
  }

  async updateUser(data: Partial<IUser>, userId: number) {
    const response = await axiosClient.patch<{ data: IUser }>(
      formatString(endpoints.USERS.SINGLE, userId),
      data
    );
    return response.data.data;
  }

  async deleteUser(userId: number) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.USERS.SINGLE, userId)
    );
    return response;
  }

  async getClientUsers(clientId: string, params: Record<string, unknown>) {
    const response = await axiosClient.get<IPaginatedResponse<IUser>>(
      formatString(endpoints.CLIENTS.CLIENT_USERS, clientId),
      { params }
    );
    return response.data;
  }

  async getUsers(ids: URLSearchParams) {
    const response = await axiosClient.get<{ data: IUser[] }>(
      endpoints.USERS.ALL,
      {
        params: ids
      }
    );
    return response.data.data;
  }

  async getEmployees(params: Record<string, unknown>) {
    const response = await axiosClient.get<IPaginatedResponse<IUser>>(
      endpoints.USERS.EMPLOYEES,
      {
        params
      }
    );
    return response.data;
  }

  async getClientSalesRepresentatives(
    clientId: string,
    params: Record<string, unknown>
  ) {
    const response = await axiosClient.get<IPaginatedResponse<IUser>>(
      formatString(endpoints.CLIENTS.SALES_REPRESENTATIVES, clientId),
      { params }
    );
    return response.data;
  }

  async createSalesRepresentative(clientId: string, data: Partial<IUser>) {
    const response = await axiosClient.post<{ data: IUser }>(
      formatString(endpoints.CLIENTS.SALES_REPRESENTATIVES, clientId),
      data
    );
    return response.data.data;
  }

  async updateSalesRepresentative(clientId: string, data: IUser) {
    const response = await axiosClient.put<{ data: IUser }>(
      formatString(
        endpoints.CLIENTS.SINGLE_SALES_REPRESENTATIVE,
        clientId,
        data.id
      ),
      data
    );
    return response.data.data;
  }

  async deleteSalesRepresentative(clientId: string, representativeId: number) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(
        endpoints.CLIENTS.SINGLE_SALES_REPRESENTATIVE,
        clientId,
        representativeId
      )
    );
    return response;
  }

  async setUserNotifications(
    userId: number,
    data: { notifications: Record<string, number[]> }
  ) {
    const response = await axiosClient.put<{ data: IUserNotification[] }>(
      formatString(endpoints.USERS.NOTIFICATIONS, userId),
      data
    );
    return response.data.data;
  }

  async getUsersByRole(params: {
    role: Role;
    client_id?: IClient["id"];
    ignore_assigned_deals?: 1 | 0;
  }) {
    const response = await axiosClient.get<{ data: IUser[] }>(
      endpoints.USERS.FILTER_BY_ROLE,
      { params }
    );
    return response.data.data;
  }

  async resetRingCentral(userId: number) {
    const response = await axiosClient.put<{ auth_link?: string }>(
      formatString(endpoints.USERS.RESET_RING_CENTRAL, userId)
    );
    const authLink = response?.data?.auth_link;
    if (authLink) {
      window.open(authLink, "_blank");
    }
  }

  async getUsersEmailAddresses(userId: number) {
    const response = await axiosClient.get<UserEmailAddresses>(
      formatString(endpoints.USERS.EMAIL_ADDRESSES.ALL, userId)
    );
    return response.data;
  }

  async updateUsersEmailAddresses(userId: number, data: UserEmailAddresses) {
    const response = await axiosClient.put<UserEmailAddresses>(
      formatString(endpoints.USERS.EMAIL_ADDRESSES.ALL, userId),
      data
    );
    return response.data;
  }

  async storeUserEmailSignatureImage(userId: number, data: FormData) {
    const response = await axiosClient.post<{ data: IFile }>(
      formatString(endpoints.USERS.EMAIL_ADDRESSES.UPLOAD_IMAGE, userId),
      data
    );
    return response.data.data;
  }

  async getClientEmailRecipients(
    clientId: string,
    params: Record<string, unknown>
  ) {
    const response = await axiosClient.get<IPaginatedResponse<EmailRecipient>>(
      formatString(endpoints.CLIENTS.EMAIL.RECIPIENTS, clientId),
      { params }
    );
    return response.data;
  }
}

const usersApiService = new UsersApiService();
export default usersApiService;
