import DashboardApiService from "@/services/modules/businesses";
import type {
  IBusinessEntityProfile,
  BusinessEntityProfileRecord
} from "@/models/businesses";
import type { IResponseMeta } from "@/models/common";
import type { IRootState } from "@/models/state";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IApplication } from "@/models/applications";
import profilesApiService from "@/services/modules/profiles";
import type { CreateEntityProfilePayload } from "@/models/entityProfiles";
import { Entity } from "@/enums/entityProfiles";

interface BusinessesState {
  businesses: IBusinessEntityProfile[];
  meta: IResponseMeta;
  activeBusiness: IBusinessEntityProfile;
  activeBusinessEntityProfile: IBusinessEntityProfile;
  activeBusinessApplications: IApplication[];
  activeBusinessApplicationsMeta: IResponseMeta;
  lastBusinessApplication: IApplication;
  businessProfiles: BusinessEntityProfileRecord[];
  businessProfilesMeta: IResponseMeta;
}

const getDefaultState = (): BusinessesState => ({
  businesses: [] as IBusinessEntityProfile[],
  meta: {} as IResponseMeta,
  activeBusiness: {} as IBusinessEntityProfile,
  activeBusinessEntityProfile: {} as IBusinessEntityProfile,
  activeBusinessApplications: [] as IApplication[],
  activeBusinessApplicationsMeta: {} as IResponseMeta,
  lastBusinessApplication: {} as IApplication,
  businessProfiles: [] as BusinessEntityProfileRecord[],
  businessProfilesMeta: {} as IResponseMeta
});

const state = getDefaultState();

const mutations: MutationTree<BusinessesState> = {
  addBusiness(state, data: IBusinessEntityProfile) {
    state.businesses.push(data);
  },
  setAll(state, data: IBusinessEntityProfile[]): void {
    state.businesses = data;
  },
  setMetadata(state, meta: IResponseMeta): void {
    state.meta = meta;
  },
  setActiveBusiness(state, data: IBusinessEntityProfile): void {
    state.activeBusiness = data;
  },
  setActiveBusinessEntityProfile(state, data: IBusinessEntityProfile): void {
    state.activeBusinessEntityProfile = data;
  },
  setActiveBusinessApplications(state, data: IApplication[]): void {
    state.activeBusinessApplications = data;
  },
  setActiveBusinessApplicationsMetadata(state, meta: IResponseMeta): void {
    state.activeBusinessApplicationsMeta = meta;
  },
  setLastBusinessApplication(state, data: IApplication): void {
    state.lastBusinessApplication = data;
  },
  setBusinessProfiles(state, data: BusinessEntityProfileRecord[]) {
    state.businessProfiles = data;
  },
  setBusinessProfilesMeta(state, data: IResponseMeta) {
    state.businessProfilesMeta = data;
  },
  setClientTrackingToken(state, token: string) {
    state.activeBusinessEntityProfile.client_tracking_token = token;
  }
};

const actions: ActionTree<BusinessesState, IRootState> = {
  async all({ commit }, params: Record<string, unknown>) {
    const data = await DashboardApiService.getBusinesses(params);

    data.data.forEach((business) => {
      if (business.funding_advisor) {
        business.funding_advisor.full_name = `${business.funding_advisor.first_name} ${business.funding_advisor.last_name}`;
      }
    });

    commit("setAll", data.data);
    commit("setMetadata", data.meta);
  },
  async businessProfile({ commit }, businessId: number) {
    const data = await DashboardApiService.getSingleBusiness(businessId);
    commit("setActiveBusiness", data);
  },

  async loadBusinessProfiles(
    { commit },
    params: Record<string, unknown>
  ): Promise<void> {
    const response = await profilesApiService.getProfilesByType(
      Entity.business,
      params
    );

    commit("setBusinessProfiles", response.data);
    commit("setBusinessProfilesMeta", response.meta);
  },
  async loadBusinessProfile({ commit }, profileId: string) {
    const response = await profilesApiService.getSingleProfileByType(
      Entity.business,
      profileId
    );
    commit("setActiveBusinessEntityProfile", response);
    return response;
  },
  async activeBusinessApplications(
    { commit },
    payload: { businessId: number; params: Record<string, unknown> }
  ) {
    const data = await DashboardApiService.getBusinessApplications(
      payload.businessId,
      payload.params
    );
    commit("setActiveBusinessApplications", data.data);
    commit("setActiveBusinessApplicationsMetadata", data.meta);
  },
  async lastBusinessApplication({ commit }, businessId: number) {
    const data =
      await DashboardApiService.getLastBusinessApplication(businessId);

    commit("applications/setActive", data, { root: true });
    commit("setLastBusinessApplication", data);
  },
  async createNewApplication(_, payload: IApplication) {
    const { id, ...newAppData } = payload;
    return await DashboardApiService.createNewApplication(id, newAppData);
  },
  async updateClientTrackingToken(
    { commit },
    payload: { profileId: string; client_token: string }
  ) {
    const { client_tracking_token } =
      await profilesApiService.updateProfileByType(
        Entity.business,
        payload.profileId,
        { client_tracking_token: payload.client_token }
      );

    commit("setClientTrackingToken", client_tracking_token);
  },
  async updateBusinessEntityProfile(
    { commit },
    {
      profileId,
      params
    }: {
      profileId: string;
      params: Record<string, unknown>;
    }
  ) {
    const response = await profilesApiService.updateBusinessEntityProfile(
      profileId,
      params
    );

    commit("setActiveBusinessEntityProfile", response.data);
    return response.data;
  },
  async createEntityProfile({ commit }, payload: CreateEntityProfilePayload) {
    const response = await profilesApiService.createEntityProfile(payload);
    if (payload.type === Entity.business) {
      commit("addBusiness", response.data);
    }
    if (payload.type === Entity.individual) {
      commit("profiles/addIndividualProfile", response.data, { root: true });
    }
    return response.data;
  }
};

const getters: GetterTree<BusinessesState, IRootState> = {
  all(state): IBusinessEntityProfile[] {
    return state.businesses;
  },
  meta(state): IResponseMeta {
    return state.meta;
  },
  activeBusiness(state): IBusinessEntityProfile {
    return state.activeBusiness;
  },
  activeBusinessEntityProfile(state): IBusinessEntityProfile {
    return state.activeBusinessEntityProfile;
  },
  activeBusinessApplications(state): IApplication[] {
    return state.activeBusinessApplications;
  },
  activeBusinessApplicationsMeta(state): IResponseMeta {
    return state.activeBusinessApplicationsMeta;
  },
  lastBusinessApplication(state): IApplication {
    return state.lastBusinessApplication;
  },
  businessProfiles(state): BusinessEntityProfileRecord[] {
    return state.businessProfiles;
  },
  businessProfilesMeta(state): IResponseMeta {
    return state.businessProfilesMeta;
  }
};

export const businesses = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
