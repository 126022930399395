export enum ScorecardType {
  CARD = "card",
  GROUP = "group"
}

export enum SortOption {
  CREATED_AT = "created_at",
  LATEST_RUN_FIRST = "-latest_run",
  LATEST_RUN_LAST = "latest_run"
}
