import { formatString } from "@/helpers/formatting";
import type { IClient } from "@/models/clients";
import type {
  IAbility,
  CreateOrResetPayload,
  CreateOrResetResponse,
  ILoginPayload,
  ILoginResponse,
  SsoAuthDetails
} from "@/models/authentications";
import type { IUser } from "@/models/users";
import { axiosClient } from "../client";
import endpoints from "../endpoints";

class AuthenticationApiService {
  async login(data: ILoginPayload) {
    const response = await axiosClient.post<{ data: ILoginResponse }>(
      endpoints.LOGIN,
      data
    );
    return response.data.data;
  }

  async register(data: Partial<IClient & { company_website: string }>) {
    const response = await axiosClient.post<{ data: IClient }>(
      endpoints.REGISTER,
      data
    );
    return response.data.data;
  }

  async refreshToken() {
    const response = await axiosClient.post<{ data: ILoginResponse }>(
      endpoints.REFRESH_TOKEN,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken") || ""}`
        }
      }
    );
    return response.data.data;
  }

  async sendResetLink(data: { email: string }) {
    const response = await axiosClient.post<{ data: CreateOrResetResponse }>(
      endpoints.SEND_RESET_LINK,
      data
    );
    return response.data.data;
  }

  async createOrResetPassword(data: {
    password: string;
    password_confirmation: string;
  }) {
    const response = await axiosClient.post<{ data: CreateOrResetResponse }>(
      endpoints.RESET_PASSWORD,
      data
    );
    return response.data.data;
  }

  async renewPassword(data: CreateOrResetPayload) {
    const adaptedData = {
      email: data.email,
      old_password: data.old_password,
      new_password: data.password,
      new_password_confirmation: data.password_confirmation
    };
    await axiosClient.put(endpoints.RENEW_PASSWORD, adaptedData);
    //return true if request processed with no errors (successful reponse 204 No Content)
    return true;
  }

  async getAuthDetails() {
    const response = await axiosClient.get<{ data: IUser }>(
      endpoints.AUTH_DETAILS
    );
    return response.data.data;
  }

  async getImpersonatedToken(userId: number) {
    const response = await axiosClient.post<{ data: ILoginResponse }>(
      formatString(endpoints.IMPERSONATE_USER, userId)
    );
    return response.data.data;
  }

  async getAbilityPermission(abilities: IAbility[]) {
    const response = await axiosClient.post(endpoints.AUTH.ABILITY, {
      abilities
    });
    return response.data.data as IAbility[];
  }

  async getSsoAuthDetails(email: string) {
    const response = await axiosClient.get<{ data: SsoAuthDetails }>(
      endpoints.AUTH.SETTINGS,
      { params: { email } }
    );
    return response.data;
  }
}

const authApiService = new AuthenticationApiService();
export default authApiService;
