import type { ActionTree, GetterTree, MutationTree } from "vuex";

import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import i18n from "@/i18n";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

import type {
  IWorkflow,
  IWorkflowData,
  IWorkflowTab,
  IWorkflowTemplatesState,
  IWorkflowStage,
  IWorkflowBlock,
  IWorkflowField,
  IDiscoveryWorkflowBlock,
  IDiscoveryQuestion,
  FinProductRecord,
  IStipsWorkflowBlock,
  WorkflowBlocksData,
  ApplicationStage,
  IWorkflowStatus,
  IWorkflowStageOption,
  WorkflowBlockIds,
  FinProduct,
  WorkflowsViewFilters,
  BorrowerPlatformStage,
  BorrowerPlatformDefinition,
  BorrowerPlatformState
} from "@/models/workflows";
import type { IRootState } from "@/models/state";
import type { CustomAttribute } from "@/models/customAttributes/customAttribute";
import apiService from "@/services/modules/workflows";
import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta,
  HTTPError
} from "@/models/common";
import { Entity } from "@/enums/entityProfiles";

import {
  defaultWfbDeadStatuses,
  defaultWfbStatuses,
  STAGE_CATEGORY_TYPES,
  WORKFLOW_BLOCKS_IDS,
  WORKFLOW_STAGE_TYPES
} from "@/helpers/constants/workflow";
import type { IDiscoveryCallAnswer } from "@/models/applications";
import { formatWFUpdateError } from "@/helpers/formatting";
import { WORKFLOW_STAGES } from "@/helpers/constants";
import { getObjectKeys } from "@/helpers/common";
import type { IScorecardGroup } from "@/models/scorecards";
import store from "@/store";

const state: IWorkflowTemplatesState = {
  templatesState: {
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks,
    data: [] as IWorkflow[],
    loading: false
  } as IWorkflowData,
  activeTemplate: {} as IWorkflow,
  activeStage: {} as IWorkflowStage,
  activeTab: {} as IWorkflowTab,
  activeEntityType: Entity.business,
  templateFields: {} as WorkflowBlocksData,
  activeOwner: null,
  showStatusesSidebar: false,
  newStageIds: [],
  selectedBorrowerPlatformStageId: "",
  selectedBorrowerPlatformState: null
};

const mutations: MutationTree<IWorkflowTemplatesState> = {
  setAllTemplates(state, data: IPaginatedResponse<IWorkflow>) {
    state.templatesState = {
      ...state.templatesState,
      ...data
    };
  },
  setLoading(state, val: boolean) {
    state.templatesState.loading = val;
  },
  setUpdatedTemplate(state, updatedTemplate: IWorkflow) {
    state.templatesState.data = produce(state.templatesState.data, (draft) => {
      const index = draft.findIndex(
        (template) => template.id === updatedTemplate.id
      );
      if (index !== -1) {
        draft[index] = { ...draft[index], ...updatedTemplate };
      }
    });
    if (state.activeTemplate?.id === updatedTemplate.id) {
      state.activeTemplate = updatedTemplate;
    }
  },
  removeDeletedTemplate(state, deletedTemplateId: string) {
    state.templatesState.data = state.templatesState.data.filter(
      (template) => template.id !== deletedTemplateId
    );
    state.templatesState.meta.total -= 1;
  },
  addReplicatedTemplate(state, replicatedTemplate: IWorkflow) {
    state.templatesState.data.unshift(replicatedTemplate);
    state.templatesState.meta.total += 1;
  },
  setMetadata(state, meta: IResponseMeta) {
    state.templatesState.meta = meta;
  },
  updatePlacementProductState(
    state,
    { enabled, product }: { enabled: boolean; product: FinProduct }
  ) {
    const updatedProduct = getActiveStage(state)?.products?.find(
      (prod) => prod.type === product
    );

    if (updatedProduct) {
      updatedProduct.enabled = enabled;
      return;
    }

    const newProduct: FinProductRecord = {
      type: product,
      enabled: enabled
    };

    if (getActiveStage(state)?.type === WORKFLOW_STAGES.PLACEMENT) {
      newProduct.funders = [];
    }

    getActiveStage(state)?.products?.push(newProduct);
  },
  updateOfferProductStips(
    state,
    { stipIds, product }: { stipIds: number[]; product: FinProduct }
  ) {
    const updatedProduct = getActiveStage(state)?.products?.find(
      (prod) => prod.type === product
    );

    if (updatedProduct) {
      updatedProduct.stips = stipIds;
    }
  },
  deleteOfferProductStip(
    state,
    { stipId, product }: { stipId: number; product: FinProduct }
  ) {
    const updatedProduct = getActiveStage(state)?.products?.find(
      (prod) => prod.type === product
    );

    if (updatedProduct?.stips) {
      updatedProduct.stips = updatedProduct.stips?.filter(
        (stip) => stip !== stipId
      );
    }
  },
  updatePlacementProductFunders(
    state,
    { funders, product }: { funders: string[]; product: string }
  ) {
    // The 'activeStageProduct' and 'activeTemplateProduct' are basically the same thing
    // The difference is that some components have probably used activeStage, some have used activeTemplate
    // When a template is being saved the activeTemplate is being taken as a SOT and sent to the backend
    // Here we are just synchronizing the template same as we are syncing the activeStage
    const activeStageProduct = getActiveStage(state)?.products?.find(
      (prod) => prod.type === product
    );
    const activeTemplateProduct = state.activeTemplate.content?.stages
      .find(({ type }) => type === "placement")
      ?.products?.find(({ type }) => type === product);

    if (activeStageProduct) {
      activeStageProduct.funders = funders;
    }
    if (activeTemplateProduct) {
      activeTemplateProduct.funders = funders;
    }
  },
  setActiveTemplate(
    state,
    {
      templateData,
      workflowStagesInfo = null
    }: {
      templateData: IWorkflow;
      workflowStagesInfo: IWorkflowStageOption[] | null;
    }
  ) {
    state.activeTemplate = templateData;

    if (workflowStagesInfo === null) {
      return;
    }

    if (!state.activeTemplate?.content?.stages?.length) {
      // set default stage with default statuses
      const defaultApplicationStatuses =
        workflowStagesInfo?.find(
          ({ type }) => WORKFLOW_STAGES.APPLICATION === type
        )?.statuses || defaultWfbStatuses;

      const newTemplate: IWorkflow = {
        ...state.activeTemplate,
        content: {
          borrower_platform: {
            is_enabled: false,
            allows_acceptance_of_multiple_offers: true,
            mask_funder_details: false,
            offer_declined_reasons: [],
            offer_declined_reasons_other_label: null,
            stages: []
          },
          documents: { sync: { down: { enabled: false } } },
          stages: [
            {
              id: uuidv4(),
              type: "application",
              name: "Application",
              management: {
                lendflow: true,
                client: false
              },
              statuses: defaultApplicationStatuses,
              tabs: [
                {
                  blocks: [],
                  name: "Tab1"
                }
              ]
            }
          ],
          webhooks: [],
          flow: [],
          fast_track: {
            transitions: []
          },
          emails: [],
          sms_notifications: [],
          email_submission: {
            is_enabled: false,
            template_id: null,
            branding_id: null,
            whitelabel_id: null
          },
          activity_hub: { active_tabs: [] }
        }
      };
      initBorrowerPlatformCustomization(newTemplate);
      state.activeTemplate = newTemplate;
    } else {
      state.activeTemplate.content.stages.forEach((stage) => {
        // set default dead statuses if there is no at least one dead status
        if (stage.statuses.find((status) => !status.live)) {
          return;
        }

        const liveStatuses = stage.statuses.filter((status) => !!status.live);

        const defaultStatuses = workflowStagesInfo?.find(
          ({ type }) => stage.type === type
        );

        const defaultDeadStatuses =
          defaultStatuses?.statuses.filter((status) => !status.live) ||
          defaultWfbDeadStatuses;

        stage.statuses = [...defaultDeadStatuses, ...liveStatuses];
      });
    }
  },
  updateStages(state, stages: IWorkflowStage[]) {
    if (state.activeTemplate?.content?.stages) {
      state.activeTemplate.content.stages = stages;
    }
  },
  setActiveStage(state, stage: IWorkflowStage) {
    state.activeStage = stage;
    if (stage?.tabs?.length) {
      state.activeTab = stage.tabs[0];
      return;
    }
    state.activeTab = {} as IWorkflowTab;
  },
  setTemplatesInactive(state, templates: string[]) {
    if (!templates.length) {
      return;
    }
    state.templatesState.data.forEach((template) => {
      if (template.id && templates.includes(template.id)) {
        template.is_active = false;
      }
    });
  },
  addStage(
    state,
    { stage, insertionIndex }: { stage: IWorkflowStage; insertionIndex: number }
  ) {
    state.activeTemplate?.content?.stages.splice(insertionIndex, 0, stage);
  },
  replaceStage(
    state,
    { stage, insertionIndex }: { stage: IWorkflowStage; insertionIndex: number }
  ) {
    if (!state.activeTemplate?.content?.stages) {
      return;
    }

    /** if client changes type of the stage we should delete tabs and blocks */
    if (
      stage.type !== state.activeTemplate.content?.stages[insertionIndex].type
    ) {
      stage.tabs = [];
      state.activeTab = {} as IWorkflowTab;
    }

    const updatedStages = [...state.activeTemplate.content.stages];
    updatedStages[insertionIndex] = stage;

    /**
     * Check if we are replacing active stage.
     * If we do then we need to update "activeStage".
     */
    if (
      state.activeTemplate.content?.stages[insertionIndex] === state.activeStage
    ) {
      state.activeStage = stage;
    }
    state.activeTemplate.content.stages = updatedStages;
  },
  setServicers(state, servicers) {
    const activeBlockLoanServicers = getActiveTab(state)?.blocks.find(
      (block) => block.id === "loan_servicing"
    );

    if (activeBlockLoanServicers) {
      activeBlockLoanServicers.servicers = servicers;
    }
  },
  deleteStage(state, deletionIndex: number) {
    if (!state.activeTemplate?.content?.stages) {
      return;
    }
    const { content } = state.activeTemplate;

    const stageToDelete = content.stages[deletionIndex];
    const fastTrackTransitionTo = content.fast_track.transitions?.[0]?.to || "";
    const fastTrackWidgetAutomations = content.fast_track.widget;

    state.activeTemplate.content = produce(
      state.activeTemplate.content,
      (draft) => {
        draft.webhooks = draft.webhooks.filter(
          ({ stage_id }) => stage_id !== stageToDelete.id
        );

        draft.stages = draft.stages.filter(
          (_, index) => index !== deletionIndex
        );

        /*
         * If any of the Fast Track automations are in place and the stage
         * they are pointing to is being deleted, we need to remove that automation
         */
        const { id, type } = stageToDelete;
        if (type !== "placement") {
          return;
        }

        draft.emails = draft.emails.filter(({ stage }) => stage !== type);

        if (id === fastTrackTransitionTo) {
          draft.fast_track.transitions = [];
        }

        if (!fastTrackWidgetAutomations) {
          return;
        }

        getObjectKeys(fastTrackWidgetAutomations).forEach((key) => {
          const to = fastTrackWidgetAutomations[key]?.to;
          if (to === id) {
            delete draft.fast_track.widget?.[key];
          }
        });
      }
    );
  },
  setActiveTab(state, tab: IWorkflowTab) {
    state.activeTab = tab;
  },
  addDiscoveryQuestion(
    _,
    payload: {
      discoveryBlock: IDiscoveryWorkflowBlock;
      newQuestion?: IDiscoveryQuestion;
    }
  ) {
    if (!payload.discoveryBlock) {
      return;
    }
    if (!payload.discoveryBlock.questions) {
      payload.discoveryBlock.questions = [];
    }
    if (payload.newQuestion) {
      payload.discoveryBlock.questions.push(payload.newQuestion);
      return;
    }
    payload.discoveryBlock.questions.push({
      id: uuidv4(),
      question: "",
      description: ""
    });
  },
  addDataOrchestrationTemplate(state, ids: string[]) {
    const stage = getActiveStage(state);
    if (!stage || stage.type !== WORKFLOW_STAGE_TYPES.UNDERWRITING) {
      return;
    }

    stage.data_orchestration_templates = ids;
  },
  setAutomatedDoTemplateId(state, id: string) {
    const stage = getActiveStage(state);
    if (!stage || stage.type !== WORKFLOW_STAGE_TYPES.UNDERWRITING) {
      return;
    }

    stage.data_orchestration_template_automation = id;
  },
  addTab(state, name: string) {
    const stage = getActiveStage(state);
    if (!stage) {
      return;
    }
    const tab: IWorkflowTab = { name, blocks: [] };
    if (stage.type === WORKFLOW_STAGE_TYPES.UNDERWRITING) {
      tab.entity_type = state.activeEntityType;
    }
    if (!stage.tabs) {
      stage.tabs = [];
    }
    stage.tabs.push(tab);
    state.activeTab = tab;
  },
  editTab(state, { name, tabIndex }: { name: string; tabIndex: number }) {
    const stage = getActiveStage(state);
    if (stage?.tabs) {
      stage.tabs[tabIndex].name = name;
    }
  },
  deleteTab(state, tabIndex: number) {
    const stage = getActiveStage(state);
    if (!stage) {
      return;
    }
    stage.tabs = stage.tabs?.filter((_, index) => index !== tabIndex);
  },
  updateTabs(state, tabs: IWorkflowTab[]) {
    const stage = getActiveStage(state);
    if (!stage) {
      return;
    }
    stage.tabs = tabs;
  },
  updateField(
    state,
    payload: {
      field: IWorkflowField;
      blockId: string;
      notify: (localeKey: string, type?: "success" | "error") => void;
    }
  ) {
    const updatingBlock = getActiveTab(state)?.blocks.find(
      (block) => block.id === payload.blockId
    );
    if (!updatingBlock?.fields) {
      return;
    }
    if (payload.field.hidden) {
      const numberOfFields = updatingBlock.fields.length;
      const numberOfHiddenFields =
        updatingBlock.fields.filter(({ hidden }) => hidden).length + 1; // Current number plus the one incoming
      if (numberOfFields === numberOfHiddenFields) {
        return payload.notify("WORKFLOWS.WORKFLOW_BUILDER.MIN_FIELD", "error");
      }
    }
    const fieldIndex = updatingBlock?.fields.findIndex(
      (field) => field.id === payload.field.id
    );
    if (fieldIndex !== undefined && updatingBlock) {
      updatingBlock.fields[fieldIndex] = payload.field;
    }
  },
  updateStips(
    _,
    payload: { stipsBlock: IStipsWorkflowBlock; stips: Array<{ id: number }> }
  ) {
    payload.stipsBlock.stips = payload.stips;
  },
  deleteQuestion(
    _,
    payload: { discoveryBlock: IDiscoveryWorkflowBlock; questionId: string }
  ) {
    payload.discoveryBlock.questions = payload.discoveryBlock.questions.filter(
      (question: IDiscoveryQuestion) => question.id !== payload.questionId
    );
  },
  addTabBlock(
    state,
    { index, block }: { index: number | null; block: IWorkflowBlock }
  ) {
    const activeTab = getActiveTab(state);
    if (!activeTab) {
      return;
    }
    /**
     * When null or -1 is passed for index parameter that means
     * block is either dropped after last block or as first block
     */
    if (index === null || index < 0) {
      activeTab.blocks.push(block);
    } else {
      activeTab.blocks.splice(index, 0, block);
    }
  },
  setActiveEntityType(state, entityType: Entity) {
    state.activeEntityType = entityType;
  },
  setActiveCustomAttributes(state, ids: CustomAttribute["id"][]) {
    const activeTab = getActiveTab(state);
    const block = activeTab?.blocks.find(
      ({ id }) => id === WORKFLOW_BLOCKS_IDS.custom_attributes
    );
    if (block) {
      block.custom_attributes = ids;
    }
  },
  deleteBlock(state, blockId: string) {
    const activeTab = getActiveTab(state);
    if (!activeTab) {
      return;
    }
    activeTab.blocks = activeTab.blocks.filter((block) => block.id !== blockId);
  },
  updateBlocksOrder(state, blocks: IWorkflowBlock[]) {
    const activeTab = getActiveTab(state);
    if (!activeTab) {
      return;
    }
    activeTab.blocks = blocks;
  },
  updateQuestion(
    _,
    payload: {
      discoveryBlock: IDiscoveryWorkflowBlock;
      discoveryQuestion: IDiscoveryQuestion;
    }
  ) {
    const updatedQuestion = payload.discoveryBlock.questions.find(
      (question) => question.id === payload.discoveryQuestion.id
    );
    if (updatedQuestion) {
      updatedQuestion.question = payload.discoveryQuestion.question;
      updatedQuestion.description = payload.discoveryQuestion.description;
    }
  },
  reorderDiscoveryQuestions(state, discoveryQuestions: IDiscoveryQuestion[]) {
    const activeTab = getActiveTab(state);
    if (!activeTab) {
      return;
    }
    const discoveryBlock = activeTab.blocks.find(
      (block) => block.id === WORKFLOW_BLOCKS_IDS.discovery_questions
    ) as IDiscoveryWorkflowBlock;
    discoveryBlock.questions = discoveryQuestions;
  },
  unsetActiveTemplate(state) {
    state.activeTemplate = {} as IWorkflow;
    state.activeStage = {} as IWorkflowStage;
    state.activeTab = {} as IWorkflowTab;
    state.templateFields = {} as WorkflowBlocksData;
    state.showStatusesSidebar = false;
  },
  setTemplateFields(state, fields: WorkflowBlocksData) {
    state.templateFields = fields;
  },
  setScorecards(state, selectedScorecards) {
    const stage = getActiveStage(state);
    if (!stage) {
      return;
    }
    stage.cards = selectedScorecards;
  },
  setScorecardGroups(state, selectedScorecardGroups: IScorecardGroup["id"][]) {
    const stage = getActiveStage(state);
    if (!stage) {
      return;
    }
    stage.groups = selectedScorecardGroups;
  },
  setStageName(state, stageName: string) {
    state.activeTemplate.stage_name = stageName;
  },
  setStageCategory(state, stageCategory: ApplicationStage) {
    state.activeTemplate.stage_category = STAGE_CATEGORY_TYPES[stageCategory];
  },
  setStageId(state, id: string) {
    state.activeTemplate.stage_id = id;
  },
  setPrimaryScorecard(state, favoriteScorecard) {
    const stageToUpdate = state.activeTemplate.content?.stages?.find(
      (stage) => stage.id === state.activeStage?.id
    );

    if (stageToUpdate) {
      stageToUpdate.primary_card =
        stageToUpdate.primary_card !== favoriteScorecard
          ? favoriteScorecard
          : undefined;

      state.activeStage.primary_card = stageToUpdate.primary_card;
    }
  },
  setPrimaryScorecardGroup(state, favoriteScorecard) {
    const stageToUpdate = state.activeTemplate.content?.stages?.find(
      (stage) => stage.id === state.activeStage?.id
    );

    if (stageToUpdate) {
      stageToUpdate.primary_group =
        stageToUpdate.primary_group !== favoriteScorecard
          ? favoriteScorecard
          : undefined;

      state.activeStage.primary_group = stageToUpdate.primary_group;
    }
  },
  setActiveOwner(state, ownerId) {
    state.activeOwner = ownerId;
  },
  setOffers(state, offers: FinProductRecord[]) {
    const activeStage = getActiveStage(state);
    if (!activeStage) {
      return;
    }
    activeStage.products = offers;
  },
  updateStatuses(
    state,
    payload: { type: string; statuses: IWorkflowStatus[] }
  ) {
    state.activeTemplate.content?.stages?.find((stage) => {
      if (stage.id === state.activeStage?.id) {
        const otherStatuses = state.activeStage.statuses.filter((status) =>
          payload.type === "dead" ? !!status.live : !status.live
        );
        stage.statuses = [...otherStatuses, ...payload.statuses];
      }
    });
  },
  updateAllStatuses(
    state,
    payload: { type: string; statuses: IWorkflowStatus[] }
  ) {
    if (!state.activeTemplate.content) {
      return;
    }

    const otherStatuses: IWorkflowStatus[] =
      state.activeTemplate.content.statuses?.filter((status) =>
        payload.type === "dead" ? !!status.live : !status.live
      ) || [];

    state.activeTemplate.content.statuses = [
      ...otherStatuses,
      ...payload.statuses
    ];
  },
  addStatusToEachStage(
    state,
    {
      status,
      exeptStage = ""
    }: { status: IWorkflowStatus; exeptStage: IWorkflowStage["id"] }
  ) {
    if (!state.activeTemplate.content?.stages) {
      return;
    }

    state.activeTemplate.content.stages =
      state.activeTemplate.content.stages.map((stage) => {
        if (stage.id === exeptStage) {
          return stage;
        }

        return {
          ...stage,
          statuses: [...stage.statuses, status]
        };
      });
  },
  toggleStatusesSidebar(state) {
    state.showStatusesSidebar = !state.showStatusesSidebar;
  },
  setNewStageId(state, id: IWorkflowStage["id"]) {
    state.newStageIds.push(id);
  },
  resetNewStageIds(state) {
    state.newStageIds = [];
  },
  joinStages(
    state,
    payload: { from: BorrowerPlatformStage; to: BorrowerPlatformStage }
  ) {
    if (!state.activeTemplate.content) {
      return;
    }
    const { from, to } = payload;
    const bpStages = state.activeTemplate.content.borrower_platform.stages;
    const insertionIndex = bpStages?.findIndex((stage) => stage.id === from.id);
    const borrowerPlatformDefinition: BorrowerPlatformDefinition | undefined =
      store.getters["options/borrowerPlatformDefinition"];
    const deadStateDefinition = borrowerPlatformDefinition?.states.find(
      (state) => state.type === "dead"
    );

    if (
      !deadStateDefinition ||
      insertionIndex === undefined ||
      insertionIndex === -1
    ) {
      return;
    }

    const states = uniqBy(
      [...from.states, ...to.states].filter((state) => state.type !== "dead"),
      "type"
    );

    /**
     * Pushing a clean Dead state so that we don't choose between merged states
     */
    states.push({
      show_button: true,
      button_text: i18n.global.t("COMMON.SUBMIT"),
      button_url: "",
      button_redirect_type: null,
      description: deadStateDefinition.default_description,
      title: deadStateDefinition.default_title,
      type: deadStateDefinition.type,
      show_all_offers_block: deadStateDefinition.show_all_offers_block
    });

    const newStage: BorrowerPlatformStage = {
      description: "",
      id: uuidv4(),
      name: "",
      states,
      workflow_template_stages: [
        ...from.workflow_template_stages,
        ...to.workflow_template_stages
      ]
    };

    const newStages = bpStages!.filter(
      (stage) => stage.id !== from.id && stage.id !== to.id
    );
    newStages.splice(insertionIndex, 0, newStage);

    state.activeTemplate.content.borrower_platform.stages = newStages;
    state.selectedBorrowerPlatformStageId = newStage.id;
  },
  splitStage(state, payload: { stage: BorrowerPlatformStage }) {
    if (!state.activeTemplate.content) {
      return;
    }
    const { stage } = payload;
    const bpStages = state.activeTemplate.content.borrower_platform.stages;
    const insertionIndex = bpStages?.findIndex((s) => s.id === stage.id);

    if (insertionIndex === undefined || insertionIndex === -1) {
      return;
    }

    const newStages = getBorrowerStagesFromTemplate(
      state.activeTemplate
    ).filter((activeTemplateStage) =>
      activeTemplateStage.workflow_template_stages.some((wfTemplateId) =>
        stage.workflow_template_stages.includes(wfTemplateId)
      )
    );

    state.activeTemplate.content.borrower_platform.stages.splice(
      insertionIndex,
      1,
      ...newStages
    );
    state.selectedBorrowerPlatformStageId = newStages[0].id;
  },
  setSelectedBorrowerPlatformStageId(state, id: string) {
    state.selectedBorrowerPlatformStageId = id;
  },
  resetBorrowerPlatformCustomization() {
    if (!state.activeTemplate.content) {
      return;
    }
    state.activeTemplate.content.borrower_platform = {
      ...state.activeTemplate.content.borrower_platform,
      stages: getBorrowerStagesFromTemplate(state.activeTemplate)
    };
  },
  setSelectedBorrowerPlatformState(
    state,
    stateType: BorrowerPlatformState["type"]
  ) {
    state.selectedBorrowerPlatformState = stateType;
  }
};

const actions: ActionTree<IWorkflowTemplatesState, IRootState> = {
  async getWorkflowTemplates(
    { commit },
    payload: { params: WorkflowsViewFilters } = { params: {} }
  ) {
    commit("setLoading", true);
    try {
      return await apiService.getWorkflowTemplates(payload.params);
    } finally {
      commit("setLoading", false);
    }
  },
  async getAndSetAllTemplates(
    { commit },
    payload: { params: WorkflowsViewFilters } = { params: {} }
  ) {
    commit("setLoading", true);
    const data = await apiService.getWorkflowTemplates(payload.params);
    commit("setAllTemplates", data);
    commit("setLoading", false);
  },
  async createTemplate(
    { commit, rootGetters, state },
    { data }: { data: Partial<IWorkflow> }
  ) {
    const templateData = await apiService.createWorkflowTemplate(data);
    commit("setAllTemplates", [...state.templatesState.data, templateData]);

    const workflowStagesInfo: IWorkflowStageOption[] =
      rootGetters["options/workflowStagesInfo"];

    commit("setActiveTemplate", { templateData, workflowStagesInfo });
    return templateData;
  },
  async updateTemplate(
    { commit },
    {
      templateId,
      data
    }: { templateId: IWorkflow["id"]; data: Partial<IWorkflow> }
  ) {
    const updatedTemplate = await apiService.updateWorkflowTemplate(
      templateId,
      data
    );
    commit("setUpdatedTemplate", updatedTemplate);
    return updatedTemplate;
  },
  async getActiveTemplate(
    { commit, state, rootGetters },
    templateId: IWorkflow["id"]
  ) {
    commit("setLoading", true);
    try {
      const templateData = await apiService.getWorkflowTemplate(templateId);
      initBorrowerPlatformCustomization(templateData);
      const workflowStagesInfo: IWorkflowStageOption[] =
        rootGetters["options/workflowStagesInfo"];

      commit("setActiveTemplate", { templateData, workflowStagesInfo });

      // Set first stage as an initial one if there is any stage within active template
      commit(
        "setActiveStage",
        state.activeTemplate?.content?.stages?.[0] ?? {}
      );

      // Set first tab within first stage as an initial one if there is any tab within active stage
      commit(
        "setActiveTab",
        state.activeTemplate?.content?.stages?.[0].tabs?.[0] ?? {}
      );
      return templateData;
    } finally {
      commit("setLoading", false);
    }
  },
  async toggleTemplateGlobalStatus({ commit }, templateId: IWorkflow["id"]) {
    const updatedTemplate =
      await apiService.toggleWorkflowTemplateGlobalStatus(templateId);
    commit("setUpdatedTemplate", updatedTemplate);
    return updatedTemplate;
  },
  async duplicateWorkflowTemplate({ commit }, templateId: IWorkflow["id"]) {
    const newTemplate = await apiService.duplicateWorkflowTemplate(templateId);
    commit("addReplicatedTemplate", newTemplate);
  },
  async getBlocksData(
    { rootState, commit, state },
    blockIds: IWorkflowBlock["id"][]
  ) {
    const data = await apiService.getFieldsData(
      rootState.applications.active.id,
      blockIds
    );
    commit("setTemplateFields", { ...state.templateFields, ...data });
  },
  async getWorkflowSnapshot(
    { commit, state, rootState },
    id: string = rootState.applications.active.id
  ) {
    commit("setLoading", true);
    try {
      const templateData: IWorkflow = await apiService.getWorkflowSnapshot(id);

      commit("setActiveTemplate", { templateData });

      // Set first stage as an initial one if there is any stage within active template
      commit(
        "setActiveStage",
        state.activeTemplate?.content?.stages?.[0] ?? {}
      );

      // Set first tab within first stage as an initial one if there is any tab within active stage
      commit(
        "setActiveTab",
        state.activeTemplate?.content?.stages?.[0].tabs?.[0] ?? {}
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async updateWorkflowSnapshotFields(
    { rootState, commit, dispatch },
    data: unknown
  ) {
    //Payload example
    //{
    //     "business": {
    //         "business_legal_name": "Bianca's Business10",
    //         "business_dba": "BSB2"
    //     },
    //     "personal": [
    //         {
    //             "personal_information_id": 926,
    //             "personal_first_name": "flow"
    //         },
    //         {
    //             "personal_information_id": null,
    //             "personal_first_name": "lend",
    //             "personal_address": [
    //                 {
    //                     "id": 3,
    //                     "address_line1": "some address"
    //                 },
    //                 {
    //                     "id": null,
    //                     "address_line1": "some address"
    //                 }
    //             ]
    //         }
    //     ]
    // }
    try {
      const responseData = await apiService.updateWorkflowSnapshotFields(
        rootState.applications.active.id,
        data
      );
      commit("setTemplateFields", responseData);
      dispatch("applications/syncActiveToAll", null, { root: true });
    } catch (e) {
      throw formatWFUpdateError(e as HTTPError);
    }
  },
  async deleteWorkflowTemplate({ commit }, templateId: IWorkflow["id"]) {
    const { status } = await apiService.deleteWorkflowTemplate(templateId);
    if (status === 204) {
      commit("removeDeletedTemplate", templateId);
    }
  },
  addDiscoveryQuestion({ commit, getters }) {
    const discoveryBlock = getters.discoveryBlock;
    commit("addDiscoveryQuestion", { discoveryBlock });
  },
  updateQuestion({ commit, getters }, discoveryQuestion: IDiscoveryQuestion) {
    const discoveryBlock = getters.discoveryBlock;
    commit("updateQuestion", { discoveryQuestion, discoveryBlock });
  },
  deleteQuestion({ commit, getters }, questionId: string) {
    const discoveryBlock = getters.discoveryBlock;
    commit("deleteQuestion", { questionId, discoveryBlock });
  },
  updateStips({ commit, getters }, payload: Array<number>) {
    const stipsBlock = getters.stipsBlock;
    commit("updateStips", { stipsBlock, stips: payload });
  },
  async forceUpdateTemplate(
    { dispatch, commit },
    payload: { conflictingTemplates: number[]; templateId: number }
  ) {
    await dispatch("updateTemplate", {
      data: { force_default: true },
      templateId: payload.templateId
    });
    commit("setTemplatesInactive", payload.conflictingTemplates);
  },
  async submitToNextWorkflowPhase(
    { state, commit, rootState, dispatch },
    currentPhaseIndex
  ) {
    if (currentPhaseIndex !== undefined && currentPhaseIndex !== -1) {
      await apiService.sumbitToNextWorkflowPhase(
        rootState.applications.active.id
      );
      dispatch("applications/syncActiveToAll", {}, { root: true });
      if (
        state.activeTemplate.content?.stages &&
        state.activeTemplate.content?.stages.length - 1 > currentPhaseIndex
      ) {
        const stage =
          state.activeTemplate.content.stages[currentPhaseIndex + 1];
        commit("setActiveStage", stage);
        commit("setStageName", stage.name);
        commit("setStageCategory", stage.type);
        commit("setStageId", stage.id);
      }
    }
  },
  deleteStage({ state, commit }, deletionIndex: number) {
    commit("deleteStage", deletionIndex);
    commit("resetBorrowerPlatformCustomization");
    commit(
      "setActiveStage",
      state.activeTemplate.content?.stages[deletionIndex - 1]
    );
  },
  async rollbackWorkflowPhase({ state, commit, rootState }) {
    const newPhaseId = rootState.applications.active.stage_id;
    const newPhaseIndex = state.activeTemplate.content?.flow.findIndex(
      (stage) => {
        return stage.id === newPhaseId;
      }
    );
    if (
      newPhaseIndex !== undefined &&
      newPhaseIndex !== -1 &&
      state.activeTemplate.content?.stages
    ) {
      if (newPhaseIndex < state.activeTemplate.content?.flow.length) {
        await apiService.rollbackWorkflowPhase(
          rootState.applications.active.id
        );
      }
      const stage = state.activeTemplate.content.stages[newPhaseIndex - 1];
      commit("setActiveStage", stage);
      commit("setStageName", stage.name);
      commit("setStageCategory", stage.type);
      commit("setStageId", stage.id);
    }
  },
  async updateDiscoveryCallAnswers(
    { rootState, commit },
    payload: { answers: IDiscoveryCallAnswer[] }
  ) {
    await apiService.updateDiscoveryCallAnswers(
      rootState.applications.active.id,
      payload
    );
    commit("applications/setUpdatedDiscoveryCallAnswers", payload.answers, {
      root: true
    });
  },
  async addDiscoveryCallQuestion(
    { rootState, commit, getters },
    payload: IDiscoveryQuestion
  ) {
    const discoveryBlock = getters.discoveryBlock;
    const updatedPayload = { ...payload, id: uuidv4() };
    await apiService.createDiscoveryCallQuestions(
      rootState.applications.active.id,
      updatedPayload
    );
    commit("addDiscoveryQuestion", {
      discoveryBlock,
      newQuestion: updatedPayload
    });
  },
  async deleteDiscoveryCallQuestion(
    { rootState, commit, getters },
    id: string
  ) {
    await apiService.deleteDiscoveryCallQuestion(
      rootState.applications.active.id,
      { id }
    );
    const discoveryBlock = getters.discoveryBlock;
    commit("deleteQuestion", { discoveryBlock, questionId: id });
  },
  setScorecards({ commit }, selectedScorecards) {
    commit("setScorecards", selectedScorecards);
  },
  setScorecardGroups(
    { commit, rootState, state },
    newSelectedScorecardGroups: IScorecardGroup["id"][]
  ) {
    const stage = getActiveStage(state);
    const currentSelectedScorecards = stage?.cards ?? [];
    const currentSelectedScorecardGroups = stage?.groups ?? [];
    const allScorecardGroups = rootState.scorecards.allGroups?.data ?? [];

    const isGroupAdded =
      newSelectedScorecardGroups.length > currentSelectedScorecardGroups.length;

    let updatedScorecards = currentSelectedScorecards;

    if (isGroupAdded) {
      const scorecardsFromNewGroups = newSelectedScorecardGroups.flatMap(
        (groupId) => {
          const group = allScorecardGroups.find(({ id }) => id === groupId);
          return group?.score_cards?.map(({ id }) => id) ?? [];
        }
      );

      updatedScorecards = uniq([
        ...currentSelectedScorecards,
        ...scorecardsFromNewGroups
      ]);
    }

    commit("setScorecardGroups", newSelectedScorecardGroups);
    commit("setScorecards", updatedScorecards);
  },
  updateField({ commit }, payload) {
    commit("updateField", {
      ...payload,
      notify: (localeKey: string, type: "success" | "error" = "success") => {
        commit(
          "setGlobalMessage",
          { title: i18n.global.t(localeKey), type },
          { root: true }
        );
      }
    });
  }
};

const getters: GetterTree<IWorkflowTemplatesState, IRootState> = {
  activeTemplate(state) {
    return state.activeTemplate;
  },
  activeTemplateStages(state) {
    return state.activeTemplate?.content?.stages || [];
  },
  activeTemplateFlow(state) {
    return state.activeTemplate?.content?.flow || [];
  },
  activeStage(state) {
    return getActiveStage(state);
  },
  activeStageDeadStatuses(state) {
    return state.activeTemplate.content?.stages
      ?.find((stage) => stage.id === state.activeTemplate.stage_id)
      ?.statuses?.filter((status) => !status.live);
  },
  activeTab(state) {
    return getActiveTab(state);
  },
  allTemplates(state) {
    return state.templatesState.data;
  },
  templatesData(state) {
    return state.templatesState;
  },
  loading(state) {
    return state.templatesState.loading;
  },
  activeEntityType(state) {
    return state.activeEntityType;
  },
  blockById: (state) => (id: string) => {
    return state.activeTab?.blocks?.find((block) => block.id === id) || {};
  },
  allVisibleFieldsByIdsForBlock: (state) => (id: string) => {
    const blocks = state.activeTemplate.content?.stages
      .flatMap((stage) => stage.tabs)
      .flatMap((tab) => tab?.blocks);
    const visibleFields = blocks?.reduce((visibleFields: string[], block) => {
      if (block?.id === id) {
        block.fields?.forEach((field) => {
          if (!field.hidden) {
            visibleFields.push(field.id);
          }
        });
      }
      return visibleFields;
    }, []);

    return uniq(visibleFields);
  },
  visibleFieldsForActiveTabByBlockId: (state) => (id: WorkflowBlockIds) => {
    const block = getActiveTabBlock(state, id);

    return (
      block?.fields?.reduce((prevList: string[], field) => {
        if (!field.hidden) {
          prevList.push(field.id);
        }
        return prevList;
      }, []) || []
    );
  },
  discoveryBlock(state) {
    return getActiveTabBlock(state, WORKFLOW_BLOCKS_IDS.discovery_questions);
  },
  templateFieldsById: (state) => (blockId: keyof WorkflowBlocksData) => {
    return state.templateFields[blockId];
  },
  templateFields(state) {
    return state.templateFields;
  },
  activeOwner(state) {
    return state.activeOwner;
  },
  showStatusesSidebar(state) {
    return state.showStatusesSidebar;
  },
  dataOrchestrationTemplates(state) {
    return getActiveStage(state)?.data_orchestration_templates || [];
  },
  dataOrchestrationTemplateAutomation(state) {
    return (
      getActiveStage(state)?.data_orchestration_template_automation || null
    );
  },
  newStageIds(state) {
    return state.newStageIds;
  },

  emailNotificationsDefinitions(state) {
    return state.activeTemplate?.email_definitions || [];
  },
  smsNotificationsDefinitions(state) {
    return state.activeTemplate?.sms_definitions || [];
  },
  selectedBorrowerPlatformStageId(state) {
    return state.selectedBorrowerPlatformStageId;
  },
  selectedBorrowerPlatformState(state) {
    return state.selectedBorrowerPlatformState;
  }
};

export const workflows = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

function getActiveStage(state: IWorkflowTemplatesState) {
  if (!state.activeStage?.id) {
    return null;
  }
  const { id: stageId } = state.activeStage;
  return (
    state.activeTemplate.content?.stages?.find(
      (stage) => stage.id === stageId
    ) || null
  );
}

function getActiveTab(state: IWorkflowTemplatesState) {
  const { name: tabName } = state.activeTab;
  return getActiveStage(state)?.tabs?.find((tab) => tab.name === tabName);
}

function getActiveTabBlock(
  state: IWorkflowTemplatesState,
  blockId: WorkflowBlockIds
) {
  return getActiveTab(state)?.blocks.find((block) => block.id === blockId);
}

function getBorrowerStagesFromTemplate(template: IWorkflow) {
  if (!template.content) {
    return [];
  }

  const borrowerPlatformDefinition: BorrowerPlatformDefinition | undefined =
    store.getters["options/borrowerPlatformDefinition"];

  return template.content.stages.map((stage) => {
    const statesDef = borrowerPlatformDefinition?.states.filter((state) =>
      state.workflow_template_stage_types.includes(stage.type)
    );
    return {
      id: uuidv4(),
      name: stage.name,
      description: `${stage.name} Stage`,
      states:
        statesDef?.map(
          (def): BorrowerPlatformState => ({
            show_button: false,
            button_text: i18n.global.t("COMMON.SUBMIT"),
            button_url: "",
            button_redirect_type: null,
            description: def.default_description,
            title: def.default_title,
            type: def.type,
            show_all_offers_block: def.show_all_offers_block
          })
        ) ?? [],
      workflow_template_stages: [stage.id]
    };
  });
}

function initBorrowerPlatformCustomization(template: IWorkflow) {
  /**
   * If a Borrower Platform was not customized for this particular template
   * before then FE has to build out the default stages and states for BP.
   */
  if (!template.content || template.content.borrower_platform.stages.length) {
    return;
  }

  template.content.borrower_platform.is_enabled = false;
  template.content.borrower_platform.stages =
    getBorrowerStagesFromTemplate(template);
}
