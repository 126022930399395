import { defineStore } from "pinia";
import communicationService from "@/services/modules/communication";
import type { IApplication } from "@/models/applications";
import type { AxiosError } from "axios";
import type {
  CallLogData,
  CallNotePayload,
  CallOutPayload,
  CommunicationFilters,
  CommunicationLogBase,
  CommunicationLogComment,
  CommunicationLogEventData,
  CommunicationLogFilterableData,
  CommunicationLogState,
  ContactUser,
  CreateTaskPayload,
  Task,
  TaskLog,
  TaskLogData
} from "@/models/communicationLogs";
import { formatDateCustom } from "@/helpers/formatting";
import groupBy from "lodash/groupBy";
import { produce } from "immer";
import cloneDeep from "lodash/cloneDeep";
import type { LogEndpointType } from "@/enums/communicationLogs";
import { LOG_ENDPOINT_TYPE_TO_LOG_TYPE } from "@/helpers/constants/communicationLogs";
import emailService from "@/services/modules/emails";
import { convertToArray, getObjectKeys } from "@/helpers/common";
import set from "lodash/set";

interface CommentPayload {
  logId: CommunicationLogBase["id"];
  type: LogEndpointType;
  comment: CommunicationLogComment["comment"];
}

const PER_PAGE_STEP = "15";
const EMAIL_THREADS_PER_PAGE_STEP = "30";

const getDefaultState = (): CommunicationLogState => ({
  logs: [],
  emailThreads: [],
  meta: null,
  hasNewLogs: false,
  isEditingNote: false,
  canLoadMore: true,
  hasNoMoreData: false,
  activityHubPage: { active_tabs: [] },
  dealActivityHub: { active_tabs: [] }
});

export const useCommunicationStore = defineStore("communication", {
  state: getDefaultState,
  getters: {
    logsGroupedByDate({ logs }) {
      const pattern = "dd MMM yyyy";
      // We want both "today" and "logCreatedAt" to be in current user's time zone
      const today = formatDateCustom(new Date(), pattern, true);

      return groupBy(logs, (log) => {
        const logCreatedAt = formatDateCustom(
          log.data.created_at,
          pattern,
          true
        );
        return today === logCreatedAt ? "today" : logCreatedAt;
      });
    },
    canClientAccessActivityHub(store) {
      return Boolean(store.activityHubPage.active_tabs.length);
    },
    canClientAccessDealActivityHubTab(store) {
      return Boolean(store.dealActivityHub.active_tabs.length);
    }
  },
  actions: {
    async getCommunicationList(filters: Partial<CommunicationFilters> = {}) {
      this.canLoadMore = false;
      // preparing params with proper values
      const params: Partial<CommunicationFilters> = cloneDeep(filters);
      params.per_page = PER_PAGE_STEP;
      const arrayParams: Array<keyof CommunicationFilters> = [
        "application_ids",
        "assignee_ids",
        "call_session_caller_ids"
      ];
      arrayParams.forEach((param) => {
        const value = params[param];
        if (value) {
          (params[param] as Array<string>) = Array.isArray(value)
            ? value
            : [value];
        }
      });
      const response = await communicationService.getCommunicationList(params);
      this.meta = response.meta;
      this.canLoadMore = !!response.data?.length;
      this.hasNoMoreData = !response.data?.length;
      if (!params.page || params.page === "1") {
        this.logs = response.data;
        return;
      }
      // if page > 1, we append logs
      this.logs.push(...response.data);
    },

    async getEmailThreads(filters: Partial<CommunicationFilters> = {}) {
      this.canLoadMore = false;
      // preparing params with proper values
      const params: Partial<CommunicationFilters> = cloneDeep(filters);
      params.per_page = EMAIL_THREADS_PER_PAGE_STEP;
      const arrayParams: Array<keyof CommunicationFilters> = [
        "application_ids",
        "recipient_ids",
        "sender_ids",
        "assignee_ids"
      ];
      arrayParams.forEach((param) => {
        const value = params[param];
        if (value) {
          (params[param] as Array<string>) = convertToArray(value);
        }
      });

      const response = await emailService.getEmailThreads(params);
      this.meta = response.meta;
      this.canLoadMore = !!response.data?.length;
      this.hasNoMoreData = !response.data?.length;
      if (!params.page || params.page === "1") {
        this.emailThreads = response.data;
        return;
      }
      response.data.forEach((emailThread) => {
        const index = this.emailThreads.findIndex(
          (thread) => thread.id === emailThread.id
        );
        if (index === -1) {
          this.emailThreads.push(emailThread);
        } else {
          this.emailThreads[index] = emailThread;
        }
      });
    },

    async callOut(applicationId: IApplication["id"], payload: CallOutPayload) {
      try {
        return await communicationService.callOut(applicationId, payload);
      } catch (error) {
        const ringCentralError = error as AxiosError<{ auth_link?: string }>;
        const authLink = ringCentralError?.response?.data?.auth_link;
        if (authLink) {
          window.open(authLink, "_blank");
        }
      }
    },

    async createTask(payload: CreateTaskPayload) {
      await communicationService.createTask(payload);
    },

    async updateTask(
      taskId: Task["id"],
      payload: Partial<TaskLogData & { description: string }>,
      user?: ContactUser
    ) {
      await communicationService.updateTask(taskId, payload);
      const currentTask = this.logs.find(
        (task) => task.data.id === taskId
      ) as TaskLog;
      if (!currentTask) {
        return;
      }

      currentTask.data = {
        ...currentTask.data,
        ...payload,
        body: payload.description ?? currentTask.data.body
      };
      if (user) {
        currentTask.data.assignee = user;
      }
    },

    async deleteTask(taskId: Task["id"]) {
      await communicationService.deleteTask(taskId);
      this.logs = this.logs.filter((task) => task.data.id !== taskId);
    },

    async updateCallNote(
      callSessionId: CallLogData["id"],
      payload: CallNotePayload
    ) {
      await communicationService.updateCallNote(callSessionId, payload);
    },

    async handleWebsocketLogUpdateEvent(payload: {
      id: CommunicationLogBase["id"];
      event: CommunicationLogEventData["event"];
      data: CommunicationLogFilterableData;
    }) {
      const logToUpdate = this.logs.find((log) => log.id === payload.id);

      if (!logToUpdate || payload.event !== "updated") {
        return;
      }

      const log = await communicationService.getCommunicationLog(payload.id);
      this.logs = produce(this.logs, (draft) => {
        const index = draft.findIndex((savedLog) => savedLog.id === log.id);
        draft[index] = log;
      });
    },

    toggleHasNewLogsStatus(value: boolean) {
      this.hasNewLogs = value;
    },

    toggleIsEditingNoteStatus(value: boolean) {
      this.isEditingNote = value;
    },

    getLogIndex(
      context: LogEndpointType,
      id: CommunicationLogBase["data"]["id"]
    ) {
      return this.logs.findIndex((log) => {
        const { data, type } = log;
        return (
          type === LOG_ENDPOINT_TYPE_TO_LOG_TYPE[context] && data.id === id
        );
      });
    },

    async createComment(payload: CommentPayload) {
      const newComment = await communicationService.createComment(
        payload.type,
        payload.logId,
        payload.comment
      );

      const index = this.getLogIndex(payload.type, payload.logId);
      if (index === -1) {
        return;
      }
      this.logs[index].data.comments.unshift(newComment);
    },

    async updateComment(
      payload: CommentPayload & {
        commentId: CommunicationLogComment["id"];
      }
    ) {
      const updatedComment = await communicationService.updateComment(
        payload.type,
        payload.logId,
        payload.commentId,
        payload.comment
      );
      const index = this.getLogIndex(payload.type, payload.logId);
      if (index === -1) {
        return;
      }
      const commentIndex = this.logs[index].data.comments.findIndex(
        (comment) => comment.id === payload.commentId
      );
      if (commentIndex === -1) {
        return;
      }
      this.logs[index].data.comments[commentIndex] = updatedComment;
    },

    async deleteComment(
      payload: Omit<CommentPayload, "comment"> & {
        commentId: CommunicationLogComment["id"];
      }
    ) {
      await communicationService.deleteComment(
        payload.type,
        payload.logId,
        payload.commentId
      );
      const index = this.getLogIndex(payload.type, payload.logId);
      if (index === -1) {
        return;
      }
      const logData = this.logs[index].data;
      logData.comments = logData.comments.filter(
        (comment) => comment.id !== payload.commentId
      );
    },
    async getActivityHubInfo(applicationId?: string) {
      const activityHubToUpdate: keyof CommunicationLogState = applicationId
        ? "dealActivityHub"
        : "activityHubPage";
      this[activityHubToUpdate] =
        (await communicationService.getActivityHubInfo(applicationId))?.data ??
        getDefaultState()[activityHubToUpdate];
    },
    resetStore(exceptionKeys: Array<keyof CommunicationLogState> = []) {
      const defaultState = getDefaultState();
      const keys = getObjectKeys(defaultState);
      keys.forEach((key) => {
        if (!exceptionKeys.includes(key)) {
          set(this, key, defaultState[key]);
        }
      });
    }
  }
});
