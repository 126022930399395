import UnderwritingApiService from "@/services/modules/underwriting";
import type { IRootState } from "@/models/state";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IUnderwritingLog } from "@/models/underwriting";

interface UnderwritingState {
  underwritingLogs: IUnderwritingLog[];
  loading: boolean;
}

const getDefaultState = (): UnderwritingState => ({
  underwritingLogs: [],
  loading: false
});

const state = getDefaultState();

const mutations: MutationTree<UnderwritingState> = {
  setUnderwritingLogs(state, data: IUnderwritingLog[]): void {
    state.underwritingLogs = data;
  },

  setLoading(state, value: boolean) {
    state.loading = value;
  }
};

const actions: ActionTree<UnderwritingState, IRootState> = {
  async getUnderwritingLogs({ commit }, applicationId: string) {
    commit("setLoading", true);
    const data = await UnderwritingApiService.getLogs(applicationId);

    commit("setUnderwritingLogs", data.data);
    commit("setLoading", false);
  }
};

const getters: GetterTree<UnderwritingState, IRootState> = {
  underwritingLogs(state): IUnderwritingLog[] {
    return state.underwritingLogs;
  },
  loading(state) {
    return state.loading;
  }
};

export const underwriting = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
