import { formatString } from "@/helpers/formatting";
import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import type {
  LenderNetwork,
  LenderNetworkCreatePayload,
  LenderNetworkUpdatePayload
} from "@/models/lenderNetwork";

class LenderNetworkService {
  async show(id: LenderNetwork["id"]) {
    return axiosClient.get<{ data: LenderNetwork }>(
      formatString(endpoints.LENDER_NETWORKS.SHOW, id)
    );
  }

  async create(payload: LenderNetworkCreatePayload) {
    const { data } = await axiosClient.post<{ data: LenderNetwork }>(
      endpoints.LENDER_NETWORKS.INDEX,
      payload
    );
    return data.data;
  }

  async update(id: LenderNetwork["id"], payload: LenderNetworkUpdatePayload) {
    const { data } = await axiosClient.put<{ data: LenderNetwork }>(
      formatString(endpoints.LENDER_NETWORKS.SHOW, id),
      payload
    );
    return data.data;
  }
}

const lenderNetworkService = new LenderNetworkService();
export default lenderNetworkService;
