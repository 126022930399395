import type { IDashboardStatistic } from "@/models/dashboard";
import type { IStatisticsData } from "@/models/funders";
import { calcAvg } from "./calculations";
import { fixTo, formatMoney, formatPercentage } from "./formatting";

export const APP_STARTED_TITLE = "Application Started";
export const APP_COMPLETE_TITLE = "Application Complete";

export const DASHBOARD_STATISTICS = {
  application_started: {
    title: APP_STARTED_TITLE,
    subtitle: "Applications made through Lendflow.",
    money: false,
    percentage: false
  },
  application_complete: {
    title: APP_COMPLETE_TITLE,
    subtitle: "The number of applications that have been completed.",
    money: false,
    percentage: false
  },
  total_offer_out: {
    title: "Offers Out",
    subtitle:
      "The number of total offers that have been received for businesses. Some businesses may have multiple offers.",
    money: false,
    percentage: false
  },
  business_with_offer: {
    title: "Applicants With Offer",
    subtitle:
      "The number of businesses who have at least 1 financial product offer from a bank or lender.",
    money: false,
    percentage: false
  },
  funded_number: {
    title: "Funded Deals",
    subtitle: "The number of applications that have been funded.",
    money: false,
    percentage: false
  },
  application_declined: {
    title: "Declined Deals",
    subtitle: "The number of times the deal was declined.",
    money: false,
    percentage: false
  },
  funded_amount: {
    title: "Funded Amount",
    subtitle:
      "The total amount of funding you've disbursed to small businesses.",
    money: true,
    percentage: false
  },
  average_funding_size: {
    title: "Average Funding Size",
    subtitle:
      "The average dollar amount of funding you’ve disbursed to small businesses.",
    money: true,
    percentage: false
  },
  funded_rate: {
    title: "Funded Rate",
    subtitle: "",
    percentage: true,
    money: false
  },
  revenue_earned: {
    title: "Revenue Earned",
    subtitle: "The total amount of revenue you've earned.",
    money: true,
    percentage: false
  }
} as const;

export const getTrendForTwoPeriods = (
  total: number | string,
  prevTotal: number | string | undefined
) => {
  const curr = Number(total ?? 0);
  const prev = Number(prevTotal ?? 0);
  if (curr === prev) {
    return 0;
  }
  return curr > prev ? 1 : -1;
};

export const getTrend = (data: IStatisticsData[] | IDashboardStatistic[]) => {
  let trend = 0;
  let dataLength = data.length;
  if (dataLength > 30) {
    dataLength = 30;
    data = data.slice(1).slice(-30);
  }
  const dataHalfLength = Math.ceil(dataLength / 2);

  const firstHalfAvg = calcAvg(
    data.splice(0, dataHalfLength) as IStatisticsData[],
    (day: { total: number }) => day.total
  );
  const secondHalfAvg = calcAvg(
    data.splice(-dataHalfLength) as IStatisticsData[],
    (day) => day.total
  );

  if (dataLength > 1) {
    if (secondHalfAvg > firstHalfAvg) {
      trend = 1;
    } else if (secondHalfAvg < firstHalfAvg) {
      trend = -1;
    }
  }
  return trend;
};

export const FUNDER_STATISTICS = {
  FUNDER_METRICS: {
    submitted_applications: {
      title: "Apps Submitted"
    },
    recent_submissions: {
      title: "Submissions in Past 30 Days"
    },
    total_offers: {
      title: "Total offers received"
    },
    funded_businesses: {
      title: "Funded Businesses"
    },
    average_funding_amount: {
      title: "Avg Funding Amount",
      formatter: (v: string | number | null | undefined) => formatMoney(v, 2)
    },
    renewals: {
      title: "Renewals"
    },
    offer_revoked: {
      title: "Number Of Offers Revoked"
    },
    total_funding_amount: {
      title: "Total Funding Amount",
      formatter: (v: string | number | null | undefined) => formatMoney(v, 2)
    }
  },
  PRODUCT_METRICS: {
    advance_rate: {
      title: "Avg Advance Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    term_length: {
      title: "Avg Term Length",
      suffix: "month",
      formatter: (v: string | number) => fixTo(v)
    },
    "30_day_discount_rate": {
      title: "Avg 30 Day Discount Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    "10_day_discount_rate_after_initial_30_days": {
      title: "Avg 10 Day Discount Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    monthly_interest: {
      title: "Monthly Interest",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    max_sell_factor_rate: {
      title: "Max Sell Factor Rate"
    },
    min_buy_factor_rate: {
      title: "Min Buy Factor Rate"
    },
    max_sell_interest_rate: {
      title: "Max Sell Interest Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    min_buy_interest_rate: {
      title: "Min Buy Interest Rate",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    funding_amount: {
      title: "Funding Amount",
      formatter: (v: string | number | null | undefined) => formatMoney(v, 2)
    }
  },
  CONVERSION_RATES: {
    offer_received_app_submitted: {
      title: "Offers Received / Apps Submitted",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    offer_received_deals_funded: {
      title: "Deals Funded / Offers Received",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    app_submitted_deals_funded: {
      title: "Deals Funded / Apps Submitted",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    },
    renewal_percentage: {
      title: "Renewal Percentage",
      formatter: (v: string | number | null | undefined) =>
        formatPercentage(v, 2)
    }
  }
};
