import { defineStore } from "pinia";
import notesService from "@/services/modules/notes";
import type {
  NoteFilterParams,
  NotesResource,
  NotePayload
} from "@/models/notes";
import type { IResponseLinks, IResponseMeta } from "@/models/common";

interface UseNotesState {
  currentNotes: NotesResource;
  activeNoteDealId: string | null;
}

const getDefaultState = (): UseNotesState => ({
  currentNotes: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  activeNoteDealId: null
});

const useNotesStore = defineStore("notes", {
  state: getDefaultState,
  actions: {
    async getNotes(params: NoteFilterParams) {
      const notes = await notesService.getNotes(params);
      this.currentNotes = notes.data;
    },
    async createNote(payload: NotePayload) {
      await notesService.createNote(payload);
    },
    async updateNote(payload: NotePayload) {
      if (!payload.id) {
        return;
      }
      await notesService.updateNote(payload);
    },
    resetNotes() {
      this.currentNotes = getDefaultState().currentNotes;
    }
  }
});

export default useNotesStore;
