import type { AbilityArguments, IAuthUser } from "@/models/authentications";
import type { IUser } from "@/models/users";
import md5 from "md5";
import { CLIENT_ROLE_GROUP, FUNDER_ROLE_GROUP } from "./constants";
import isEmpty from "lodash/isEmpty";
import type { PermissionSubject, Ability } from "@/enums/auth";

export const getAbilityChecksum = (permissionAbility: {
  subject: PermissionSubject | string;
  ability: Ability | string;
  arguments?: AbilityArguments;
}) => {
  const abilityObj: {
    subject: PermissionSubject | string;
    ability: Ability | string;
    arguments?: AbilityArguments;
  } = {
    subject: permissionAbility.subject,
    ability: permissionAbility.ability
  };
  if (!isEmpty(permissionAbility.arguments)) {
    abilityObj.arguments = permissionAbility.arguments;
  }

  return md5(JSON.stringify(abilityObj));
};

export const isClient = (user: IUser | IAuthUser | null) =>
  user?.roles?.some((role) => CLIENT_ROLE_GROUP.includes(role));

export const isFunder = (user: IUser | IAuthUser | null) =>
  user?.roles?.some((role) => FUNDER_ROLE_GROUP.includes(role));
