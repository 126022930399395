import type { Channel } from "laravel-echo/dist/channel";

import { windowWithEcho } from "@/helpers/websockets";
import { EVENT_HANDLERS } from "@/helpers/websockets/common";
import type { SocketChannel } from "@/helpers/websockets/common";

export const getFormattedChannelName = (
  channelName: SocketChannel,
  id?: string
): string => {
  if (!id) {
    return channelName;
  }
  if (channelName.includes("idPlaceholder")) {
    return channelName.replace("idPlaceholder", id);
  }
  return `${channelName}${id}`;
};

export const joinPrivateChannel = (channelName: string): Channel => {
  return windowWithEcho.Echo.private(channelName);
};

export const subscribeToChannelEvents = (
  channelName: SocketChannel,
  id?: string
): void => {
  const channel = joinPrivateChannel(getFormattedChannelName(channelName, id));
  const eventHandlers = EVENT_HANDLERS[channelName];
  Object.keys(eventHandlers).forEach((event) => {
    channel.listen(event, eventHandlers[event as keyof typeof eventHandlers]);
  });
};

export const leaveChannel = (channelName: SocketChannel, id?: string): void => {
  windowWithEcho.Echo?.leave(getFormattedChannelName(channelName, id));
};
