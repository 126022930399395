import {
  APPLICATIONS_EVENT_HANDLERS,
  APPLICATION_LIST_HANDLERS
} from "@/helpers/websockets/applications/events";

import { AUTH_EVENT_HANDLERS } from "@/helpers/websockets/authentication/events";
import { COMMUNICATION_LOGS_EVENT_HANDLERS } from "@/helpers/websockets/communication/events";
import { SCORECARDS_EVENT_HANDLERS } from "@/helpers/websockets/scorecards/events";

export const APPLICATIONS_SOCKETS_CHANNEL = "applications." as const;
export const APPLICATIONS_SOCKETS_CHANNEL_ROOT = "applications" as const;
export const APPLICATIONS_SOCKETS_CHANNEL_FOR_CLIENT =
  "applications_for_client." as const;
export const AUTH_SOCKETS_CHANNEL_FOR_CLIENT = "clients." as const;
export const AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS = "users." as const;
export const COMMUNICATION_LOGS_CHANNEL = "communication_logs" as const;
export const COMMUNICATION_LOGS_APPLICATION_CHANNEL =
  `${APPLICATIONS_SOCKETS_CHANNEL}idPlaceholder.${COMMUNICATION_LOGS_CHANNEL}` as const;
export const SCORECARDS_BT_SOCKETS_CHANNEL = "score_card_backtest." as const;
export const SCORECARDS_BT_GROUP_SOCKETS_CHANNEL =
  "score_card_group_backtest." as const;

export type SocketChannel =
  | typeof APPLICATIONS_SOCKETS_CHANNEL
  | typeof APPLICATIONS_SOCKETS_CHANNEL_ROOT
  | typeof APPLICATIONS_SOCKETS_CHANNEL_FOR_CLIENT
  | typeof AUTH_SOCKETS_CHANNEL_FOR_CLIENT
  | typeof AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS
  | typeof COMMUNICATION_LOGS_CHANNEL
  | typeof COMMUNICATION_LOGS_APPLICATION_CHANNEL
  | typeof SCORECARDS_BT_SOCKETS_CHANNEL
  | typeof SCORECARDS_BT_GROUP_SOCKETS_CHANNEL;

export const EVENT_HANDLERS = {
  [APPLICATIONS_SOCKETS_CHANNEL]: APPLICATIONS_EVENT_HANDLERS,
  [APPLICATIONS_SOCKETS_CHANNEL_ROOT]: APPLICATION_LIST_HANDLERS,
  [APPLICATIONS_SOCKETS_CHANNEL_FOR_CLIENT]: APPLICATION_LIST_HANDLERS,
  [AUTH_SOCKETS_CHANNEL_FOR_ALL_USERS]: AUTH_EVENT_HANDLERS,
  [AUTH_SOCKETS_CHANNEL_FOR_CLIENT]: AUTH_EVENT_HANDLERS,
  [COMMUNICATION_LOGS_CHANNEL]: COMMUNICATION_LOGS_EVENT_HANDLERS,
  [COMMUNICATION_LOGS_APPLICATION_CHANNEL]: COMMUNICATION_LOGS_EVENT_HANDLERS,
  [SCORECARDS_BT_SOCKETS_CHANNEL]: SCORECARDS_EVENT_HANDLERS,
  [SCORECARDS_BT_GROUP_SOCKETS_CHANNEL]: SCORECARDS_EVENT_HANDLERS
} as const;
