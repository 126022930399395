import type { ClearAdverseMediaReportSectionResults } from "@/models/commercialData/clear/ClearAdverseMediaReport";
import type {
  SearchRecord,
  SearchRecordAttribute
} from "@/models/commercialData/clear/ClearConfirmPersonSearch";
import type { SearchRecordAttribute as BusinessSearchRecordAttribute } from "@/models/commercialData/clear/ClearIdConfirmBusiness";
import {
  CLEAR_ADVERSE_MEDIA_RELEVANCE_HIGH,
  CLEAR_ADVERSE_MEDIA_RELEVANCE_MEDIUM,
  CLEAR_ADVERSE_MEDIA_RELEVANCE_LOW,
  CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES
} from "@/helpers/constants/clear";

export const getSectionData = (
  data: ClearAdverseMediaReportSectionResults[] | undefined,
  sectionName: string
): ClearAdverseMediaReportSectionResults =>
  Object.values(data || {}).find(
    (value) => value.SectionName === sectionName
  ) || {};

export const getColorIndexForRelevance = (
  relevanceScore: number | string
): number =>
  Number(relevanceScore) >= CLEAR_ADVERSE_MEDIA_RELEVANCE_HIGH
    ? CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES[
        CLEAR_ADVERSE_MEDIA_RELEVANCE_HIGH
      ]
    : Number(relevanceScore) >= CLEAR_ADVERSE_MEDIA_RELEVANCE_MEDIUM
      ? CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES[
          CLEAR_ADVERSE_MEDIA_RELEVANCE_MEDIUM
        ]
      : CLEAR_ADVERSE_MEDIA_RELEVANCE_COLOR_CODES[
          CLEAR_ADVERSE_MEDIA_RELEVANCE_LOW
        ];

export const getAttributeText = (
  value:
    | string
    | SearchRecordAttribute
    | BusinessSearchRecordAttribute
    | undefined
) => {
  if (typeof value === "string") {
    return value;
  }
  return value?.["@text"] || "-";
};

export const getFullNameFromSearchRecord = (record: SearchRecord) => {
  const { FirstName, LastName } = record;
  const types = [FirstName, LastName].map((name) => typeof name);
  if (types.every((type) => type === "string")) {
    return `${FirstName} ${LastName}`;
  }
  if (types.every((type) => type === "object")) {
    return `${(FirstName as SearchRecordAttribute)["@text"]} ${
      (LastName as SearchRecordAttribute)["@text"]
    }`;
  }
  if (types[0] === "string" && types[1] === "object") {
    return `${FirstName} ${(LastName as SearchRecordAttribute)["@text"]}`;
  }
  return `${(FirstName as SearchRecordAttribute)["@text"]} ${LastName}`;
};

export const getSubmittedDataValue = (value: string | string[] | undefined) => {
  if (!value) {
    return "-";
  }
  if (Array.isArray(value)) {
    return value.filter(Boolean).join(", ") || "-";
  }
  return value || "-";
};
