import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta
} from "@/models/common";
import type {
  BackRoute,
  IRunScorecardGroupsPayload,
  IRunScorecardsPayload,
  IScorecard,
  IScorecardAttribute,
  IScorecardCategory,
  IScorecardContentDistribution,
  IScorecardGroup,
  IScorecardGroupResult,
  IScorecardResult,
  ScorecardGroupWeights,
  ScorecardsState
} from "@/models/scorecards";
import {
  expandContentCategory,
  getScorecardCategoryBlueprint
} from "@/helpers/scorecards";
import type { IDataService } from "@/models/orchestration";
import type { IRootState } from "@/models/state";
import scorecardsApiService from "@/services/modules/scorecards";
import type { TYPE_GROUP } from "@/helpers/constants/scorecards";
import { TYPE_INDIVIDUAL } from "@/helpers/constants/scorecards";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import { produce } from "immer";
import { v4 as uuid4 } from "uuid";
import uniq from "lodash/uniq";

const getDefaultState = (): ScorecardsState => ({
  all: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  loading: false,
  groupLoading: false,
  active: null,
  activeServices: [],
  activeTemplateCategories: [],
  activeGroup: null,
  allGroups: null,
  groupReport: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  report: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  groupResultsForApplication: {
    data: [],
    meta: {} as IResponseMeta,
    links: {} as IResponseLinks
  },
  invalidCategories: [],
  runScorecardIds: [],
  activeBuilderPhase: 0,
  isBuilderView: true,
  backRoute: "Scorecards",
  runScorecardType: null,
  groupHasBeenRun: false,
  scorecardHasBeenRun: false
});

const state = getDefaultState();

const mutations: MutationTree<ScorecardsState> = {
  setAll(state, data: IPaginatedResponse<IScorecard>) {
    state.all = data;
  },

  setLoading(state, val: boolean) {
    state.loading = val;
  },

  setGroupHasBeenRun(state, val: boolean) {
    state.groupHasBeenRun = val;
  },

  setRunScorecardType(
    state,
    type: typeof TYPE_INDIVIDUAL | typeof TYPE_GROUP | null
  ) {
    state.runScorecardType = type;
  },

  setScorecardHasBeenRun(state, val: boolean) {
    state.scorecardHasBeenRun = val;
  },

  setGroupLoading(state, val: boolean) {
    state.groupLoading = val;
  },

  setActive(state, data: IScorecard | null) {
    const updatedData = produce(data, (draftState) => {
      draftState?.content?.categories?.forEach((category) => {
        category.category_id = uuid4();
      });
    });
    state.active = updatedData;
  },

  setActiveServices(state, services: IDataService[] | null) {
    state.activeServices = produce(state.activeServices, (draftState) => {
      services?.forEach((service) => {
        const { attributes } = service;
        if (attributes) {
          attributes.forEach((attr) => {
            const { context } = attr;
            if (context?.id) {
              attr.context = {
                value: { id: context.id },
                type: context.type
              };
            }
          });
        }
      });
      draftState.splice(0, draftState.length);
      if (services) {
        Object.assign(draftState, services);
      }
    });
  },

  setActiveScorecardTemplateCategories(state, data: IScorecard | null) {
    if (!data?.content?.categories) {
      state.activeTemplateCategories = [];
      return;
    }
    state.activeTemplateCategories = data.content.categories.map(
      (category, index) => {
        return expandContentCategory(category, index, state.activeServices);
      }
    );
  },

  clear(state) {
    state.all = getDefaultState().all;
  },

  setActiveGroup(state, data: IScorecardGroup | null) {
    state.activeGroup = data;
  },

  appendNextCategory(
    state,
    payload: {
      categoryId: string | null;
      data: IScorecardAttribute | IScorecardCategory;
      insertAfter: number | null;
    }
  ) {
    const newCategory = getScorecardCategoryBlueprint(payload.data);
    if (
      !state.activeTemplateCategories.length ||
      (!payload.data.category_id && payload.insertAfter === null)
    ) {
      state.activeTemplateCategories = [
        ...state.activeTemplateCategories,
        newCategory
      ];
      return;
    }

    const currentCategoryIndex = state.activeTemplateCategories.findIndex(
      (category) => category.category_id === payload.data.category_id
    );
    if (payload.insertAfter !== null) {
      if (currentCategoryIndex > -1) {
        // remove first
        state.activeTemplateCategories.splice(currentCategoryIndex, 1);
      }
      if (payload.insertAfter < currentCategoryIndex) {
        state.activeTemplateCategories.splice(
          payload.insertAfter + 1,
          0,
          newCategory
        );
        return;
      }
      if (currentCategoryIndex > -1) {
        // account for spliced record
        state.activeTemplateCategories.splice(
          payload.insertAfter,
          0,
          newCategory
        );
        return;
      }
      state.activeTemplateCategories.splice(
        payload.insertAfter + 1,
        0,
        newCategory
      );
    }
  },

  replaceCategory(
    state,
    payload: {
      data: IScorecardAttribute | IScorecardCategory;
      replace: IScorecardCategory;
    }
  ) {
    if (payload.data.category_id) {
      // remove it first
      const indexToRemove = state.activeTemplateCategories.findIndex(
        (category) => category.category_id === payload.data.category_id
      );
      if (indexToRemove > -1) {
        state.activeTemplateCategories.splice(indexToRemove, 1);
      }
    }
    const newCategory = getScorecardCategoryBlueprint(payload.data);
    const indexToReplace = state.activeTemplateCategories.findIndex(
      (category) => category.category_id === payload.replace.category_id
    );
    if (indexToReplace === -1) {
      return;
    }
    state.activeTemplateCategories.splice(indexToReplace, 1, newCategory);
  },

  removeCategory(state, payload: { data: IScorecardCategory }) {
    if (!payload.data.category_id) {
      return;
    }
    const indexToRemove = state.activeTemplateCategories.findIndex(
      (category) => category.category_id === payload.data.category_id
    );
    if (indexToRemove > -1) {
      state.activeTemplateCategories.splice(indexToRemove, 1);
    }
  },

  setUpdatedCategory(state, updatedCategory: IScorecardCategory) {
    state.activeTemplateCategories = produce(
      state.activeTemplateCategories,
      (draft) => {
        const index = draft.findIndex(
          (template) => template.category_id === updatedCategory.category_id
        );
        if (index !== -1) {
          draft[index] = { ...draft[index], ...updatedCategory };
          // if there are backups the points for all backups should always equal points for the category
          draft[index].backups?.forEach((backup) => {
            backup.points = updatedCategory.points;
          });
        }
      }
    );
  },

  setUpdatedBackup(state, payload) {
    state.activeTemplateCategories = produce(
      state.activeTemplateCategories,
      (draft) => {
        const index = draft.findIndex(
          (template) => template.category_id === payload.parent.category_id
        );
        if (index == -1) {
          return;
        }
        // checking for value was still giving TS errors that this could be undefined
        const backups = draft[index].backups ?? undefined;
        if (!backups) {
          return;
        }
        const backupIndex = backups.findIndex(
          (backup) => backup.category_id === payload.backup.category_id
        );
        const backup = backups[backupIndex];
        if (backupIndex !== -1) {
          backups[backupIndex] = { ...backup, ...payload.backup };
        }
      }
    );
  },

  setUpdatedGroupWeights(state, updatedGroupWeights: ScorecardGroupWeights[]) {
    if (!state.activeGroup) {
      return;
    }
    state.activeGroup = { ...state.activeGroup, weights: updatedGroupWeights };
  },

  setDistributionForGroupScorecard(
    state,
    payload: {
      scorecard: IScorecard;
      distribution: IScorecardContentDistribution[] | undefined;
      is_automatically_distributed: boolean;
    }
  ) {
    if (!state.activeGroup) {
      return;
    }
    // this is required to allow immer to update if the weight_distribution is undefined
    state.activeGroup.score_cards = state.activeGroup.score_cards.map((sc) => {
      if (sc.id === payload.scorecard.id) {
        return {
          ...sc,
          weight_distribution: sc.weight_distribution ?? { ranges: [] }
        };
      } else {
        return sc;
      }
    });
    state.activeGroup = produce(state.activeGroup, (draft) => {
      const index = draft.score_cards.findIndex(
        (score_card) => score_card.id === payload.scorecard.id
      );
      if (index == -1) {
        return;
      }
      const weight_distribution =
        draft.score_cards[index].weight_distribution ?? undefined;
      if (!weight_distribution) {
        if (!payload.distribution) {
          return;
        }
        // eslint-disable-next-line
        // @ts-ignore
        draft.score_cards[index].weight_distribution.ranges =
          payload.distribution;
      } else {
        weight_distribution.ranges = payload.distribution ?? [];
      }
      draft.score_cards[index].is_automatically_distributed =
        payload.is_automatically_distributed;
    });
  },

  removeBackup(
    state,
    payload: { backup: IScorecardCategory; parent: IScorecardCategory }
  ) {
    if (!payload.parent.category_id || !payload.backup.category_id) {
      return;
    }
    const parentIndex = state.activeTemplateCategories.findIndex(
      (category) => category.category_id === payload.parent.category_id
    );
    if (parentIndex > -1) {
      // find backup in parent
      const backups = state.activeTemplateCategories[parentIndex].backups;
      if (!backups) {
        return;
      }
      const backupIndex = backups.findIndex(
        (backup) => backup.category_id === payload.backup.category_id
      );
      if (backupIndex > -1) {
        backups.splice(backupIndex, 1);
      }
    }
  },
  addBackup(
    state,
    payload: {
      parent: IScorecardAttribute | IScorecardCategory;
      backup: IScorecardAttribute | IScorecardCategory;
    }
  ) {
    const newBackup = getScorecardCategoryBlueprint(payload.backup);
    if (!state.activeTemplateCategories.length || !payload.parent) {
      return;
    }
    const currentCategoryIndex = state.activeTemplateCategories.findIndex(
      (category) => category.category_id === payload.parent.category_id
    );
    if (currentCategoryIndex > -1) {
      const currentBackupIndex = state.activeTemplateCategories[
        currentCategoryIndex
      ].backups?.findIndex(
        (backup) => backup.category_id === payload.backup.category_id
      );
      if (!currentBackupIndex || currentBackupIndex === -1) {
        // add it
        if (!state.activeTemplateCategories[currentCategoryIndex].backups) {
          state.activeTemplateCategories[currentCategoryIndex].backups = [
            newBackup
          ];
          return;
        }
        state.activeTemplateCategories[currentCategoryIndex].backups?.push(
          newBackup
        );
        return;
      }
      // remove first
      if (currentBackupIndex > -1) {
        state.activeTemplateCategories[currentCategoryIndex].backups?.splice(
          currentBackupIndex,
          1
        );
      }
    }
  },

  setAllGroups(state, groups: IPaginatedResponse<IScorecardGroup> | null) {
    state.allGroups = groups;
  },

  setScorecardGroupReport(
    state,
    report: IPaginatedResponse<IScorecardGroupResult> | null
  ) {
    state.groupReport = report;
  },

  setScorecardReport(
    state,
    report: IPaginatedResponse<IScorecardResult> | null
  ) {
    state.report = report;
  },

  setScorecardGroupResultsForApplication(
    state,
    results: IPaginatedResponse<IScorecardGroupResult> | null
  ) {
    state.groupResultsForApplication = results;
  },

  setInvalidCategories(state, categories: IScorecardCategory[]) {
    state.invalidCategories = categories;
  },

  setRunScorecardIds(state, id: string) {
    state.runScorecardIds = uniq([...state.runScorecardIds, id]);
  },

  clearRunScorecardIds(state) {
    state.runScorecardIds = [];
  },

  setActiveBuilderPhase(state, phase: number) {
    state.activeBuilderPhase = phase;
  },

  setIsBuilderView(state, isBuilderView: boolean) {
    state.isBuilderView = isBuilderView;
  },

  setBackRoute(state, backRoute: BackRoute) {
    state.backRoute = backRoute;
  }
};

const actions: ActionTree<ScorecardsState, IRootState> = {
  async createScorecard(_, scorecard: Partial<IScorecard>) {
    return await scorecardsApiService.createScorecard(scorecard);
  },

  async getAll({ commit }, params: Record<string, string | number> = {}) {
    commit("setLoading", true);
    const data = await scorecardsApiService.getScorecards(params);
    commit("setAll", data);
    commit("setLoading", false);
    commit("clearRunScorecardIds");
  },

  async getServices({ commit }) {
    commit("setLoading", true);
    const data = await scorecardsApiService.getScorecardServices();
    commit("setActiveServices", data);
    commit("setLoading", false);
  },

  async get({ commit, dispatch }, id: string) {
    if (!id) {
      return;
    }
    commit("setLoading", true);
    if (!state.activeServices?.length) {
      await dispatch("getServices");
    }
    const data = await scorecardsApiService.get(id);
    commit("setActive", data);
    commit("setActiveScorecardTemplateCategories", state.active);
    commit("setLoading", false);
  },

  async update({ commit, dispatch }, scorecard: Partial<IScorecard>) {
    if (!scorecard.id) {
      return;
    }
    if (!state.activeServices?.length) {
      await dispatch("getServices");
    }
    const data = await scorecardsApiService.update(scorecard.id, scorecard);
    commit("setActive", data);
    commit("setActiveScorecardTemplateCategories", data);
    return data;
  },

  async createGroup(_, scorecardGroup: Partial<IScorecardGroup>) {
    return await scorecardsApiService.createGroup(scorecardGroup);
  },

  async getGroup(
    { commit },
    params: { id: string; include_content?: boolean }
  ) {
    if (!params.id) {
      return;
    }
    commit("setGroupLoading", true);
    try {
      const data = await scorecardsApiService.getGroup(params);
      commit("setActiveGroup", data);
    } finally {
      commit("setGroupLoading", false);
    }
  },

  async updateGroup({ commit }, scorecardGroup: Partial<IScorecardGroup>) {
    if (!scorecardGroup.id) {
      return;
    }
    const data = await scorecardsApiService.updateGroup(
      scorecardGroup.id,
      scorecardGroup
    );
    commit("setActiveGroup", data);
    return data;
  },

  async getScorecardGroups(
    { commit },
    params: Record<string, string | number>
  ) {
    const data = await scorecardsApiService.getScorecardGroups(params);
    commit("setAllGroups", data);
    commit("clearRunScorecardIds");
  },

  async getScorecardContextReport(
    { commit },
    {
      id,
      type,
      ...params
    }: {
      id: string;
      type: "group" | "individual";
      params: Record<string, string | number>;
    }
  ) {
    const data = await scorecardsApiService.getScorecardContextReport({
      id,
      type,
      ...params
    });
    const mutationType =
      type === "group" ? "setScorecardGroupReport" : "setScorecardReport";
    commit(mutationType, data);
  },

  async getScorecardBacktestContextReport(
    { commit },
    {
      id,
      type,
      ...params
    }: {
      id: string;
      type: "group" | "individual";
      params: Record<string, string | number>;
    }
  ) {
    const data = await scorecardsApiService.getScorecardBacktestContextReport({
      id,
      type,
      ...params
    });
    const mutationType =
      type === "group" ? "setScorecardGroupReport" : "setScorecardReport";
    commit(mutationType, data);
  },

  async getScorecardGroupResultsForApplication(
    { commit },
    { application_id, id, ...params }: Record<string, string | number>
  ) {
    const data =
      await scorecardsApiService.getScorecardGroupResultsForApplication(
        application_id,
        id,
        params
      );
    commit("setScorecardGroupResultsForApplication", data);
  },

  async runScorecards(
    _,
    payload: {
      appId: string;
      data: IRunScorecardsPayload;
    }
  ) {
    const data = await scorecardsApiService.runScorecards(
      payload.appId,
      payload.data
    );
    return data;
  },

  async runScorecardGroups(
    _,
    payload: {
      appId: string;
      data: IRunScorecardGroupsPayload;
    }
  ) {
    const data = await scorecardsApiService.runScorecardGroups(
      payload.appId,
      payload.data
    );
    return data;
  },

  async updatePoints(
    { dispatch },
    payload: { appId: string; scorecardId: string; points: number }
  ) {
    const data = await scorecardsApiService.updatePoints(
      payload.appId,
      payload.scorecardId,
      payload.points
    );
    await dispatch("getAll", { application_id: payload.appId });
    return data;
  },

  async duplicateScorecard(_, id: IScorecard["id"]) {
    await scorecardsApiService.duplicateScorecard(id);
  },

  // This method is reserved for an event pushed through a WebSocket
  // See "src/helpers/websockets/applications/handlers.ts"
  async respondToResultFinishedEvent({ getters, dispatch, commit, state }) {
    const runIds = state.runScorecardIds;
    if (!runIds.length) {
      return;
    }
    const mutationType =
      getters.runScorecardType === TYPE_INDIVIDUAL
        ? "setScorecardHasBeenRun"
        : "setGroupHasBeenRun";

    commit(mutationType, true);
    commit("clearRunScorecardIds");
    commit("setRunScorecardType", null);
    dispatch("applications/syncActiveToAll", null, { root: true });
  }
};

const getters: GetterTree<ScorecardsState, IRootState> = {
  all(state) {
    return state.all;
  },

  loading(state) {
    return state.loading;
  },

  groupLoading(state) {
    return state.groupLoading;
  },

  active(state) {
    return state.active;
  },

  activeServices(state) {
    return state.activeServices;
  },

  activeGroup(state) {
    return state.activeGroup;
  },

  activeTemplateCategories(state) {
    return state.activeTemplateCategories;
  },

  allGroups(state) {
    return state.allGroups;
  },

  groupReport(state) {
    return state.groupReport;
  },

  report(state) {
    return state.report;
  },

  groupResultForApplication(state) {
    return state.groupResultsForApplication;
  },

  invalidCategories(state) {
    return state.invalidCategories;
  },

  runScorecardIds(state) {
    return state.runScorecardIds;
  },

  activeBuilderPhase(state) {
    return state.activeBuilderPhase;
  },

  isBuilderView(state) {
    return state.isBuilderView;
  },

  backRoute(state) {
    return state.backRoute;
  },

  groupHasBeenRun(state) {
    return state.groupHasBeenRun;
  },

  scorecardHasBeenRun(state) {
    return state.scorecardHasBeenRun;
  },

  runScorecardType(state) {
    return state.runScorecardType;
  }
};

export const scorecards = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
