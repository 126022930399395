import { computed, type ComputedRef, type Ref } from "vue";
import type {
  IBankMonthlySummary,
  IBankTransactionSummary,
  IBankTransactionsSummary,
  StubbedBankMonthlySummary,
  StubbedBankTransactionSummary
} from "@/models/applications";
import type { PlaidData } from "@/models/applicationData/plaid";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import uniqBy from "lodash/uniqBy";
import { safeParseToFloat } from "@/helpers/formatting";
import { add } from "date-fns";
import { parse } from "date-fns";
import { compareDesc } from "date-fns";
import { format } from "date-fns";
import { isAfter } from "date-fns";
import { isSameMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { endOfDay } from "date-fns";
import { sub } from "date-fns";
import { startOfMonth } from "date-fns";

export const usePlaidDataBase = () => {
  const { getters } = useStore();

  const plaidData = computed<PlaidData>(() => {
    return getters["applications/plaidData"];
  });

  return { plaidData };
};

export const usePlaidTransactionsSummaryData = (
  transactionsSummary: ComputedRef<IBankTransactionsSummary>,
  selectedMonth: Ref<string>
) => {
  const { t } = useI18n();
  const summaryBase = {
    deposits: 0,
    deposits_count: 0,
    withdrawals: 0,
    withdrawals_count: 0
  } as const;

  const datesWithTransactions = computed(() => {
    const dates = Object.keys(transactionsSummary.value).map((date) =>
      parse(date, "yyyy LLL dd", new Date())
    );

    if (dates.length <= 1) {
      return dates;
    }

    return dates.sort(compareDesc);
  });

  const dateOfVeryFirstTransaction = computed(
    () => datesWithTransactions.value?.[datesWithTransactions.value.length - 1]
  );

  const dateOfVeryLastTransaction = computed(
    () => datesWithTransactions.value?.[0]
  );

  const allMonths = computed(() => {
    const months = [];
    let currentMonth = startOfMonth(dateOfVeryFirstTransaction.value);
    const lastMonth = startOfMonth(dateOfVeryLastTransaction.value);

    while (currentMonth <= lastMonth) {
      months.push(currentMonth);

      currentMonth = add(currentMonth, { months: 1 });
    }

    return months;
  });
  const availableMonths = computed(() =>
    allMonths.value.reduce(
      (acc, month) => {
        const formatted = format(month, "yyyy LLL");
        acc[formatted] = formatted;
        return acc;
      },
      { "": `${t("COMMON.ALL")} ${t("COMMON.MONTHS")}` } as Record<
        string,
        string
      >
    )
  );

  const getDailyTransactionStub = (
    date: Date
  ): StubbedBankTransactionSummary => ({
    ...summaryBase,
    balance: 0,
    month: format(date, "yyyy LLL"),
    _stubbed: true
  });
  const isStubbedBankTransactionSummary = (
    summary: IBankTransactionSummary | StubbedBankTransactionSummary
  ): summary is StubbedBankTransactionSummary => {
    return !!summary._stubbed;
  };

  const monthsWithoutTransactions = computed(() => {
    let date = startOfMonth(dateOfVeryFirstTransaction.value);
    const monthsWithoutTransactions: Date[] = [];

    while (date <= dateOfVeryLastTransaction.value) {
      if (
        !monthsWithTransactions.value.some((month) => isSameMonth(month, date))
      ) {
        monthsWithoutTransactions.push(date);
      }

      date = add(date, { months: 1 });
    }

    return monthsWithoutTransactions;
  });

  const monthsWithTransactions = computed(() =>
    uniqBy(datesWithTransactions.value, (date) =>
      format(date, "yyyy LLL")
    ).reverse()
  );

  const dailySummary = computed(() => {
    const summary = transactionsSummary.value;
    const dailySummary: IBankTransactionsSummary = {};
    let currentDate = dateOfVeryFirstTransaction.value;

    if (!currentDate || !dateOfVeryLastTransaction.value) {
      return dailySummary;
    }

    while (!isAfter(currentDate, dateOfVeryLastTransaction.value)) {
      const dateString = format(currentDate, "yyyy LLL dd");
      const summaryForDate:
        | IBankTransactionSummary
        | StubbedBankTransactionSummary =
        summary[dateString] || getDailyTransactionStub(currentDate);

      if (isStubbedBankTransactionSummary(summaryForDate)) {
        // we don't have balance data for this date. we need to determine it.
        if (
          !monthsWithoutTransactions.value.some((month) =>
            isSameMonth(currentDate, month)
          )
        ) {
          const yesterdayDateString = format(
            sub(currentDate, { days: 1 }),
            "yyyy LLL dd"
          );

          summaryForDate.balance =
            dailySummary?.[yesterdayDateString]?.balance ?? 0;
        }
      }

      summaryForDate.balance = Number(summaryForDate.balance);

      dailySummary[dateString] = summaryForDate;

      currentDate = add(currentDate, { days: 1 });
    }

    return dailySummary;
  });

  const getMonthSummaryStub = (month: string): StubbedBankMonthlySummary => ({
    ...summaryBase,
    month,
    neg_days: 0
  });

  const monthlySummary = computed<StubbedBankMonthlySummary[]>(() => {
    const summaryByMonth = allMonths.value.reduce<
      Record<string, StubbedBankMonthlySummary>
    >((sum, currentDate) => {
      sum[format(currentDate, "yyyy LLL")] = getMonthSummaryStub(
        currentDate.toString()
      );
      return sum;
    }, {});

    Object.values(dailySummary.value).forEach((summaryForDate) => {
      const summaryForMonth = summaryByMonth[summaryForDate.month];

      summaryForMonth.month = summaryForDate.month;
      summaryForMonth.deposits += safeParseToFloat(summaryForDate.deposits);
      summaryForMonth.deposits_count += summaryForDate.deposits_count ?? 0;
      summaryForMonth.withdrawals += safeParseToFloat(
        summaryForDate.withdrawals
      );
      summaryForMonth.withdrawals_count +=
        summaryForDate.withdrawals_count ?? 0;

      const balanceForDate = summaryForDate.balance;

      if (balanceForDate < 0) {
        summaryForMonth.neg_days++;
      }

      if (
        summaryForMonth.low === undefined ||
        balanceForDate < summaryForMonth.low
      ) {
        summaryForMonth.low = balanceForDate;
      }
    });

    return Object.values(summaryByMonth).reverse();
  });

  const dateOfFirstTransaction = computed(() => {
    if (!selectedMonth.value) {
      return dateOfVeryFirstTransaction.value;
    }
    const beginningOfMonth = startOfMonth(
      parse(selectedMonth.value, "yyyy LLL", new Date())
    );

    if (
      !monthsWithTransactions.value.some((month) =>
        isSameMonth(month, beginningOfMonth)
      )
    ) {
      return datesWithTransactions.value.find((date) =>
        isSameMonth(beginningOfMonth, date)
      );
    }

    const veryFirstTransaction = dateOfVeryFirstTransaction.value;
    return veryFirstTransaction > beginningOfMonth
      ? veryFirstTransaction
      : beginningOfMonth;
  });

  const dateOfLastTransaction = computed(() => {
    if (!selectedMonth.value || !dateOfFirstTransaction.value) {
      return dateOfVeryLastTransaction.value;
    }
    const lastDayOfMonth = endOfMonth(dateOfFirstTransaction.value);
    const tonight = endOfDay(new Date());

    if (lastDayOfMonth > tonight) {
      return tonight > dateOfVeryLastTransaction.value
        ? dateOfVeryLastTransaction.value
        : tonight;
    }

    return lastDayOfMonth > dateOfVeryLastTransaction.value
      ? dateOfVeryLastTransaction.value
      : lastDayOfMonth;
  });

  const scopedMonthlySummary = computed<StubbedBankMonthlySummary[]>(() => {
    if (!selectedMonth.value) {
      return monthlySummary.value;
    }

    return [
      monthlySummary.value.find(
        (summary) => selectedMonth.value === summary.month
      ) as IBankMonthlySummary
    ];
  });

  return {
    transactionsSummary,
    datesWithTransactions,
    dateOfVeryFirstTransaction,
    dateOfVeryLastTransaction,
    allMonths,
    availableMonths,
    monthlySummary,
    dailySummary,
    monthsWithTransactions,
    scopedMonthlySummary,
    dateOfLastTransaction,
    dateOfFirstTransaction
  };
};
