import type {
  IDiscoveryWorkflowBlock,
  IWorkflowBlock,
  IWorkflowStage
} from "@/models/workflows";
import i18n from "@/i18n";
import { PHASE_SCORECARDS } from "@/helpers/constants";
import {
  PHASES_WITH_TABS,
  WORKFLOW_BLOCKS_IDS
} from "@/helpers/constants/workflow";

const { t } = i18n.global;

type ValidatorMessages = Array<string>;

abstract class WorkflowValidator {
  protected _stage: IWorkflowStage;
  protected _messages: ValidatorMessages = [];

  constructor(stage: IWorkflowStage) {
    this._stage = stage;
  }

  public abstract validate(): void;

  get messages() {
    return this._messages;
  }

  protected get blocks(): Array<IWorkflowBlock> {
    if (!this._stage.tabs) {
      return [];
    }
    return this._stage.tabs.flatMap((tab) => tab.blocks);
  }

  addMessage(...messages: Array<string>) {
    this._messages.push(...messages);
  }
}

class DeadStatusesValidator extends WorkflowValidator {
  validate() {
    const deadStatuses = this._stage.statuses.filter((status) => !status.live);

    if (deadStatuses.length) {
      deadStatuses.forEach((status) => {
        if (!status.name) {
          this.addMessage(
            t("WORKFLOW.ERROR_MESSAGES.STATUS_CANNOT_BE_EMTPY", {
              stage: this._stage.name
            })
          );
        }

        status.substatuses?.forEach((substatus) => {
          if (!substatus) {
            this.addMessage(
              t("WORKFLOW.ERROR_MESSAGES.SUBSTATUS_CANNOT_BE_EMTPY", {
                stage: this._stage.name
              })
            );
          }
        });
      });
    } else {
      this.addMessage(
        t("WORKFLOW.ERROR_MESSAGES.AT_LEAST_ONE_DEAD_STATUS_IN_STAGE", {
          stage: this._stage.name
        })
      );
    }
  }
}

class ScorecardsValidator extends WorkflowValidator {
  public validate(): void {
    if (this._stage.type !== PHASE_SCORECARDS.toLocaleLowerCase()) {
      return;
    }
    if (
      this._stage.primary_card &&
      !this._stage.cards?.includes(this._stage.primary_card)
    ) {
      this.addMessage(
        t("WORKFLOW.ERROR_MESSAGES.PRIMARY_CARD_IS_ONE_OF_CARDS", {
          stage: this._stage.name
        })
      );
    }

    if (
      this._stage.primary_group &&
      !this._stage.groups?.includes(this._stage.primary_group)
    ) {
      this.addMessage(
        t("WORKFLOW.ERROR_MESSAGES.PRIMARY_GROUP_IS_ONE_OF_CARDS", {
          stage: this._stage.name
        })
      );
    }
  }
}

class TabsValidator extends WorkflowValidator {
  public validate(): void {
    if (!PHASES_WITH_TABS.includes(this._stage.type)) {
      return;
    }

    if (!this._stage.tabs?.length) {
      this.addMessage(
        t("WORKFLOW.ERROR_MESSAGES.AT_LEAST_ONE_TAB_IN_STAGE", {
          stage: this._stage.name
        })
      );
    }

    this._stage.tabs?.forEach((tab) => {
      if (tab.blocks.length) {
        return;
      }
      this.addMessage(
        t("WORKFLOW.ERROR_MESSAGES.AT_LEAST_ONE_BLOCK_IN_TAB", {
          stage: this._stage.name,
          tab: tab.name
        })
      );
    });
  }
}

class DiscoveryQuestionsValidator extends WorkflowValidator {
  public validate(): void {
    const discoveryQuestionBlock = this.blocks.find(
      ({ id }) => id === WORKFLOW_BLOCKS_IDS.discovery_questions
    );

    if (!discoveryQuestionBlock) {
      return;
    }
    const { questions } = discoveryQuestionBlock as IDiscoveryWorkflowBlock;

    if (questions.some((q) => !q.question)) {
      this.addMessage(t("WORKFLOW.ERROR_MESSAGES.QUESTIONS_CANNOT_BE_EMPTY"));
    }
  }
}

class PlacementValidator extends WorkflowValidator {
  public validate(): void {
    if (this._stage.type !== "placement") {
      return;
    }

    const hasEnabledProducts = !!this._stage.products
      ?.flatMap(({ enabled }) => enabled)
      .filter(Boolean).length;

    if (hasEnabledProducts) {
      return;
    }

    this.addMessage(t("WORKFLOW.ERROR_MESSAGES.AT_LEAST_ONE_PRODUCT_TYPE"));
  }
}

export class WorkflowValidationChain {
  private constructor() {
    /* This is a final class */
  }

  static run(stage: IWorkflowStage) {
    const validators: Array<WorkflowValidator> = [
      new DeadStatusesValidator(stage),
      new DiscoveryQuestionsValidator(stage),
      new ScorecardsValidator(stage),
      new TabsValidator(stage),
      new PlacementValidator(stage)
    ];

    return validators.flatMap((validator) => {
      validator.validate();
      return validator.messages;
    });
  }
}
