import { axiosClient } from "../client";
import endpoints from "../endpoints";
import type { IUnderwritingLog } from "@/models/underwriting";
import { formatString } from "@/helpers/formatting";

class UnderwritingApiService {
  async getLogs(applicationId: string) {
    const response = await axiosClient.get<{ data: IUnderwritingLog[] }>(
      formatString(endpoints.APPLICATIONS.UNDERWRITING_LOGS, applicationId)
    );
    return response.data;
  }
}

const underwritingApiService = new UnderwritingApiService();
export default underwritingApiService;
