export enum CustomAttributeStatusOption {
  draft = 1,
  published
}

export enum CustomAttributeTypeOption {
  api = 1,
  formula = 2
}

export enum MathOperation {
  add = "+",
  subtract = "-",
  multiply = "*",
  divide = "/",
  power = "^",
  percentage = "%"
}

export enum MathOperator {
  add = "+",
  subtract = "-",
  multiply = "*",
  divide = "/"
}

export enum EditorNodeName {
  attribute = "attribute",
  function = "function",
  number = "number",
  operator = "operator",
  parenthesis = "parenthesis",
  separator = "separator",
  uncategorized = "uncategorized",
  unrecognized = "unrecognized",
  regex = "regex",
  attributeOption = "attributeOption"
}

export enum AllowedKey {
  arrowLeft = "ArrowLeft",
  arrowRight = "ArrowRight",
  backspace = "Backspace",
  shift = "Shift"
}
