import type { ISmartFilterData } from "@/models/applications";

export const calcAvg = <T>(data: T[], mapCallback: (obj: T) => number) => {
  let average = 0;
  let sum = 0;

  const filteredValues = data.map(mapCallback).filter(Boolean);
  if (filteredValues.length) {
    sum = filteredValues.reduce((a, b) => a + b, 0);
    average = sum / filteredValues.length;
  }
  return average;
};

export const isNotCurrentDate = (year: number, month: number): boolean => {
  const currentDate = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  };
  return year !== currentDate.year || month !== currentDate.month;
};

export const getFilterCount = (
  filters:
    | ISmartFilterData
    | Record<string, string | number | string[] | number[]>,
  keysToExclude: string[] = []
): number =>
  Object.keys(filters || {}).reduce((filterAccumulator, currentKey) => {
    if (keysToExclude?.length && keysToExclude.includes(currentKey)) {
      return filterAccumulator;
    }

    const filterToCheck = filters[currentKey as keyof typeof filters];

    return (typeof filterToCheck === "number" && filterToCheck > 0) ||
      (typeof filterToCheck !== "number" && filterToCheck?.length)
      ? filterAccumulator + 1
      : filterAccumulator;
  }, 0);
