import { COMMON_GET_QUERY } from "@/helpers/constants";
import { formatString } from "@/helpers/formatting";
import type { IPaginatedResponse } from "@/models/common";
import type {
  BacktestReport,
  BacktestReportAndMeta,
  BacktestReportList,
  BacktestReportPayload,
  IRunScorecardGroupsPayload,
  IRunScorecardsPayload,
  IScorecard,
  IScorecardGroup,
  IScorecardGroupResult,
  IScorecardResult
} from "@/models/scorecards";
import type { IDataService } from "@/models/orchestration";
import { axiosClient } from "../client";
import ENDPOINTS from "../endpoints";

class ScorecardsApiService {
  async createScorecard(scorecard: Partial<IScorecard>) {
    const response = await axiosClient.post<{ data: IScorecard }>(
      ENDPOINTS.SCORECARDS.ALL,
      scorecard
    );
    return response.data.data;
  }

  async getScorecards(params: Record<string, string | number | number[]>) {
    const response = await axiosClient.get<IPaginatedResponse<IScorecard>>(
      ENDPOINTS.SCORECARDS.ALL,
      {
        params: {
          ...COMMON_GET_QUERY,
          ...params
        }
      }
    );
    return response.data;
  }

  async getPublishedScorecards(params: Record<string, string | number>) {
    return await scorecardsApiService.getScorecards({
      ...params,
      statuses: [2]
    });
  }

  async getScorecardServices() {
    const response = await axiosClient.get<{ data: IDataService[] }>(
      ENDPOINTS.SCORECARDS.SERVICES
    );
    return response.data.data;
  }

  async delete(id: string) {
    return await axiosClient.delete(
      formatString(ENDPOINTS.SCORECARDS.SINGLE, id)
    );
  }

  async get(id: string) {
    const response = await axiosClient.get<{ data: IScorecard }>(
      formatString(ENDPOINTS.SCORECARDS.SINGLE, id)
    );
    return response.data.data;
  }

  async update(id: string, scorecard: Partial<IScorecard>) {
    const response = await axiosClient.patch<{ data: IScorecard }>(
      formatString(ENDPOINTS.SCORECARDS.SINGLE, id),
      scorecard
    );
    return response.data.data;
  }

  async createGroup(scorecardGroup: Partial<IScorecardGroup>) {
    const response = await axiosClient.post<{ data: IScorecardGroup }>(
      formatString(ENDPOINTS.SCORECARD_GROUPS.ALL),
      scorecardGroup
    );
    return response.data.data;
  }

  async getGroup(params: { id: string; include_content?: boolean }) {
    const response = await axiosClient.get<{ data: IScorecardGroup }>(
      formatString(ENDPOINTS.SCORECARD_GROUPS.SINGLE, params.id),
      { params: { include_content: params.include_content ?? false } }
    );
    return response.data.data;
  }

  async updateGroup(id: string, scorecardGroup: Partial<IScorecardGroup>) {
    const response = await axiosClient.patch<{ data: IScorecardGroup }>(
      formatString(ENDPOINTS.SCORECARD_GROUPS.SINGLE, id),
      scorecardGroup
    );
    return response.data.data;
  }

  async getScorecardGroups(params: Record<string, string | number | number[]>) {
    const response = await axiosClient.get<IPaginatedResponse<IScorecardGroup>>(
      formatString(ENDPOINTS.SCORECARD_GROUPS.ALL),
      {
        params: {
          ...COMMON_GET_QUERY,
          ...params
        }
      }
    );
    return response.data;
  }

  async getPublishedScorecardGroups(params: Record<string, string | number>) {
    return await scorecardsApiService.getScorecardGroups({
      ...params,
      statuses: [2]
    });
  }

  async deleteGroup(id: string) {
    return await axiosClient.delete(
      formatString(ENDPOINTS.SCORECARD_GROUPS.SINGLE, id)
    );
  }

  async getScorecardContextReport<TPayload>(payload: {
    id: IScorecard["id"];
    type?: "group" | "individual";
    payload?: TPayload;
  }): typeof payload.type extends "group"
    ? Promise<IPaginatedResponse<IScorecardGroupResult>>
    : Promise<IPaginatedResponse<IScorecardResult>> {
    const endpoint =
      payload.type === "group"
        ? ENDPOINTS.SCORECARD_GROUPS.RESULTS
        : ENDPOINTS.SCORECARDS.RESULTS;
    const response = await axiosClient.get<
      typeof payload.type extends "group"
        ? IPaginatedResponse<IScorecardGroupResult>
        : IPaginatedResponse<IScorecardResult>
    >(formatString(endpoint, payload.id), {
      params: payload.payload
    });
    return response.data;
  }

  async getScorecardBacktestContextReport<TPayload>(payload: {
    id: IScorecard["id"];
    type?: "group" | "individual";
    payload?: TPayload;
  }): typeof payload.type extends "group"
    ? Promise<IPaginatedResponse<IScorecardGroupResult>>
    : Promise<IPaginatedResponse<IScorecardResult>> {
    const endpoint =
      payload.type === "group"
        ? ENDPOINTS.SCORECARD_GROUPS.MULTI_RESULTS
        : ENDPOINTS.SCORECARDS.MULTI_RESULTS;
    const response = await axiosClient.post<
      typeof payload.type extends "group"
        ? IPaginatedResponse<IScorecardGroupResult>
        : IPaginatedResponse<IScorecardResult>
    >(formatString(endpoint, payload.id), {
      ...payload.payload
    });
    return response.data;
  }

  async getScorecardGroupResultsForApplication(
    application_id: string | number,
    id: string | number,
    payload: Record<string, string | number> = {}
  ) {
    const response = await axiosClient.get<{ data: IScorecardGroup }>(
      formatString(ENDPOINTS.SCORECARD_GROUPS.RESULTS, id),
      { params: { application_id, ...payload } }
    );
    return response.data;
  }

  async runScorecards(appId: string, payload: IRunScorecardsPayload) {
    return await axiosClient.post(
      formatString(ENDPOINTS.SCORECARDS.RUN_SCORECARDS, appId),
      payload
    );
  }

  async runScorecardGroups(appId: string, payload: IRunScorecardGroupsPayload) {
    return await axiosClient.post(
      formatString(ENDPOINTS.SCORECARD_GROUPS.RUN_SCORECARD_GROUPS, appId),
      payload
    );
  }

  async updatePoints(appId: string, scorecardId: string, points: number) {
    return await axiosClient.put(
      formatString(ENDPOINTS.SCORECARDS.UPDATE_POINTS, appId, scorecardId),
      { points }
    );
  }

  async duplicateScorecard(scorecardId: IScorecard["id"]) {
    return await axiosClient.post<{ data: Partial<IScorecard> }>(
      formatString(ENDPOINTS.SCORECARDS.DUPLICATE, scorecardId)
    );
  }

  async queueScorecardBacktestReport(
    scorecardId: IScorecard["id"],
    applicationIds: string[]
  ) {
    await axiosClient.post(
      formatString(ENDPOINTS.SCORECARDS.RUN_BACKTEST_REPORT, scorecardId),
      {
        application_ids: applicationIds
      }
    );
  }

  async queueScorecardGroupBacktestReport(
    scorecardGroupId: IScorecardGroup["id"],
    applicationIds: string[]
  ) {
    await axiosClient.post(
      formatString(
        ENDPOINTS.SCORECARD_GROUPS.BACKTEST_REPORT,
        scorecardGroupId
      ),
      { application_ids: applicationIds }
    );
  }

  async saveScorecardBacktestReport(payload: BacktestReportPayload) {
    await axiosClient.post<{
      data: Record<"data", BacktestReport[]> | undefined;
    }>(ENDPOINTS.SCORECARDS.BACKTEST_REPORTS.ALL, payload);
  }

  async updateScorecardBacktestReport(payload: BacktestReportPayload) {
    if (!payload.id) {
      return;
    }
    await axiosClient.patch<{
      data: Record<"data", BacktestReport[]> | undefined;
    }>(
      formatString(ENDPOINTS.SCORECARDS.BACKTEST_REPORTS.SINGLE, payload.id),
      payload
    );
  }

  async deleteScorecardBacktestReport(id: string) {
    return await axiosClient.delete(
      formatString(ENDPOINTS.SCORECARDS.BACKTEST_REPORTS.SINGLE, id)
    );
  }

  async getScorecardBacktestReports(payload: Record<string, string | number>) {
    const response = await axiosClient.get<
      IPaginatedResponse<BacktestReportList>
    >(ENDPOINTS.SCORECARDS.BACKTEST_REPORTS.ALL, { params: payload });
    return response.data;
  }

  async getScorecardBacktestReport(reportId: string) {
    const response = await axiosClient.get<{ data: BacktestReportAndMeta }>(
      formatString(ENDPOINTS.SCORECARDS.BACKTEST_REPORTS.SINGLE, reportId)
    );
    return response.data.data;
  }
}

const scorecardsApiService = new ScorecardsApiService();
export default scorecardsApiService;
