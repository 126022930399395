import type { ToastMessage, ToastMessageData, ToastType } from "@/lib/Toast";
import { useStore } from "vuex";

export const useNotification = () => {
  const { commit } = useStore();

  const showMessage = (
    title: string,
    type: ToastType = "success",
    subTitle = "",
    options?: Omit<ToastMessageData, "title" | "type" | "subTitle">
  ) => {
    commit(
      "setGlobalMessage",
      { title, type, subTitle, ...options },
      { root: true }
    );
  };

  const clearMessage = (id: ToastMessage["id"]) => {
    commit("clearGlobalMessage", id);
  };

  const clearAllMessages = () => {
    commit("clearAllGlobalMessages");
  };

  return {
    showMessage,
    clearMessage,
    clearAllMessages
  };
};
